import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { DrcButton } from 'driscolls-react-components';

const styles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '8px',
        marginRight: '8px',
        alignSelf: 'baseline'
    },
    btn: {
        margin: '0 !important',
        border: 'none',
        borderRadius: '0 !important',
        [theme.darkTheme]: {
            '&:hover': {
                color: '#fff !important',
                border: '1px solid #fff !important',
                backgroundColor: 'transparent !important'
            }
        }
    },
    btnContainer: {
        border: '1px solid #bdbdbd',
        [theme.darkTheme]: {
            border: '1px solid #555'
        },
        margin: '9px',
        borderRadius: '4px'
    },
    label: {
        alignSelf: 'center'
    },
    leftMost: {
        borderRadius: '4px 0px 0px 4px !important'
    },
    rightMost: {
        borderRadius: '0px 4px 4px 0px !important'
    }
});

class Switch extends Component {
    btnLabels = ['Yes', 'Both', 'No'];
    render() {
        let { label, value, classes, onChange } = this.props;

        if (!value) value = 'Both';

        return (
            <div className={classes.container}>
                <div className={classes.label}>{label}</div>
                <div className={classes.btnContainer}>
                    {this.btnLabels.map((label, indx) => (
                        <DrcButton
                            className={`${classes.btn} ${indx === 0 ? classes.leftMost : ''} ${
                                indx === this.btnLabels.length - 1 ? classes.rightMost : ''
                            }`}
                            isPrimary={value === label}
                            isSecondary={value !== label}
                            onClick={() => onChange(label)}
                        >
                            {label}
                        </DrcButton>
                    ))}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Switch);
