import React, { Component } from 'react';
import { DrcButton } from 'driscolls-react-components';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction, showToast, setErrorsAction, setLogoutMsg } from '../actions/actions';
import { setViewLogsRecords } from '../actions/ViewLogsActions';
import { connect } from 'react-redux';
import { Middleware } from 'one-ring';
import { withOktaAuth } from '@okta/okta-react';
import { Translate } from 'react-localize-redux';
import BackButton from '../components/BackButton';
import AddUpdateSolution from '../components/AddUpdateSolution';
import ConfirmationDialog from '../components/ConfirmationDialog';
import CreateTicketDialog from '../components/CreateTicketDialog';
import ViewLogOrPayloadDialog from '../components/ViewLogOrPayloadDialog';
import ViewLogsList from '../components/ViewLogs/List';
import ViewLogsFilter from '../components/ViewLogs/Filter';
import {
    getAccessToken,
    getCurrentLoggedInUserName,
    getEndIsoDate,
    getIsoDate,
    getLoadingMessage,
    getPrettyDateTime,
    getStartIsoDate,
    makeOptions,
    getTitle,
    formatRecords
} from '../utils/helper';
import ArrowDownwardRounded from '@material-ui/icons/ArrowDownwardRounded';
import { DuExcelUtilities } from 'driscolls-react-utilities';
import startCase from 'lodash/startCase';
import APIEndPoints from '../services/api';
import PossibleSolutions from '../components/PossibleSolutions';
import HeaderTitle from '../components/HeaderTitle';
import IsMasterDataInitialized from '../components/IsMasterDataInitialized';
import MainPanel from '../components/MainPanel';
import ViewLogsListActions from '../components/ViewLogs/ListActions';

const styles = () => ({
    filterShell: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    exportBtn: {
        height: '35px'
    },
    popup: {
        boxsizing: 'border-box',
        backgroundColor: 'white',
        width: '18rem',
        zIndex: '200',
        position: 'absolute',
        padding: '12px',
        borderRadius: '.5rem',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: 'black',
            boxShadow: '0px 2px 4px -1px rgba(200,200,200, .3), 0px 4px 5px 0px rgba(200,200,200,0.14), 0px 1px 10px 0px rgba(200,200,200,0.12)'
        }
    },
    menuOptionButton: {
        minWidth: '100%',
        maxWidth: '100%',
        margin: '0px !important'
    },
    actionButtonLabel: {
        float: 'right',
        textAlign: 'left',
        width: '80%',
        fontWeight: 'bold'
    }
});

class ViewLogs extends Component {
    PAGE_TITLE = `View Logs - ${this.props.match.params.ProcessName}`;
    state = {
        records: [],
        openAddSolution: false,
        title: '',
        processNameList: [],
        askForConfirmation: false,
        confirmationContent: '',
        startCreatingTkt: false,
        enableReprocessing: false,
        enableViewLogOrPayload: false,
        content: null,
        pageSize: 50,
        first: 0,
        appName: this.props.match.params.ApplicationName,
        processName: this.props.match.params.ProcessName ? this.props.match.params.ProcessName : '',
        selectedRow: null,
        selectedRows: [],
        showPossibleSolutions: false,
        solutions: [],
        isViewOnly: false,
        headerText: '',
        rowToDeleteOrReprocess: null,
        isAddSolution: false,
        enableExportAll: false,
        errorSource: '',
        rowToLockAndUnlock: null,
        enableLockRecord: false,
        loggedInUserName: null,
        showContextMenu: false,
        top: 150,
        left: 150,
        currentRow: null
    };

    modalRef = React.createRef();

    getViewLogsData = async (
        currentPage,
        pageSize,
        startDate,
        endDate,
        apiName,
        transactionId,
        reprocessable,
        endPoint,
        source,
        searchString,
        appName,
        searchLike
    ) => {
        let token = await getAccessToken(this.props);
        var data = [];
        if (token) {
            data = await Middleware.Send(
                'ViewLogsList',
                token,
                APIEndPoints.VIEW_LOGS_DETAILS(
                    this.props.env,
                    currentPage,
                    pageSize,
                    startDate,
                    endDate,
                    apiName,
                    transactionId,
                    reprocessable === 'Yes' ? '1' : reprocessable === 'No' ? '0' : null,
                    endPoint,
                    source,
                    searchString,
                    appName,
                    searchLike
                ),
                'GET',
                '',
                getLoadingMessage('LoadingViewLogs')
            );
            if (data.Result) {
                data.Result = data.Result.map((val, index) => {
                    let createdDate = val.createdDate;
                    let createdBy = val.createdBy;
                    let updatedDate = val.updatedDate;
                    let updatedBy = val.updatedBy;
                    ['createdDate', 'createdBy', 'updatedDate', 'updatedBy'].forEach((key) => delete val[key]);
                    if (this.props.filters.source === 'DB') {
                        return {
                            ...val,
                            index: index, //doing this until backend comes up with unique key for new relic errors
                            createdDate: createdDate ? getPrettyDateTime(createdDate) : createdDate,
                            createdBy: createdBy,
                            updatedDate: updatedDate ? getPrettyDateTime(updatedDate) : updatedDate,
                            updatedBy: updatedBy,
                            errorDescription: val.errorDescription ? JSON.stringify(val.errorDescription) : val.errorDescription
                        };
                    } else {
                        return {
                            ...val,
                            index: index, //doing this until backend comes up with unique key for new relic errors
                            createdDate: createdDate ? getPrettyDateTime(createdDate) : createdDate,
                            errorDescription: val.errorDescription ? JSON.stringify(val.errorDescription) : val.errorDescription
                        };
                    }
                });
            }
        }
        return data;
    };

    getApiListData = async (appName) => {
        let token = await getAccessToken(this.props);
        var data = [];
        if (token) {
            data = await Middleware.Send(
                'ApiNames',
                token,
                APIEndPoints.API_NAMES(this.props.env, appName),
                'GET',
                '',
                getLoadingMessage('loadingApiNames')
            );
        }
        return data;
    };

    getViewLogsDataWithCustomFilters = async (page, pageSize) => {
        let date = new Date();
        let startDate = this.appName && this.props.filters.startDate ? this.props.filters.startDate : date;
        let endDate = this.appName && this.props.filters.endDate ? this.props.filters.endDate : date;
        let source = this.state.errorSource === 'nrError' ? 'NR' : 'DB';
        let data = await this.getViewLogsData(
            page,
            pageSize,
            getStartIsoDate(startDate),
            getEndIsoDate(endDate),
            this.processName,
            this.appName && this.props.filters.transactionId ? this.props.filters.transactionId : '',
            this.appName && this.props.filters.reProcessable ? this.props.filters.reProcessable : '',
            this.appName && this.props.filters.endPoint ? this.props.filters.endPoint : '',
            source,
            this.appName && this.props.filters.searchString ? this.props.filters.searchString : '',
            this.appName,
            this.appName && this.props.filters.searchLike ? this.props.filters.searchLike : true
        );
        return data;
    };

    onPage = async (event) => {
        try {
            let data = await this.getViewLogsDataWithCustomFilters(event.first / this.state.pageSize + 1, this.state.pageSize);
            this.props.setViewLogsData(data.Result ? data.Result : [], data.totalRecords ? data.totalRecords : 0);
            this.setState({ first: event.first, records: data.Result ? data.Result : [] });
        } catch (err) {
            this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileFetchingLogsData" />, err);
        }
    };

    initializeProcessDetailsScreen = async (startDate, endDate, transactionId, reprocessable, endPoint, source, searchString, searchLike) => {
        var apiListData = [];
        if (this.appName) {
            try {
                apiListData = await this.getApiListData(this.appName);
                apiListData = makeOptions(apiListData ? apiListData : [], 'apiName', 'apiName');
                var data = await this.getViewLogsData(
                    1,
                    this.state.pageSize,
                    startDate,
                    endDate,
                    this.processName,
                    transactionId,
                    reprocessable,
                    endPoint,
                    source,
                    searchString,
                    this.appName,
                    searchLike
                );
                this.props.setViewLogsData(data.Result ? data.Result : [], data.totalRecords ? data.totalRecords : 0);
                this.setState({
                    records: data.Result ? data.Result : [],
                    processNameList: [{ label: 'All', value: '' }].concat(apiListData),
                    appName: this.appName,
                    processName: this.processName
                });
            } catch (err) {
                this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileFetchingLogsData" />, err);
            }
        }
    };

    checkCurrentUser = async () => {
        let token = await getAccessToken(this.props);
        if (token) {
            try {
                let currentUserName = await getCurrentLoggedInUserName(token);
                if (currentUserName) {
                    this.setState({
                        loggedInUserName: currentUserName
                    });
                } else {
                    return 'user unknown';
                }
            } catch (err) {
                this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="getCurrentLoggedInUserNameFailed" />, err);
            }
        }
    };

    handleActionClick = (event, row) => {
        const cellsHalfHeight = 10;
        const bound = event.currentTarget.getBoundingClientRect();
        const topPosition = event.pageY;
        const blockHeight = 150;
        const top = topPosition + blockHeight > window.innerHeight ? bound.y - blockHeight + cellsHalfHeight * 2 : bound.y + cellsHalfHeight;
        this.setState(
            {
                showContextMenu: false
            },
            () => {
                this.setState({
                    showContextMenu: true,
                    top: top,
                    left: bound.x + bound.width + 6,
                    currentRow: row
                });
            }
        );
    };

    handleGlobalClick = (event, row) => {
        const isEventOutside = !this.modalRef.current.contains(event.target);
        const checkClass = event.target.className.baseVal === 'MuiSvgIcon-root';
        const notInButton = event.target.tagName !== 'path';

        if (isEventOutside && !checkClass && notInButton) {
            this.handleContextMenuClose();
        }
    };

    handleContextMenuClose = () => {
        this.setState({ showContextMenu: false });
    };

    handleScroll = () => {
        this.handleContextMenuClose();
    };

    async componentDidMount() {
        document.addEventListener('click', this.handleGlobalClick);
        window.addEventListener('scroll', this.handleScroll, true);
        if (this.props.pageTitle !== this.PAGE_TITLE) {
            this.props.setPageTitle(this.PAGE_TITLE);
        }
        this.appName = this.props.match.params.ApplicationName;
        this.processName = this.props.match.params.ProcessName ? this.props.match.params.ProcessName : '';
        let date = new Date();
        let startDate = this.appName && this.props.filters.startDate ? this.props.filters.startDate : date;
        let endDate = this.appName && this.props.filters.endDate ? this.props.filters.endDate : date;
        let errorSourceValue = this.props.location.state ? this.props.location.state.source : '';
        let source = errorSourceValue === 'nrError' ? 'NR' : 'DB';
        this.setState({ headerText: getTitle(startDate, endDate), errorSource: errorSourceValue });
        this.initializeProcessDetailsScreen(getStartIsoDate(startDate), getEndIsoDate(endDate), '', '', '', source, '');
        await this.checkCurrentUser();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.env !== this.props.env) {
            let date = new Date();
            let startDate = this.appName && this.props.filters.startDate ? this.props.filters.startDate : date;
            let endDate = this.appName && this.props.filters.endDate ? this.props.filters.endDate : date;
            let errorSourceValue = this.props.location.state ? this.props.location.state.source : '';
            let source = errorSourceValue === 'nrError' ? 'NR' : 'DB';
            this.checkCurrentUser();
            this.initializeProcessDetailsScreen(
                getStartIsoDate(startDate),
                getEndIsoDate(endDate),
                this.appName && this.props.filters.transactionId ? this.props.filters.transactionId : '',
                this.appName && this.props.filters.reProcessable ? this.props.filters.reProcessable : '',
                this.appName && this.props.filters.locked ? this.props.filters.locked : '',
                this.appName && this.props.filters.endPoint ? this.props.filters.endPoint : '',
                this.appName && this.props.filters.source ? this.props.filters.source : source,
                this.appName && this.props.filters.searchString ? this.props.filters.searchString : '',
                this.appName && this.props.filters.searchLike ? this.props.filters.searchLike : true
            );
        }
    }

    closeAddSolutionDialog = () => {
        this.setState({ openAddSolution: false, selectedRow: null, isViewOnly: false, isAddSolution: false });
    };

    startPurge = (row) => {
        let askForConfirmation = true;
        let title = <Translate id="PurgeRecord" />;
        let confirmationContent = <Translate id="purgeLogData" />;
        let rowToDeleteOrReprocess = row;
        this.setState({
            askForConfirmation,
            title,
            confirmationContent,
            rowToDeleteOrReprocess
        });
    };

    lockRecord = (row) => {
        let askForConfirmation = true;
        let titleId = row.locked === 0 ? 'LockRecord' : 'UnlockRecord';
        let confirmationContentId = row.locked === 0 ? 'confirmLockRecord' : 'confirmUnlockRecord';
        let title = <Translate id={titleId} />;
        let confirmationContent = <Translate id={confirmationContentId} />;
        let rowToLockAndUnlock = row;
        let enableLockRecord = true;
        this.setState({
            askForConfirmation,
            title,
            confirmationContent,
            rowToLockAndUnlock,
            enableLockRecord
        });
    };

    startReprocessCreation = (row) => {
        let askForConfirmation = true;
        let title = <Translate id="ReprocessErrorRecord" />;
        let confirmationContent = <Translate id="ReprocessLogMessage" />;
        let rowToDeleteOrReprocess = row;
        let enableReprocessing = true;
        this.setState({
            askForConfirmation,
            title,
            confirmationContent,
            rowToDeleteOrReprocess,
            enableReprocessing
        });
    };

    startTktCreation = (row) => {
        let startCreatingTkt = true;
        let title = <Translate id="ServiceNowIncidentDetails" />;
        this.setState({
            selectedRow: row,
            startCreatingTkt,
            title
        });
    };

    formatTimestamp = (timeStamp) => {
        var timeStampArray = timeStamp.split('T');
        var date = timeStampArray[0].split('/').reverse().join('-');
        return `${date} ${timeStampArray[1]}:00`;
    };

    startViewLog = async (row) => {
        let token = await getAccessToken(this.props);
        if (token) {
            try {
                let source = this.state.errorSource === 'nrError' ? 'NR' : 'DB';
                let enableViewLogOrPayload = true;
                let title = <Translate id="ErrorLogDetails" />;
                let startDate = this.appName && this.startDate ? this.startDate : this.props.filters.startDate;
                let endDate = this.appName && this.endDate ? this.endDate : this.props.filters.endDate;
                let data = await Middleware.Send(
                    'ViewLog',
                    token,
                    APIEndPoints.VIEW_ERROR_LISTING_LOG(
                        this.props.env,
                        row.transactionId,
                        row.apiName,
                        getStartIsoDate(startDate),
                        getEndIsoDate(endDate),
                        source
                    ),
                    'GET',
                    '',
                    getLoadingMessage('LoadingLog')
                );
                if (Array.isArray(data)) {
                    data = data.map((val) => {
                        if (val.timestamp) {
                            return { ...val, timestamp: getPrettyDateTime(this.formatTimestamp(val.timestamp)) };
                        }
                        return val;
                    });
                }
                this.setState({
                    content: data,
                    enableViewLogOrPayload,
                    title
                });
            } catch (err) {
                this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileLoadingLogDetails" />, err);
            }
        }
    };

    startViewPayload = async (row) => {
        let token = await getAccessToken(this.props);
        if (token) {
            try {
                let enableViewLogOrPayload = true;
                let title = <Translate id="Payload" />;
                let data = await Middleware.Send(
                    'ViewPayload',
                    token,
                    APIEndPoints.VIEW_ERROR_LISTING_PAYLOAD(this.props.env, row.id),
                    'GET',
                    '',
                    getLoadingMessage('LoadingPayload')
                );
                this.setState({
                    content: typeof data === 'string' ? JSON.parse(data) : data,
                    enableViewLogOrPayload,
                    title
                });
            } catch (err) {
                this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileLoadingPayloadDetails" />, err);
            }
        }
    };

    closeViewLogOrPayload = () => {
        this.setState({ enableViewLogOrPayload: false, title: '', content: null });
    };

    handleSearch = async (filterVal) => {
        try {
            let data = await this.getViewLogsData(
                1,
                this.state.pageSize,
                getStartIsoDate(filterVal.startDate),
                getEndIsoDate(filterVal.endDate),
                filterVal.apiName,
                filterVal.transactionId,
                filterVal.reProcessable,
                filterVal.endPoint,
                filterVal.source,
                filterVal.searchString,
                filterVal.application,
                filterVal.searchLike
            );
            this.startDate = filterVal.startDate;
            this.endDate = filterVal.endDate;
            this.props.setViewLogsData(data.Result ? data.Result : [], data.totalRecords ? data.totalRecords : 0);
            this.setState({ records: data.Result ? data.Result : [], first: 0, headerText: getTitle(filterVal.startDate, filterVal.endDate) });
            this.props.history.push(`/ViewLogs/${this.appName}/${this.processName}`);
        } catch (err) {
            this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileFetchingLogsData" />, err);
        }
    };

    handleLockRecord = async () => {
        let token = await getAccessToken(this.props);
        let APIErrorId = this.state.rowToLockAndUnlock.locked === 0 ? 'RecordLockFailedHeading' : 'RecordUnlockFailedHeading';
        if (token) {
            try {
                let isChecked = this.state.rowToLockAndUnlock.locked === 0 ? '1' : '0';
                let successToastId = this.state.rowToLockAndUnlock.locked === 0 ? 'EnableLockSuccessful' : 'EnableUnlockSuccessful';
                let loadRecordLockingId = this.state.rowToLockAndUnlock.locked === 0 ? 'LockingRecord' : 'UnlockingRecord';
                var data = await Middleware.Send(
                    'LockRecord',
                    token,
                    APIEndPoints.PROCESS_RECORD_LOCK(this.props.env),
                    'POST',
                    {
                        id: this.state.rowToLockAndUnlock.id,
                        checked: isChecked,
                        updatedBy: getCurrentLoggedInUserName(token)
                    },
                    getLoadingMessage(loadRecordLockingId)
                );
                if (data.responseCode === '201') {
                    this.props.showToast(<Translate id={successToastId} />, true);
                } else if (data.responseCode === '409') {
                    this.props.setErrorsAction(
                        <Translate id={APIErrorId} />,
                        <>
                            <Translate id="RecordLockFailedMessage" />
                            <Translate id="RecordLockFailedMessage2" />
                        </>
                    );
                } else {
                    this.props.showToast(<Translate id="LockFail" />, false);
                }
                this.refreshRows();
            } catch (err) {
                let catchErrorMessageId = this.state.rowToLockAndUnlock.locked === 0 ? 'RecordLockFailedMessage' : 'RecordUnlockFailedMessage';
                this.props.setErrorsAction(<Translate id={APIErrorId} />, <Translate id={catchErrorMessageId} />, err);
            }
        }
        this.closeConfirmationDialog();
    };

    handleDelete = async () => {
        let token = await getAccessToken(this.props);
        if (token) {
            try {
                await Middleware.Send(
                    'DeleteLog',
                    token,
                    APIEndPoints.DELETE_ERROR_LISTING_RECORD(this.props.env),
                    'DELETE',
                    {
                        id: this.state.rowToDeleteOrReprocess.id,
                        updatedBy: getCurrentLoggedInUserName(token)
                    },
                    getLoadingMessage('DeletingRecord')
                );
                this.refreshRows();
                this.props.showToast(<Translate id="DeleteErrorRecordSuccessFul" />, true);
            } catch (err) {
                this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileDeletingLogsData" />, err);
            }
        }
        this.closeConfirmationDialog();
    };

    handleReProcess = async () => {
        let token = await getAccessToken(this.props);
        if (token) {
            try {
                var data = await Middleware.Send(
                    'ReprocessErrorRecord',
                    token,
                    APIEndPoints.REPROCESS_ERROR_RECORD(this.props.env),
                    'POST',
                    {
                        apiName: this.state.rowToDeleteOrReprocess.apiName,
                        createdDate: this.state.rowToDeleteOrReprocess.createdDate
                            ? new Date(this.state.rowToDeleteOrReprocess.createdDate)
                            : new Date(),
                        endPoint: this.state.rowToDeleteOrReprocess.endPoint,
                        method: this.state.rowToDeleteOrReprocess.method,
                        id: this.state.rowToDeleteOrReprocess.id,
                        updatedBy: getCurrentLoggedInUserName(token)
                    },
                    getLoadingMessage('ReprocessingRecord')
                );
                if (data.message === 'success') {
                    this.props.showToast(<Translate id="ReprocessSuccessfull" />, true);
                } else {
                    this.props.showToast(<Translate id="ReprocessFailure" />, false);
                }
                this.refreshRows();
            } catch (err) {
                this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileReprocessingErrorRecord" />, err);
            }
        }
        this.closeConfirmationDialog();
    };

    confirm = async () => {
        if (this.state.enableReprocessing) {
            this.handleReProcess();
        } else if (this.state.enableExportAll) {
            this.handleExportAll();
        } else if (this.state.enableLockRecord) {
            this.handleLockRecord();
        } else {
            this.handleDelete();
        }
    };

    closeConfirmationDialog = () => {
        this.setState({
            askForConfirmation: false,
            title: '',
            confirmationContent: '',
            rowToDeleteOrReprocess: null,
            enableReprocessing: false,
            enableExportAll: false,
            enableLockRecord: false
        });
    };

    checkIfAppValuesAreValid = () => {
        return this.appName;
    };

    createTkt = async (ticketValues) => {
        let token = await getAccessToken(this.props);
        if (token) {
            try {
                let body = {
                    subject: ticketValues.title,
                    body: ticketValues.description
                };
                var data = await Middleware.Send(
                    'CreateServiceNowTicket',
                    token,
                    APIEndPoints.CREATE_SERVICE_NOW_TICKET(
                        this.props.env,
                        getCurrentLoggedInUserName(token),
                        this.state.selectedRow.id,
                        this.props.filters.source
                    ),
                    'POST',
                    body,
                    getLoadingMessage('CreatingServiceNowTicket')
                );
                if (data.message !== 'failure') {
                    this.props.showToast(<Translate id="CreateServiceNowTicketSuccessful" />, true);
                } else {
                    this.props.showToast(<Translate id="CreateServiceNowTicketFailure" />, false);
                }
                this.refreshRows();
            } catch (err) {
                this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileCreatingServiceNowTicket" />, err);
            }
        }
        this.cancelTktCreation();
    };

    cancelTktCreation = () => {
        this.setState({
            startCreatingTkt: false,
            title: '',
            selectedRow: null
        });
    };

    changeSource = (source) => {
        this.setState({ errorSource: source });
    };

    handleAppOrApiSelectChange = async (value, name) => {
        if (name === 'application') {
            this.appName = value;
            this.processName = '';
            var data = [];
            try {
                data = await this.getApiListData(value);
                data = makeOptions(data ? data : [], 'apiName', 'apiName');
            } catch (err) {
                this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileFetchingApiListingData" />, err);
            }
            this.setState({
                processNameList: [{ label: 'All', value: '' }].concat(data),
                appName: this.appName,
                processName: '',
                records: []
            });
        } else {
            this.processName = value;
            this.setState({ processName: this.processName });
        }
    };

    onRowsSelected = (e) => {
        this.setState({ selectedRows: e.value });
    };

    formatAndExport = (fileName, cols, data) => {
        data = data.map((val) => {
            return {
                ...val,
                errorDescription: val.errorMessage,
                detailedErrorDescription: val.errorDescription,
                reprocessable: val.reprocessable === 1 ? 'Yes' : 'No'
            };
        }); //doing this until column name is not corrected from backend
        DuExcelUtilities.Write(fileName, cols, data);
    };

    downloadData = async () => {
        var data = [...this.state.selectedRows];
        if (data.length && data.length !== this.state.pageSize && data.length !== this.props.totalRecords) {
            var cols = this.getCols();
            data = formatRecords(data, 'errorMessage');
            this.formatAndExport('ViewLogs.xlsx', cols, data);
        } else {
            this.startExportAll();
        }
        this.setState({ selectedRows: [] });
    };

    startExportAll = () => {
        this.setState({
            askForConfirmation: true,
            enableExportAll: true,
            title: <Translate id="ExportAllRecords" />,
            confirmationContent: `You Are About To Export ${this.props.totalRecords} Records, This Action Might Take Some Time, Do you Want To Continue?`
        });
    };

    getCols = () => {
        var excludingFields = ['disablePurge', 'rootCause', 'disableTicketCreation', 'disableAddSolution', 'index'];
        var keys = Object.keys(this.state.records[0]);
        keys[keys.indexOf('errorMessage')] = 'errorDescription'; //doing this until column name is not corrected from backend
        keys[keys.indexOf('errorDescription')] = 'detailedErrorDescription'; //doing this until column name is not corrected from backend
        keys = keys.filter((val) => !excludingFields.includes(val));
        return keys.map((key) => {
            return { name: startCase(key), key: key };
        });
    };

    handleExportAll = async () => {
        try {
            var cols = this.getCols();
            var records = await this.getViewLogsDataWithCustomFilters(1, this.props.totalRecords);
            records = formatRecords(records.Result ? records.Result : [], 'errorMessage');
            this.formatAndExport('ViewLogs-All.xlsx', cols, records);
            this.setState({ selectedIndexes: [] });
        } catch (err) {
            this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileFetchingLogsData" />, err);
        }
        this.closeConfirmationDialog();
    };

    closeSolutionsModal = () => {
        this.setState({
            showPossibleSolutions: false,
            title: '',
            solutions: []
        });
    };

    viewPossibleSolutions = async (row) => {
        let token = await getAccessToken(this.props);
        if (token) {
            var filter = {
                appErrorCode: row.errorCode,
                errorMessage: row.errorMessage,
                rootCause: '',
                apiName: row.apiName,
                endPoint: row.endPoint
            };
            var data = await Middleware.Send(
                'ErrorLibraryList',
                token,
                APIEndPoints.ERROR_LIBRARY_DETAILS(this.props.env, 1, 10, 1),
                'POST',
                filter,
                getLoadingMessage('FetchingAllPossibleSolutions')
            );
            if (data.results && data.results.length === 1) {
                this.setState({ openAddSolution: true, isViewOnly: true, selectedRow: data.results[0] });
            } else {
                this.setState({
                    showPossibleSolutions: true,
                    title: 'Solutions',
                    solutions: data.results ? data.results : []
                });
            }
        }
    };

    startAddToLibrary = (row) => {
        this.setState({
            openAddSolution: true,
            selectedRow: {
                statusCode: row.statusCode,
                errorMessage: row.errorMessage,
                rootCause: '',
                apiName: row.apiName,
                solution: '',
                protocol: '',
                appErrorCode: row.errorCode,
                endPoint: row.endPoint,
                id: row.id
            },
            isAddSolution: true
        });
    };

    handleAddErrorResolution = async (data) => {
        let token = await getAccessToken(this.props);
        if (token) {
            let date = getIsoDate(new Date(), true);
            let userId = getCurrentLoggedInUserName(token);
            data.createdDate = date;
            data.createdBy = userId;
            data.updatedDate = date;
            data.updatedBy = userId;
            try {
                await Middleware.Send(
                    'ErrorLibrary',
                    token,
                    APIEndPoints.ADD_UPDATE_ERROR_LIBRARY(
                        this.props.env,
                        this.state.selectedRow.id,
                        getCurrentLoggedInUserName(token),
                        this.props.filters.source
                    ),
                    'POST',
                    data,
                    getLoadingMessage('AddingRecordToErrorLibrary')
                );
                this.props.showToast(<Translate id="AddErrorLibSuccessful" />, true);
            } catch (err) {
                this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileAddingRecordToErrorLibrary" />, err);
            }
        }
        this.closeAddSolutionDialog();
        this.refreshRows();
    };

    refreshRows = async () => {
        var data = await this.getViewLogsDataWithCustomFilters(this.state.first / this.state.pageSize + 1, this.state.pageSize);
        this.setState({ records: data.Result ? data.Result : [] });
        this.props.setViewLogsData(data.Result ? data.Result : [], data.totalRecords ? data.totalRecords : 0);
    };

    actionTemplate = ViewLogsListActions(
        this.startReprocessCreation,
        this.startViewLog,
        this.startTktCreation,
        this.startPurge,
        this.startViewPayload,
        this.viewPossibleSolutions,
        this.startAddToLibrary,
        this.props.classes
    );

    componentWillUnmount() {
        document.removeEventListener('click', this.handleGlobalClick);
        window.removeEventListener('scroll', this.handleScroll, true);
    }

    render() {
        const { classes } = this.props;

        return (
            <IsMasterDataInitialized>
                <MainPanel>
                    <HeaderTitle
                        id="ViewLogs"
                        title={`${this.processName ? `: ` : ''}${this.processName ? `${this.processName}` : ''}${this.state.headerText}`}
                    />
                    <div>
                        <div>
                            <BackButton
                                linkText={<Translate id="BackToProcessListing" />}
                                link={this.appName ? `/ApiListing/${this.appName}` : '/ApiListing/'}
                            />
                        </div>
                    </div>
                    <div className={classes.filterShell}>
                        <ViewLogsFilter
                            appName={this.state.appName}
                            processName={this.state.processName}
                            canSearch={!this.checkIfAppValuesAreValid()}
                            handleSelectChange={this.handleAppOrApiSelectChange}
                            processNameList={this.state.processNameList}
                            handleSearch={this.handleSearch}
                            changeSource={this.changeSource}
                            errorSource={this.state.errorSource}
                        />
                        <DrcButton isSecondary className={classes.exportBtn} onClick={this.downloadData} disabled={!this.state.records.length}>
                            <ArrowDownwardRounded />
                            <Translate id="Export" />
                        </DrcButton>
                    </div>
                    <div
                        style={{
                            top: this.state.top,
                            left: this.state.left,
                            display: this.state.showContextMenu ? 'block' : 'none'
                        }}
                        className={classes.popup}
                        id="menupopup"
                        ref={this.modalRef}
                    >
                        {this.actionTemplate(this.state.currentRow, this.state.loggedInUserName, this.props.filters.source)}
                    </div>
                    <ViewLogsList
                        lockRecord={this.lockRecord}
                        records={this.checkIfAppValuesAreValid() ? this.state.records : []}
                        totalRecords={this.props.totalRecords}
                        first={this.state.first}
                        pageSize={this.state.pageSize}
                        onPage={this.onPage}
                        onRowsSelected={this.onRowsSelected}
                        classes={this.classes}
                        selectedIndexes={this.state.selectedRows}
                        currentUser={this.state.loggedInUserName}
                        errorSourceList={this.state.errorSource}
                        handleActionClick={this.handleActionClick}
                        onScroll={this.handleScroll}
                    />
                </MainPanel>
                <AddUpdateSolution
                    open={this.state.openAddSolution}
                    title={<Translate id="ErrorResolution" />}
                    cancel={this.closeAddSolutionDialog}
                    updating={false}
                    selectedRow={this.state.selectedRow}
                    isViewOnly={this.state.isViewOnly}
                    saveOrUpdateSolution={this.handleAddErrorResolution}
                    isAddSolution={this.state.isAddSolution}
                    loadOptions={() => {}}
                />
                <ConfirmationDialog
                    open={this.state.askForConfirmation}
                    title={this.state.title}
                    confirm={this.confirm}
                    cancel={this.closeConfirmationDialog}
                    content={this.state.confirmationContent}
                />
                <CreateTicketDialog
                    open={this.state.startCreatingTkt}
                    title={this.state.title}
                    create={this.createTkt}
                    cancel={this.cancelTktCreation}
                />
                <ViewLogOrPayloadDialog
                    open={this.state.enableViewLogOrPayload}
                    title={this.state.title}
                    cancel={this.closeViewLogOrPayload}
                    content={this.state.content}
                />
                <PossibleSolutions
                    open={this.state.showPossibleSolutions}
                    title={this.state.title}
                    cancel={this.closeSolutionsModal}
                    solutions={this.state.solutions}
                />
            </IsMasterDataInitialized>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setErrorsAction: (title, error, errorstack) => dispatch(setErrorsAction(title, error, errorstack)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setViewLogsData: (records, totalRecords) => dispatch(setViewLogsRecords(records, totalRecords)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess)),
    setLogoutMsg: (logoutMessage) => dispatch(setLogoutMsg(logoutMessage))
});

const mapStateToProps = (state) => {
    return {
        pageTitle: state.rootReducer.pageTitle,
        records: state.viewLogsReducer.viewLogsData,
        totalRecords: state.viewLogsReducer.totalRecords,
        filters: state.viewLogsReducer.filters,
        env: state.rootReducer.env
    };
};

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewLogs)));
