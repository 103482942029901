import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import RefreshIcon from '@material-ui/icons/Refresh';
import { DrcIconButton, DrcTooltip } from 'driscolls-react-components';
import { DuThemeUtilities } from 'driscolls-react-utilities';
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';

class DashboardActions extends Component {
    state = {
        swapIcon: !this.props.expanded
    };

    swapIconHandler = (btn) => {
        this.setState({ swapIcon: !this.state.swapIcon });
        btn.handler();
    };

    DASHBOARD_BUTTONS = [
        {
            icon: KeyboardArrowUpIcon,
            swapIcon: KeyboardArrowDownIcon,
            iconColor: DuThemeUtilities.DefaultColors.primary.green,
            label: 'TogglePanel',
            handler: () => this.props.togglePanel(),
            accessRestricted: false,
            disabled: false
        },
        {
            icon: RefreshIcon,
            iconColor: DuThemeUtilities.DefaultColors.primary.green,
            label: 'Refresh',
            handler: () => this.props.handleRefreshPanel(),
            accessRestricted: false,
            disabled: false
        }
    ];

    getDashboardButtonComponent = (btn, indx, classes, swapIcon) => (
        <DrcIconButton
            key={indx}
            isSecondary
            onClick={btn.swapIcon ? () => this.swapIconHandler(btn) : btn.handler}
            disabled={btn.disabled}
            className={classes.actionButton}
        >
            <DrcTooltip tipText={<Translate id={btn.label} />}>
                {swapIcon && btn.swapIcon ? (
                    <btn.swapIcon style={{ width: '20px', height: '20px', color: btn.iconColor }} className={classes.btnicon} />
                ) : (
                    <btn.icon style={{ width: '20px', height: '20px', color: btn.iconColor }} className={classes.btnicon} />
                )}
            </DrcTooltip>
        </DrcIconButton>
    );
    render() {
        const { classes } = this.props;
        const { swapIcon } = this.state;
        return <div>{this.DASHBOARD_BUTTONS.map((btn, indx) => this.getDashboardButtonComponent(btn, indx, classes, swapIcon))}</div>;
    }
}

export default DashboardActions;
