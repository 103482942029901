import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DrcPanel } from 'driscolls-react-components';
import { DuThemeUtilities } from 'driscolls-react-utilities';

const styles = (theme) => ({
    appTile: {
        borderRadius: '6px',
        border: 'none',
        boxShadow: '0px 1px 4px 2px #eaeaea',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        minWidth: '160px !important',
        maxWidth: '160px !important',
        marginBottom: '8px',
        height: '80px',
        cursor: 'pointer',
        flex: 1,
        margin: '0 8px 0 0',
        [theme.darkTheme]: {
            boxShadow: '0px 0px 0px 1px #555'
        },
        '&:hover': {
            boxShadow: '0px 0px 4px 1px #b5b5b5'
        }
    },
    appTileCount: {
        fontSize: '30px',
        textAlign: 'initial'
    },
    green: {
        color: `${DuThemeUtilities.DefaultColors.primary.green}`
    },
    red: {
        color: `${DuThemeUtilities.DefaultColors.primary.red}`
    },
    purple: {
        color: `${DuThemeUtilities.DefaultColors.primary.purple}`
    },
    pink: {
        color: `${DuThemeUtilities.DefaultColors.primary.pink}`
    },
    appTileLabel: {
        textAlign: 'initial',
        fontWeight: 700
    }
});

class IntegrationErrorAppTile extends Component {
    BAD_ERROR_THRESHOLD = 30;
    GOOD_ERROR_THRESHOLD = 10;

    getClassName = (errorCount) => {
        return (
            this.props.classes.appTileCount +
            ' ' +
            (errorCount >= this.BAD_ERROR_THRESHOLD
                ? this.props.classes.red
                : errorCount <= this.GOOD_ERROR_THRESHOLD
                ? this.props.classes.green
                : errorCount > this.GOOD_ERROR_THRESHOLD && errorCount < this.BAD_ERROR_THRESHOLD
                ? this.props.classes.purple
                : this.props.classes.pink)
        );
    };

    render() {
        const { application, goToAppDetails, classes, errorCountSource } = this.props;
        return (
            <DrcPanel className={classes.appTile} key={application.label}>
                <div onClick={() => goToAppDetails(application.label)} className={classes.appTileLabel}>
                    {application.label}
                </div>
                <div onClick={() => goToAppDetails(application.label)} className={this.getClassName(errorCountSource)}>
                    {errorCountSource}
                </div>
            </DrcPanel>
        );
    }
}

export default withStyles(styles)(IntegrationErrorAppTile);
