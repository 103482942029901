import { withStyles } from '@material-ui/core/styles';
import { DuValidationUtilities } from 'driscolls-react-utilities';
import OverflowTip from '../OverflowTip';
import React, { Component } from 'react';

const styles = () => ({
    dataTableCell: {
        fontSize: 13
    },
    emailAddress: {
        fontWeight: 600,
        fontSize: 13,
        color: '#4a773c',
        textDecoration: 'underline'
    }
});

class LabelFormatter extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { row, field, classes } = this.props;
        var value = row[field.trim()];
        const isEmail = DuValidationUtilities.EMAIL_REGEXP.test(value);
        return (
            <OverflowTip title={value}>
                {isEmail ? (
                    <span>
                        <a className={classes.emailAddress} href={'mailto:' + value}>
                            {value}
                        </a>
                    </span>
                ) : (
                    <span className={classes.dataTableCell}>{value}</span>
                )}
            </OverflowTip>
        );
    }
}

export default withStyles(styles)(LabelFormatter);
