import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';
import { DrcDateRangePicker } from 'driscolls-react-components';
import { checkIfDateIsNotInRange, getPreviousDate } from '../utils/helper';

const styles = () => ({
    drcDateCustom: {
        width: '250px !important'
    },
    dateErrorMsg: {
        color: 'red'
    }
});

class DateRangePicker extends Component {
    render() {
        const { startDate, endDate, onChange, classes } = this.props;
        return (
            <div>
                <DrcDateRangePicker
                    label={<Translate id="DateRange" />}
                    name="date"
                    isDateRange
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onChange}
                    fullWidth={true}
                    className={classes.drcDateCustom}
                    maxDate={getPreviousDate(0)}
                    minDate={getPreviousDate(30)} /* Max Date is todays date and Min Date is 30 days before todays date */
                />
                {checkIfDateIsNotInRange(startDate, endDate, 2) && (
                    <span className={classes.dateErrorMsg}>
                        <Translate id="dateErrorMsg" />
                    </span>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(DateRangePicker);
