import { SET_API_LISTING_FILTERS, SET_API_LISTING_RECORDS } from '../actions/ApiListingActions';

const initialState = {
    apiListData: [],
    totalRecords: 0,
    apiName: '',
    startDate: new Date(),
    endDate: new Date()
};

const ApiListingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_API_LISTING_RECORDS:
            return Object.assign({}, state, {
                apiListData: action.payload.data,
                totalRecords: action.payload.totalRecords
            });
        case SET_API_LISTING_FILTERS:
            return Object.assign({}, state, {
                apiName: action.payload.apiName,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate
            });
        default:
            return state;
    }
};

export default ApiListingReducer;
