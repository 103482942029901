import { DuAuthenticationUtilities, DuDateUtilities } from 'driscolls-react-utilities';
import { Translate } from 'react-localize-redux';
import ColoredLabel from '../components/TableCellFormatters/ColoredLabel';
import ColorLabelsUsingKeys from '../components/TableCellFormatters/ColorLabelsUsingKeys';
import LabelFormatter from '../components/TableCellFormatters/LabelFormatter';
import DisplayBool from '../components/DisplayBool';
import { differenceInDays, endOfDay, startOfDay } from 'date-fns';

export const makeOptions = (data, optionLabel, valueLabel) => {
    let uniqueRecords = removeDuplicates(data, optionLabel);
    return uniqueRecords.map((row) => {
        row.label = row[optionLabel];
        row.value = row[valueLabel];
        return row;
    });
};

export const removeDuplicates = (data, onKey) => {
    let uniqueRecords = [];
    let visited = {};
    data.forEach((record) => {
        if (!visited[record[onKey]]) {
            uniqueRecords.push(record);
            visited[record[onKey]] = 'done';
        }
    });

    return uniqueRecords;
};

export const getUniqueValuesOfKey = (data, ofKey) => Array.from(new Set(data.map((record) => record[ofKey])));

export const hasEditAccess = (token) => {
    const allGroupsWithEditAccess = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || []);
    return DuAuthenticationUtilities.IsInGroup(token, allGroupsWithEditAccess || []);
};

export const getPreviousDate = (noOfDays) => {
    if (noOfDays === 0) {
        return new Date();
    } else {
        //subtracting no of days from todays date to arrive on the particular date
        return new Date(new Date() - 1000 * 60 * 60 * 24 * noOfDays);
    }
};

export const getTitle = (startDate, endDate) => {
    if (!(startDate - endDate)) {
        return `: ${DuDateUtilities.ToPrettyDate(startDate)}`;
    } else {
        return `: ${DuDateUtilities.ToPrettyDate(startDate)} - ${DuDateUtilities.ToPrettyDate(endDate)}`;
    }
};

export const getRowCount = (totalRecords, pageSize) => {
    return totalRecords < pageSize ? totalRecords : pageSize;
};

export const coloredLabelTemplate = (name) => (row) => <ColoredLabel val={row[name]} />;

export const colorBgTemplate = (name) => (row) => <ColorLabelsUsingKeys val={row[name]} field={name} />;

export const reprocessTemplate = (name) => (row) => <DisplayBool val={row[name] === 1 ? true : false} />;

export const labelTemplate = (name) => (row) => <LabelFormatter row={row} field={name} />;

export const ReInitializeMasterOptions = async (setApplicationList) => setApplicationList([]);

export const getRandomColor = () => '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);

export const envs = [
    { label: 'Dev', value: 'dev' },
    { label: 'Test', value: 'tst' },
    { label: 'UAT', value: 'uat' },
    { label: 'Stage', value: 'stg' },
    { label: 'Production', value: 'prd' }
];

export const getCurrentEnvironment = (subDomain) => {
    let environment = envs.find((val) => val.label.toLowerCase() === subDomain)?.value;
    return environment ? environment : 'prd';
};

export const formatRecords = (data, key) => {
    return data.map((val) => {
        return {
            ...val,
            [key]: val[key].trim()
        };
    });
};

export const getIsoDate = (date, condition = false) => {
    return DuDateUtilities.ToIsoDate(date, condition);
};

export const getPrettyDateTime = (dateTime) => {
    return DuDateUtilities.ToPrettyDateTime(dateTime);
};

export const checkIfDateIsNotInRange = (startDate, endDate, range) => {
    return differenceInDays(endDate, startDate) > range;
};

export const getLoadingMessage = (id) => {
    return { loadingMessage: <Translate id={id} /> };
};

export const getCurrentLoggedInUserName = (token) => {
    return DuAuthenticationUtilities.GetEmail(token);
};

export const getAccessToken = async (props) => {
    let token = await props.authService.getAccessToken();
    if (token) {
        return token;
    }
    props.setLogoutMsg('sessionLoggedOut');
    props.history?.push('/LogOut/');
    return null;
};

export const getStartIsoDate = (date) => {
    date = startOfDay(date);
    return date.toISOString().slice(0, 19).replace('T', ' ');
};

export const getEndIsoDate = (date) => {
    date = endOfDay(date);
    return date.toISOString().slice(0, 19).replace('T', ' ');
};
