import { withStyles } from '@material-ui/core/styles';
import { DrcButton } from 'driscolls-react-components';
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { setErrorsAction } from '../actions/actions';

const styles = () => ({
    noRecordsMessage: {
        display: 'flex',
        height: '100px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorMessage: {
        fontSize: '14px',
        color: '#e34843'
    },
    info: {
        fontSize: '14px'
    }
});
class GridNoRecords extends Component {
    openErrorDialog = () => {
        this.props.setErrorsAction('Error', 'Error Occurred while Fetching Data', JSON.stringify(this.props.errorData));
    };

    render() {
        const { isError, classes } = this.props;
        return (
            <div className={classes.noRecordsMessage}>
                <div>
                    <div>
                        <h2 className={isError ? classes.errorMessage : classes.info}>
                            {isError ? (
                                <Translate id="gridErrorMessage" />
                            ) : (
                                <Translate id="NoRecordsToDisplay" />
                            )}
                        </h2>
                        {isError && (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <DrcButton onClick={this.openErrorDialog}>
                                    <Translate>View Error</Translate>
                                </DrcButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setErrorsAction: (title, error, errorstack) => dispatch(setErrorsAction(title, error, errorstack))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(GridNoRecords));
