export const SET_ERRORS = 'SET_ERRORS';
export const HIDE_ERROR_DIALOG = 'HIDE_ERROR_DIALOG';

export const LOGOUT = 'LOGOUT';
export const SET_LOGOUT_MSG = 'SET_LOGOUT_MSG';

export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN';
export const HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN';

// Master Actions
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const setPageTitleAction = (pageTitle) => ({
    type: SET_PAGE_TITLE,
    payload: pageTitle
});

export const SET_APP_FILTER = 'SET_APP_FILTER';
export const setAppFilter = (filters) => ({
    type: SET_APP_FILTER,
    payload: filters
});

export const ADD_INFO = 'ADD_INFO';
export const addInfo = (info) => ({
    type: ADD_INFO,
    payload: info
});

// Modal Reducers
export const setErrorsAction = (title, errors, errorstack) => ({
    type: SET_ERRORS,
    payload: { title, errors, errorstack }
});

export const hideErrorDialogAction = () => ({
    type: HIDE_ERROR_DIALOG,
    payload: {}
});

export const logoutAction = () => ({
    type: LOGOUT,
    payload: {}
});

export const setLogoutMsg = (logoutMessage) => ({
    type: SET_LOGOUT_MSG,
    payload: { logoutMessage }
});

export const showLoadingScreenAction = (loadingMessage) => ({
    type: SHOW_LOADING_SCREEN,
    payload: loadingMessage
});

export const hideLoadingScreenAction = () => ({
    type: HIDE_LOADING_SCREEN,
    payload: {}
});

export const SET_ENV = 'SET_ENV';
export const setEnv = (env) => ({
    type: SET_ENV,
    payload: env
});

export const SET_READONLY_ACCESS = 'SET_READONLY_ACCESS';
export const setIsReadOnly = (data) => ({
    type: SET_READONLY_ACCESS,
    payload: data
});

export const SHOW_TOAST = 'SHOW_TOAST';
export const showToast = (toastMessage, isSuccess) => ({
    type: SHOW_TOAST,
    payload: { toastMessage, isSuccess }
});
