import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DrcButton, DrcSelect } from 'driscolls-react-components';
import { Translate } from 'react-localize-redux';
import DateRangePicker from '../DateRangePicker';
import BackButton from '../BackButton';
import { setApiListingFilters } from '../../actions/ApiListingActions';
import ApplicationSelectionDropDown from '../ApplicationSelectionDropDown';
import { checkIfDateIsNotInRange } from '../../utils/helper';

const styles = () => ({
    filterContainer: {
        display: 'flex',
        flexFlow: 'row wrap'
    },
    drcSelectCustom: {
        width: '175px !important',
        paddingRight: '8px'
    },
    searchBtn: {
        alignSelf: 'baseline'
    }
});

class ApiListingFilter extends Component {
    state = {
        startDate: this.props.appName ? this.props.startDate : new Date(),
        endDate: this.props.appName ? this.props.endDate : new Date(),
        apiName: this.props.appName && this.props.apiName ? { label: this.props.apiName, value: this.props.apiName } : { label: 'All', value: '' },
        application: this.props.appName ? { label: this.props.appName, value: this.props.appName } : {}
    };

    handleDateChange = (startDate, endDate) => {
        this.setState({ startDate: startDate, endDate: endDate });
    };

    handleSelectChange = (data, name) => {
        if (name === 'application') {
            this.props.getApiNames(data.value);
            this.setState({ [name]: data, apiName: { label: 'All', value: '' } });
        } else {
            this.setState({ [name]: data });
        }
    };

    Search = () => {
        var data = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            apiName: this.state.apiName.value ? this.state.apiName.value : '',
            appName: this.state.application.label
        };
        this.props.setApiListingFilters(data.apiName, data.startDate, data.endDate);
        this.props.handleSearch(data);
    };

    checkIfAppValueIsValid = () => {
        return this.state.application.label;
    };

    render() {
        const { classes } = this.props;
        return (
            <>
                <div>
                    <BackButton linkText={<Translate id="ApplicationListing" />} link="/ApplicationList/" />
                </div>
                <div className={classes.filterContainer}>
                    <ApplicationSelectionDropDown
                        value={this.state.application}
                        onChange={(data) => this.handleSelectChange(data, 'application')}
                        required={true}
                    />
                    <DrcSelect
                        label={<Translate id="ApiName" />}
                        required
                        name="apiName"
                        onChange={(data) => this.handleSelectChange(data, 'apiName')}
                        value={this.state.apiName}
                        options={this.props.apiNamesList}
                        fullWidth={true}
                        className={classes.drcSelectCustom}
                    />
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onChange={(startDate, endDate) => this.handleDateChange(startDate, endDate)}
                    />
                    <DrcButton
                        isPrimary
                        className={classes.searchBtn}
                        onClick={this.Search}
                        disabled={!this.checkIfAppValueIsValid() || checkIfDateIsNotInRange(this.state.startDate, this.state.endDate, 2)}
                    >
                        {<Translate id="search" />}
                    </DrcButton>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        startDate: state.apiListingReducer.startDate,
        endDate: state.apiListingReducer.endDate,
        apiName: state.apiListingReducer.apiName
    };
};
const mapDispatchToProps = (dispatch) => ({
    setApiListingFilters: (apiName, startDate, endDate) => dispatch(setApiListingFilters(apiName, startDate, endDate))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApiListingFilter));
