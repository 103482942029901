import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DrcButton } from 'driscolls-react-components';
import { Translate } from 'react-localize-redux';
import DateRangePicker from '../DateRangePicker';
import BackButton from '../BackButton';
import { setApplicationListingFilters } from '../../actions/ApplicationListingActions';
import { checkIfDateIsNotInRange } from '../../utils/helper';

const styles = () => ({
    filterContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    filterItems: {
        display: 'flex',
        flexFlow: 'row wrap'
    },
    searchBtn: {
        alignSelf: 'baseline'
    }
});

class Filter extends Component {
    state = {
        startDate: this.props.filters.startDate ? this.props.filters.startDate : new Date(),
        endDate: this.props.filters.endDate ? this.props.filters.endDate : new Date()
    };

    handleDateChange = (startDate, endDate) => {
        this.setState({ startDate: startDate, endDate: endDate });
    };

    Search = () => {
        var data = {
            startDate: this.state.startDate,
            endDate: this.state.endDate
        };
        this.props.setApplicationListingFilters({ startDate: this.state.startDate, endDate: this.state.endDate });
        this.props.handleSearch(data);
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.filterContainer}>
                <div>
                    <BackButton linkText={<Translate id="backToHome" />} link="/Dashboard/" />
                </div>
                <div className={classes.filterItems}>
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onChange={(startDate, endDate) => this.handleDateChange(startDate, endDate)}
                    />
                    <DrcButton
                        isPrimary
                        className={classes.searchBtn}
                        onClick={this.Search}
                        disabled={checkIfDateIsNotInRange(this.state.startDate, this.state.endDate, 2)}
                    >
                        {<Translate id="search" />}
                    </DrcButton>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.applicationListingReducer.filters
    };
};
const mapDispatchToProps = (dispatch) => ({
    setApplicationListingFilters: (data) => dispatch(setApplicationListingFilters(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Filter));
