import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { DuThemeUtilities } from 'driscolls-react-utilities';
import { DrcButton } from 'driscolls-react-components';
import { Translate } from 'react-localize-redux';
import LibraryAdd from '@material-ui/icons/LibraryAddOutlined';
import EmojiObjects from '@material-ui/icons/EmojiObjectsOutlined';
import IsEditAllowed from '../IsEditAllowed';

const ViewLogsListActions = (
    startReprocessCreation,
    startViewLog,
    startTktCreation,
    startPurge,
    startViewPayload,
    viewPossibleSolutions,
    addToLibrary,
    classes
) => (row, currentUser, sourceValue) => {
    let actionButtons = [
        {
            icon: SettingsOutlinedIcon,
            iconColor: DuThemeUtilities.DefaultColors.primary.blue,
            label: 'Reprocess',
            handler: () => startReprocessCreation(row),
            accessRestricted: true,
            disabled: row?.reprocessable === 0 || row?.locked === 0 || currentUser !== row?.updatedBy
        },
        {
            icon: InsertDriveFileOutlinedIcon,
            iconColor: '#af5454',
            label: 'ViewLog',
            handler: () => startViewLog(row),
            accessRestricted: true,
            disabled: false
        },
        {
            icon: ConfirmationNumberOutlinedIcon,
            iconColor: DuThemeUtilities.DefaultColors.primary.purple,
            label: 'CreateIncident',
            handler: () => startTktCreation(row),
            accessRestricted: true,
            disabled: sourceValue === 'DB' ? row?.disableTicketCreation || row?.locked === 0 || currentUser !== row?.updatedBy : false
        },
        {
            icon: DeleteForeverOutlinedIcon,
            iconColor: DuThemeUtilities.DefaultColors.primary.red,
            label: 'Purge',
            handler: () => startPurge(row),
            accessRestricted: true,
            disabled: row?.disablePurge || row?.locked === 0 || currentUser !== row?.updatedBy
        },
        {
            icon: DescriptionOutlinedIcon,
            iconColor: '#d76707',
            label: 'ViewPayload',
            handler: () => startViewPayload(row),
            accessRestricted: false,
            disabled: row?.disablePurge
        },
        {
            icon: EmojiObjects,
            iconColor: '#d8de29',
            label: 'PossibleSolution',
            handler: () => viewPossibleSolutions(row),
            accessRestricted: true,
            disabled: false
        },
        {
            icon: LibraryAdd,
            iconColor: DuThemeUtilities.DefaultColors.primary.green,
            label: 'AddSolutionToLibrary',
            handler: () => addToLibrary(row),
            accessRestricted: true,
            disabled: row?.disableAddSolution !== 0
        }
    ];

    const getButtonComponent = (btn, indx) =>
        btn.disabled ? (
            ''
        ) : (
            <DrcButton key={indx} onClick={btn.handler} size="small" disabled={btn.disabled} className={classes.menuOptionButton}>
                <btn.icon style={{ float: 'left', width: '20%', color: btn.iconColor }} />
                <label className={classes.actionButtonLabel}>
                    <Translate id={btn.label} />
                </label>
            </DrcButton>
        );

    return (
        <div>
            {actionButtons.map((btn, indx) =>
                btn.accessRestricted ? <IsEditAllowed key={btn.label}>{getButtonComponent(btn, indx)}</IsEditAllowed> : getButtonComponent(btn, indx)
            )}
        </div>
    );
};

export default ViewLogsListActions;
