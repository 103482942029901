import { Middleware } from 'one-ring';
const getApiListMapping = [
    Middleware.CreateMapping('apiName', 'apiName'),
    Middleware.CreateMapping('applicationType', 'applicationType'),
    Middleware.CreateMapping('apiVersion', 'apiVersion'),
    Middleware.CreateMapping('errorCount', 'errorCount'),
    Middleware.CreateMapping('dbErrorCount', 'dbErrorCount'),
    Middleware.CreateMapping('nrErrorCount', 'nrErrorCount'),
    Middleware.CreateMapping('muleVersion', 'muleVersion'),
    Middleware.CreateArrayMapping('apiListing', 'apiListing')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('ApiList', 'GET')) {
        Middleware.RegisterMapping('ApiList', 'GET', getApiListMapping);
    }
}

const ApiListMapping = {
    ConfigureMiddleware
};

export default ApiListMapping;
