import React, { Component } from 'react';
import { DrcInput, DrcDialog, DrcButton, DrcTooltip, DrcIconButton } from 'driscolls-react-components';
import { Translate } from 'react-localize-redux';
import { withStyles } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopy from '@material-ui/icons/FileCopy';
import { DuThemeUtilities } from 'driscolls-react-utilities';
import { Close } from '@material-ui/icons';
import isEqual from 'lodash/isEqual';
import TypeAheadAsync from './TypeAheadAsync';

const styles = (theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '900px',
            width: '100%',
            padding: '8px 16px',
            borderRadius: '8px',
            [theme.darkTheme]: {
                padding: '0px 0px !important'
            }
        },
        '& .MuiDialogActions-root': {
            border: 'none !important',
            [theme.darkTheme]: {
                backgroundColor: 'none !important'
            }
        }
    },
    solutionInput: {
        height: '300px !important',
        '& .MuiInputBase-root': {
            height: '280px !important'
        },
        '& .MuiOutlinedInput-inputMultiline': {
            overflow: 'scroll !important',
            height: '250px !important'
        },
        '& MuiOutlinedInput-input': {
            padding: '16px 0px !important'
        },
        '& MuiOutlinedInput-inputMarginDense': {
            paddingTop: '0px !important',
            margin: '16px 0px !important'
        }
    },
    copyIcon: {
        alignSelf: 'baseline',
        height: '16px',
        cursor: 'pointer',
        '&:hover': {
            color: DuThemeUtilities.DefaultColors.primary.purple
        }
    },
    button: {
        width: '120px !important'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey
    }
});

class AddUpdateSolution extends Component {
    state = {
        statusCode: '',
        errorMessage: '',
        rootCause: '',
        apiName: '',
        solution: '',
        protocol: '',
        appErrorCode: '',
        endPoint: '',
        selected: {}
    };

    getInputs = () => {
        return [
            {
                id: 'appErrorCode',
                placeholder: 'Max 40 characters',
                maxLength: 40,
                readOnly: this.props.isAddSolution ? true : this.checkIfUpdateDisabled(),
                required: true
            },
            {
                id: 'statusCode',
                placeholder: 'Max 40 characters',
                maxLength: 40,
                readOnly: this.checkIfDisabled(),
                required: true
            },
            {
                id: 'errorMessage',
                placeholder: 'Max 5000 characters',
                maxLength: 5000,
                readOnly: this.checkIfDisabled(),
                required: true
            },
            {
                id: 'rootCause',
                placeholder: 'Max 100 characters',
                maxLength: 100,
                readOnly: this.checkIfDisabled(),
                required: true
            },

            {
                id: 'endPoint',
                placeholder: 'Max 500 characters',
                maxLength: 500,
                readOnly: this.props.isAddSolution ? true : this.checkIfDisabled(),
                required: true
            },
            {
                id: 'protocol',
                placeholder: 'Max 25 characters',
                maxLength: 25,
                readOnly: this.checkIfDisabled(),
                required: false
            }
        ];
    };

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.selectedRow, this.props.selectedRow)) {
            this.setState({
                statusCode: this.props.selectedRow && this.props.selectedRow.statusCode ? this.props.selectedRow.statusCode : '',
                errorMessage: this.props.selectedRow && this.props.selectedRow.errorMessage ? this.props.selectedRow.errorMessage : '',
                rootCause: this.props.selectedRow && this.props.selectedRow.rootCause ? this.props.selectedRow.rootCause : '',
                apiName: this.props.selectedRow && this.props.selectedRow.apiName ? this.props.selectedRow.apiName : '',
                solution: this.props.selectedRow && this.props.selectedRow.solution ? this.props.selectedRow.solution : '',
                protocol: this.props.selectedRow && this.props.selectedRow.protocol ? this.props.selectedRow.protocol : '',
                appErrorCode: this.props.selectedRow && this.props.selectedRow.appErrorCode ? this.props.selectedRow.appErrorCode : '',
                endPoint: this.props.selectedRow && this.props.selectedRow.endPoint ? this.props.selectedRow.endPoint : '',
                selected:
                    this.props.selectedRow && this.props.selectedRow.apiName
                        ? { label: this.props.selectedRow.apiName, value: this.props.selectedRow.apiName }
                        : {}
            });
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    checkIfAllMandatoryFieldsAreFilled = () => {
        return (
            this.state.statusCode &&
            this.state.errorMessage &&
            this.state.rootCause &&
            this.state.solution &&
            this.state.appErrorCode &&
            this.state.endPoint
        );
    };

    saveOrUpdateSolution = () => {
        this.props.saveOrUpdateSolution({
            statusCode: this.state.statusCode,
            errorMessage: this.state.errorMessage,
            rootCause: this.state.rootCause,
            apiName: this.state.selected && this.state.selected.value ? this.state.selected.value : '',
            solution: this.state.solution,
            protocol: this.state.protocol,
            appErrorCode: this.state.appErrorCode,
            endPoint: this.state.endPoint
        });
        this.reset();
    };

    checkIfDisabled = () => {
        return this.props.isViewOnly ? true : false;
    };

    checkIfUpdateDisabled = () => {
        return this.props.isViewOnly ? true : this.props.updating ? true : false;
    };

    handleCopy = (solution) => {
        navigator.clipboard.writeText(solution);
    };

    reset = () => {
        this.setState({
            statusCode: '',
            errorMessage: '',
            rootCause: '',
            apiName: '',
            solution: '',
            protocol: '',
            appErrorCode: '',
            endPoint: '',
            selected: {}
        });
    };

    close = () => {
        this.reset();
        this.props.cancel();
    };

    loadOptions = (value) => {
        if (value.length >= 3 || !value.length) {
            return this.props.loadOptions(value);
        }
    };

    onAsyncChange = (value) => {
        this.setState({ selected: value });
    };

    onAsyncInputChange = (value) => {
        this.setState({ apiName: value });
    };

    getApiNameInput = () =>
        this.props.isAddSolution || this.props.isViewOnly ? (
            <DrcInput
                label={<Translate id={'apiName'} />}
                name={'apiName'}
                placeholder={'Max 50 characters'}
                value={this.state.apiName}
                onChange={this.handleChange}
                inputProps={{ maxLength: 40, readOnly: true }}
                required={false}
            />
        ) : (
            <TypeAheadAsync
                className={this.props.classes.asyncItem}
                label={<Translate id={'apiName'} />}
                loadOptions={this.loadOptions}
                onChange={this.onAsyncChange}
                onInputChange={this.onAsyncInputChange}
                selectedOption={this.state.selected}
            />
        );

    render() {
        const { title, open, classes, updating, isViewOnly } = this.props;
        return (
            <DrcDialog
                title={
                    <>
                        {title}
                        <DrcIconButton isSecondary className={classes.closeButton} onClick={this.close}>
                            <DrcTooltip title={<Translate id="Close" />}>
                                <Close />
                            </DrcTooltip>
                        </DrcIconButton>
                    </>
                }
                open={open}
                buttons={
                    <div>
                        {!isViewOnly && (
                            <DrcButton isSecondary onClick={this.close} className={classes.button}>
                                <Translate id={'Cancel'} />
                            </DrcButton>
                        )}
                        {!isViewOnly &&
                            (updating ? (
                                <DrcButton
                                    isPrimary
                                    onClick={this.saveOrUpdateSolution}
                                    disabled={!this.checkIfAllMandatoryFieldsAreFilled()}
                                    className={classes.button}
                                >
                                    <Translate id="update" />
                                </DrcButton>
                            ) : (
                                <DrcButton
                                    isPrimary
                                    onClick={this.saveOrUpdateSolution}
                                    disabled={!this.checkIfAllMandatoryFieldsAreFilled()}
                                    className={classes.button}
                                >
                                    <Translate id="save" />
                                </DrcButton>
                            ))}
                    </div>
                }
                className={classes.dialog}
            >
                {this.getInputs().map((data, index) => (
                    <DrcInput
                        label={<Translate id={data.id} />}
                        name={data.id}
                        placeholder={data.placeholder}
                        value={this.state[data.id]}
                        onChange={this.handleChange}
                        inputProps={{ readOnly: data.readOnly, maxLength: data.maxLength }}
                        required={data.required}
                        key={index}
                    />
                ))}
                {this.getApiNameInput()}
                <DrcInput
                    className={classes.solutionInput}
                    label={<Translate id="Solution" />}
                    name="solution"
                    value={this.state.solution}
                    onChange={this.handleChange}
                    required
                    multiline
                    rowsMax={10}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" className={classes.copyIcon}>
                                <FileCopy onClick={() => this.handleCopy(this.state.solution)} />
                            </InputAdornment>
                        ),
                        readOnly: this.checkIfDisabled()
                    }}
                />
            </DrcDialog>
        );
    }
}

export default withStyles(styles)(AddUpdateSolution);
