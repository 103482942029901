import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';
import { DrcLegend } from 'driscolls-react-components';

const styles = () => ({
    headerDiv: {
        display: 'flex'
    },
    sectionHeader: {
        border: 'none',
        color: 'gray',
        marginBottom: '0px'
    },
    pastHeaderSpan: {
        marginLeft: '10px',
        color: '#4a773c'
    }
});

class IntegrationErrorHeader extends Component {
    render() {
        const { classes, errorSourceId,translationId} = this.props;
        return (
            <div className={classes.headerDiv}>
                <div>
                    <DrcLegend className={classes.sectionHeader}>
                        <Translate id={errorSourceId} />
                    </DrcLegend>
                    <span className={classes.pastHeaderSpan}>
                        (<Translate id={translationId || 'pastDays'} />)
                    </span>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(IntegrationErrorHeader);
