import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DrcButton, DrcSelect, DrcInput } from 'driscolls-react-components';
import { Translate } from 'react-localize-redux';
import ApplicationSelectionDropDown from '../ApplicationSelectionDropDown';
import { setErrorLibraryFilters } from '../../actions/ErrorLibraryActions';

const styles = () => ({
    filterContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '8px',
        flexFlow: 'row wrap'
    },
    actionButton: {
        width: '24px',
        height: '32px',
        marginRight: '2px',
        marginTop: '-2px'
    },
    drcInputCustom: {
        width: '175px !important',
        paddingRight: '8px'
    }
});

class ErrorLibraryFilter extends Component {
    state = {
        rootCause: this.props.filters.rootCause,
        errorCode: this.props.filters.errorCode,
        errorMessage: this.props.filters.errorMessage,
        application: this.props.filters.applicationName
            ? { label: this.props.filters.applicationName, value: this.props.filters.applicationName }
            : null,
        apiName: this.props.filters.apiName
            ? { label: this.props.filters.apiName, value: this.props.filters.apiName }
            : this.props.filters.apiName === ''
            ? { label: 'All', value: '' }
            : null
    };
    inputs = [
        { name: 'errorCode', placeholder: 'Error Code' },
        { name: 'errorMessage', placeholder: 'Error Message' },
        { name: 'rootCause', placeholder: 'Root Cause' }
    ];
    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSelectChange = (data, name) => {
        if (name === 'application') {
            this.props.getProcessListData(data.value);
            this.setState({ [name]: data, apiName: { label: 'All', value: '' } });
        } else {
            this.setState({ [name]: data });
        }
    };

    onSearchButtonClick = async () => {
        let data = {
            rootCause: this.state.rootCause,
            errorCode: this.state.errorCode,
            errorMessage: this.state.errorMessage,
            applicationName: this.state.application ? this.state.application.value : '',
            apiName: this.state.apiName ? this.state.apiName.value : ''
        };
        this.props.setErrorLibraryFilters(data);
        this.props.handleSearch(data);
    };

    onResetButtonClick = async () => {
        let data = {
            rootCause: '',
            errorCode: '',
            errorMessage: '',
            applicationName: null,
            apiName: null
        };
        this.setState({ rootCause: '', errorCode: '', errorMessage: '', application: null, apiName: null });
        this.props.setErrorLibraryFilters(data);
        this.props.handleSearch(data);
    };

    checkIfButtonDisabled = () => {
        return this.state.application && !this.state.apiName
            ? true
            : !(this.state.errorCode || this.state.errorMessage || this.state.rootCause || this.state.apiName);
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.filterContainer}>
                {this.inputs.map((input, index) => (
                    <DrcInput
                        name={input.name}
                        key={index}
                        placeholder={input.placeholder}
                        label={<Translate id={input.name} />}
                        onChange={this.handleInputChange}
                        value={this.state[input.name]}
                        className={classes.drcInputCustom}
                    />
                ))}
                <ApplicationSelectionDropDown
                    value={this.state.application}
                    onChange={(data) => this.handleSelectChange(data, 'application')}
                    required={false}
                />
                <DrcSelect
                    label={<Translate id="api" />}
                    name="apiName"
                    onChange={(data) => this.handleSelectChange(data, 'apiName')}
                    value={this.state.apiName}
                    options={this.props.apiNameList}
                    fullWidth={true}
                    className={classes.drcInputCustom}
                />
                <DrcButton isPrimary onClick={this.onSearchButtonClick} disabled={this.checkIfButtonDisabled()} className={classes.actionButton}>
                    {<Translate id="search" />}
                </DrcButton>
                <DrcButton isSecondary onClick={this.onResetButtonClick} className={classes.actionButton}>
                    {<Translate id="reset" />}
                </DrcButton>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.errorLibraryReducer.filters
    };
};
const mapDispatchToProps = (dispatch) => ({
    setErrorLibraryFilters: (data) => dispatch(setErrorLibraryFilters(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ErrorLibraryFilter));
