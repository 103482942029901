import React, { Component } from 'react';
import { connect } from 'react-redux';

class IsEditAllowed extends Component {
    render() {
        return <>{this.props.hasReadOnlyAccess ? '' : this.props.children}</>;
    }
}

function mapStateToProps(state) {
    return {
        hasReadOnlyAccess: state.rootReducer.isReadOnly
    };
}

export default connect(mapStateToProps, null)(IsEditAllowed);
