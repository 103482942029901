import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import OverflowTip from '../OverflowTip';

const styles = () => ({
    container: {
        display: 'flex'
    },
    label: {
        color: '#eee',
        alignSelf: 'center',
        padding: '4px',
        textAlign: 'center',
        fontFamily: 'rubik',
        borderRadius: '4px',
        letterSpacing: '0.5px'
    }
});

function ColoredLabel({ classes, val }) {
    const appliedColor = () => {
        if (isNaN(val)) {
            switch (val?.toLowerCase()) {
                case 'https':
                    return '#00b300';
                case 'post':
                    return '#49cc90';
                case 'get':
                    return '#62affe';
                case 'delete':
                    return '#f96977';
                case 'patch':
                    return '#fca130';
                case 'put':
                    return '#fca130';
                case 'api':
                    return '#9e2b92';
                case 'mq-process':
                    return '#f1aa07';
                default:
                    return '#e73508';
            }
        } else {
            if (val >= 300 && val < 400) {
                return '#ff4d4d';
            } else if (val >= 400 && val < 500) {
                return '#ff3333';
            } else if (val >= 500) {
                return '#cc0000';
            } else {
                return '#ff0080';
            }
        }
    };
    return (
        <div className={classes.container}>
            <OverflowTip title={val}>
                <label style={{ background: appliedColor() }} className={`${classes.label}`}>
                    {(val || '').toString().toUpperCase()}
                </label>
            </OverflowTip>
        </div>
    );
}

export default withStyles(styles)(ColoredLabel);
