import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class IsMasterDataInitialized extends Component {
    render() {
        return <>{!this.props.isInitialized ? <Redirect to="/InitializeApplication/" /> : this.props.children}</>;
    }
}

function mapStateToProps(state) {
    return {
        isInitialized: state.masterReducer.isInitialized
    };
}

export default connect(mapStateToProps, null)(IsMasterDataInitialized);
