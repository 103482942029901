import React from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
    link: {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: `${theme.palette.primary.main}`,
        fontSize: '13px',
        fontWeight: 'bold'
    }
});

function DisplayLink({ linkText, link, classes, errorSource, ...props }) {
    return (
        <div className={classes.link} onClick={() => props.history.push(link, { source: errorSource })}>
            {linkText}
        </div>
    );
}

export default withRouter(withStyles(styles)(DisplayLink));
