import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';
import { DrcLegend } from 'driscolls-react-components';

const styles = (theme) => ({
    pageHeader: {
        width: '700px !important',
        border: 'none',
        margin: '8px 0px 0px 0px',
        color: `${theme.palette.secondary.dark}`,
        [theme.darkTheme]: {
            color: '#da291c'
        }
    }
});

class HeaderTitle extends Component {
    render() {
        const { id, title, classes } = this.props;
        return (
            <DrcLegend className={classes.pageHeader}>
                <Translate id={id} />
                {title ? title : ''}
            </DrcLegend>
        );
    }
}

export default withStyles(styles)(HeaderTitle);
