import { Middleware } from 'one-ring';
const getApplicationListMapping = [
    Middleware.CreateMapping('appName', 'appName'),
    Middleware.CreateMapping('appVersion', 'appVersion'),
    Middleware.CreateMapping('errorCount', 'Errorcount'),
    Middleware.CreateMapping('dbErrorCount', 'dbErrorCount'),
    Middleware.CreateMapping('nrErrorCount', 'nrErrorCount'),
    Middleware.CreateMapping('reprocessable', 'reprocessable'),
    Middleware.CreateArrayMapping('applicationListing', 'applicationListing')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('ApplicationList', 'GET')) {
        Middleware.RegisterMapping('ApplicationList', 'GET', getApplicationListMapping);
    }
}

const ApplicationListMapping = {
    ConfigureMiddleware
};

export default ApplicationListMapping;
