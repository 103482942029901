import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DrcPanel } from 'driscolls-react-components';
import IntegrationErrorAppTile from './IntegrationErrorAppTile';
import IntegrationErrorHeader from './IntegrationErrorHeader';
import DashboardActions from './DashboardActions';

const styles = (theme) => ({
    errorsByApp: {
        width: '100%',
        flex: 1
    },
    section: {
        borderRadius: '4px',
        boxShadow: '0px 0px 6px 0px #b5b5b5',
        maxWidth: 'calc(100vw - 40px) !important',
        maxHeight: 'calc(100vh - 170px) !important',
        [theme.darkTheme]: {
            boxShadow: 'none !important'
        }
    },
    integrationsErrorContainer: {
        display: 'flex',
        marginTop: '5px',
        flexFlow: 'wrap',
        overflow: 'auto',
        padding: '5px',
        maxHeight: 'calc(85vh - 160px) !important'
    },
    refreshIcon: {
        width: '25px',
        height: '25px',
        float: 'right',
        minWidth: '25px'
    },
    actionButton: {
        width: '25px',
        height: '25px',
        float: 'right',
        minWidth: '25px'
    }
});

class IntegrationErrors extends Component {
    state = {
        showProcessErrorPanel: true,
        showNewRelicErrorPanel: false
    };

    handleRefreshPanel = () => {
        this.props.refreshData();
    };

    handleTogglePanel = () => {
        this.setState({ showProcessErrorPanel: !this.state.showProcessErrorPanel });
    };

    handleNewRelicTogglePanel = () => {
        this.setState({ showNewRelicErrorPanel: !this.state.showNewRelicErrorPanel });
    };

    render() {
        const { applicationList, goToAppDetails, classes } = this.props;
        return (
            <div className={classes.errorsByApp}>
                <DrcPanel className={classes.section}>
                    <DashboardActions
                        expanded={this.state.showProcessErrorPanel}
                        classes={classes}
                        handleRefreshPanel={this.handleRefreshPanel}
                        togglePanel={this.handleTogglePanel}
                    />
                    <IntegrationErrorHeader errorSourceId="processErrors" />
                    {this.state.showProcessErrorPanel ? (
                        <div className={classes.integrationsErrorContainer}>
                            {applicationList.map((application) => (
                                <IntegrationErrorAppTile
                                    application={application}
                                    key={application?.label}
                                    errorCountSource={application?.errorCount}
                                    goToAppDetails={goToAppDetails}
                                />
                            ))}
                        </div>
                    ) : (
                        ''
                    )}
                </DrcPanel>

                <DrcPanel className={classes.section}>
                    <DashboardActions
                        expanded={this.state.showNewRelicErrorPanel}
                        classes={classes}
                        handleRefreshPanel={this.handleRefreshPanel}
                        togglePanel={this.handleNewRelicTogglePanel}
                    />
                    <IntegrationErrorHeader errorSourceId="newRelicErrors" />
                    {this.state.showNewRelicErrorPanel ? (
                        <div className={classes.integrationsErrorContainer}>
                            {applicationList.map((application) => (
                                <IntegrationErrorAppTile
                                    application={application}
                                    key={application?.label}
                                    errorCountSource={application?.newRelicErrorCount}
                                    goToAppDetails={goToAppDetails}
                                />
                            ))}
                        </div>
                    ) : (
                        ''
                    )}
                </DrcPanel>
            </div>
        );
    }
}

export default withStyles(styles)(IntegrationErrors);
