import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { withOktaAuth } from '@okta/okta-react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DrcButton, DrcChips, DrcPanel, DrcSelect, DrcSwitch } from 'driscolls-react-components';
import { DuDateUtilities } from 'driscolls-react-utilities';
import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { showToast } from '../../actions/actions';
import IntegrationErrorHeader from './IntegrationErrorHeader';

const PROCESS_ERRORS = 'processerrors';
const NEWRELIC_ERRORS = 'newrelicerrors';

const styles = (theme) => ({
    errorsByApp: {
        width: '100%',
        flex: 1
    },
    section: {
        borderRadius: '4px',
        boxShadow: '0px 0px 6px 0px #b5b5b5',
        maxWidth: 'calc(100vw - 40px) !important',
        maxHeight: '1080px !important',
        [theme.darkTheme]: {
            boxShadow: 'none !important'
        }
    },
    integrationsErrorContainer: {
        display: 'flex',
        marginTop: '5px',
        flexFlow: 'wrap',
        overflow: 'auto',
        padding: '5px',
        height: '40vh'
    },
    includeSelect: {
        width: '180px',
        marginRight: '2px'
    },
    switchStyle: {
        margin: '0',
        padding: '0',
        maxWidth: '150px !important',
        height: '40px'
    },
    container: {
        display: 'flex',
        padding: '5px',
        overflow: 'auto',
        flexFlow: 'wrap',
        marginTop: '5px',
        maxHeight: 'calc(85vh - 160px) !important',
        alignItems: 'center'
    }
});

class ErrorTrends extends Component {
    localCharts = [];
    options = {
        scales: {
            yAxes: [
                {
                    gridLines: { display: true },
                    ticks: {
                        beginAtZero: true
                    }
                }
            ]
        },
        legend: {
            display: false,
            position: 'bottom'
        },
        responsive: true,
        maintainAspectRatio: false
    };
    state = {
        showFilter: false,
        selectedApplication: {},
        selectedApplications: [],
        charts: [],
        source: { label: 'Process Errors', value: 'processerrors' },
        sourceOptions: [
            { label: 'Process Errors', value: PROCESS_ERRORS },
            { label: 'New Relic Errors', value: NEWRELIC_ERRORS }
        ],
        barChartData: [],
        showLegend: false,
        showLabels: false,
        disableLegend: true
    };

    componentDidMount() {
        Bar.plugins?.register(ChartDataLabels);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.errorTrends !== this.props.errorTrends || prevState.selectedApplications !== this.state.selectedApplications) {
            this.response = this.props.errorTrends;
            this.setState({ selectedApplications: this.props.defaultTrendingApps });
            this.initializeErrorTrends(this.state.source.value);
        }
    }

    //Get a constant color for each app name
    getColor = (appName) => {
        var hash = 0;
        appName.split('').map((char, i) => {
            hash = char.charCodeAt(0) + ((hash << 5) - hash);
            return null;
        });
        var color = '#';
        var arrKeys = [0, 1, 2];
        arrKeys.map((key, i) => {
            var value = (hash >> (i * 8)) & 0xff;
            color += ('00' + value.toString(16)).substr(-2);
            return null;
        });
        return color;
    };

    initializeErrorTrends = (type = PROCESS_ERRORS) => {
        this.trendType = type;
        this.localCharts = [];
        let months = [];
        let dbErrors = type === PROCESS_ERRORS ? this.props.errorTrends['dbErrors'] : this.props.errorTrends['nrErrors'];
        Object.keys(dbErrors || {}).map((dbMonth) => {
            let objMonth = { month: dbMonth, data: [...dbErrors[dbMonth]] };
            months.push(objMonth);
            return null;
        });
        months = months.sort((a, b) => {
            return new Date(b.month) - new Date(a.month);
        });

        months.map((month) => {
            month.data = month.data.sort((a, b) => parseInt(b.dbErrorCount) - parseInt(a.dbErrorCount));
            month.data = month.data.filter((item) => {
                return item.appName !== null;
            });
            return null;
        });
        this.processChart(months, type);
    };

    getFormattedXAxisLabels = (value, type) => {
        let formattedDate = DuDateUtilities.FormatDateFromIso(DuDateUtilities.ToIsoDate(new Date(value)));
        if (type === NEWRELIC_ERRORS) {
            return formattedDate;
        } else {
            let arrTemp = formattedDate.split('-');
            formattedDate = arrTemp[0] + "'" + arrTemp[2];
        }
        return formattedDate;
    };

    processChart = async (chartDate, type = PROCESS_ERRORS) => {
        let xAxisLabels = [];
        let dataSet = [];
        chartDate.map((item) => {
            xAxisLabels.push(this.getFormattedXAxisLabels(item.month, type));
            var dataSetObjectStack1 = { label: '', backgroundColor: '#6adc46', stack: '', data: [] };
            var dataSetObjectStack2 = { label: '', backgroundColor: '#ff8b87', stack: '', data: [] };
            item.data.map((dataItem) => {
                dataSetObjectStack1 = dataSet.find((objItem) => {
                    return objItem.label === dataItem.appName + ' Resolved' || objItem.label === dataItem.appName;
                });
                if (!dataSetObjectStack1) {
                    dataSetObjectStack1 = {
                        ...dataSetObjectStack1,
                        label: type === PROCESS_ERRORS ? dataItem.appName + ' Resolved' : dataItem.appName,
                        stack: dataItem.appName,
                        backgroundColor: type === PROCESS_ERRORS ? '#6adc46' : this.getColor(dataItem.appName),
                        data:
                            type === PROCESS_ERRORS
                                ? [dataItem.resolved]
                                : type === NEWRELIC_ERRORS
                                ? [dataItem.nrErrorCount]
                                : [dataItem.nrErrorCount + dataItem.dbErrorCount],
                        datalabels:
                            type === PROCESS_ERRORS
                                ? {
                                      display: false
                                  }
                                : this.state.showLabels
                                ? {
                                      formatter: function (value, context) {
                                          return dataItem.appName.toUpperCase();
                                      },
                                      clip: false,
                                      color: '#4a773c',
                                      clamp: true,
                                      anchor: 'top',
                                      align: 'top',
                                      font: {
                                          weight: 'bold',
                                          size: '10'
                                      },
                                      rotation: 270
                                  }
                                : { display: false }
                    };
                    if (type !== PROCESS_ERRORS) {
                        delete dataSetObjectStack1.stack;
                    }
                    dataSet.push(dataSetObjectStack1);
                } else {
                    dataSetObjectStack1.data.push(
                        type === PROCESS_ERRORS
                            ? dataItem.resolved
                            : type === NEWRELIC_ERRORS
                            ? dataItem.nrErrorCount
                            : dataItem.nrErrorCount + dataItem.dbErrorCount
                    );
                }

                dataSetObjectStack2 = dataSet.find((objItem) => {
                    return objItem.label === dataItem.appName + ' unResolved';
                });
                if (type === PROCESS_ERRORS) {
                    if (!dataSetObjectStack2) {
                        dataSetObjectStack2 = {
                            ...dataSetObjectStack2,
                            label: dataItem.appName + ' unResolved',
                            stack: dataItem.appName,
                            backgroundColor: '#ff8b87',
                            data: [dataItem.unResolved],
                            datalabels: this.state.showLabels
                                ? {
                                      formatter: function (value, context) {
                                          return dataItem.appName.toUpperCase();
                                      },
                                      clip: false,
                                      color: '#4a773c',
                                      clamp: true,
                                      anchor: 'top',
                                      align: 'top',
                                      font: {
                                          weight: 'bold',
                                          size: '10'
                                      },
                                      rotation: 270
                                  }
                                : { display: false }
                        };
                        dataSet.push(dataSetObjectStack2);
                    } else {
                        dataSetObjectStack2.data.push(dataItem.unResolved);
                    }
                }

                return null;
            });
            return null;
        });
        let barChartData = { labels: xAxisLabels, datasets: dataSet };
        this.setState({ barChartData });
    };

    handleSelectChange = (data) => {
        this.setState({ source: data });
        this.initializeErrorTrends(data.value);
    };

    handleApplicationSelect = (data) => {
        let apps = [...this.state.selectedApplications, data];
        this.setState({ selectedApplications: apps, selectedApplication: data, showFilter: false });
        this.props.refreshData(apps);
    };

    handleSwitchChange = async (event, checked, key) => {
        if (key === 'showLegend') {
            this.setState({ showLegend: checked }, () => {
                this.options.legend.display = checked;
                this.initializeErrorTrends(this.trendType);
            });
        } else if (key === 'showlabel') {
            await this.setState({ showLegend: false }, () => {
                this.options.legend.display = false;
                this.setState({ showLabels: checked, disableLegend: !checked }, () => {
                    this.initializeErrorTrends(this.trendType);
                });
            });
        }
    };

    handleDeleteChips = (chip) => {
        let apps = this.state.selectedApplications.filter((item) => item.label !== chip.label);
        this.setState({ selectedApplications: apps });
        this.props.refreshData(apps);
    };

    makeChips = (selectedFilters) => {
        let chips = [];
        let key = 1;
        selectedFilters.map((filter) => {
            chips.push({
                key,
                fieldName: filter.fieldName,
                value: filter.value,
                label: filter.label
            });
            return null;
        });
        return chips;
    };

    getFilteredApps = (selectedApplications) => {
        let array = this.props.applicationList.filter(
            (item) => selectedApplications.find((appItem) => appItem.label.toLowercase() === item.label.toLowercase()) === undefined
        );
        return array;
    };

    onAddBtnClick = () => {
        this.setState({ showFilter: true });
    };

    render() {
        const { classes } = this.props;
        const { sourceOptions, source, showLegend, selectedApplications, barChartData, showFilter, showLabels, disableLegend } = this.state;
        return (
            <div className={classes.errorsByApp}>
                <DrcPanel className={classes.section}>
                    <IntegrationErrorHeader
                        errorSourceId="trendingErrors"
                        translationId={source.value === PROCESS_ERRORS ? 'pastFourMonths' : 'pastFourWeeks'}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <DrcSelect
                            label={<Translate id="Source" />}
                            onChange={this.handleSelectChange}
                            value={source}
                            options={sourceOptions ? sourceOptions : ''}
                            fullWidth={true}
                            className={classes.includeSelect}
                        />
                        <DrcSwitch
                            checked={showLabels}
                            value={showLabels}
                            onChange={(event, checked) => this.handleSwitchChange(event, checked, 'showlabel')}
                            className={classes.switchStyle}
                        >
                            {<Translate id="SHowLabels" />}
                        </DrcSwitch>
                        <DrcSwitch
                            disabled={disableLegend}
                            checked={showLegend}
                            value={showLegend}
                            onChange={(event, checked) => this.handleSwitchChange(event, checked, 'showLegend')}
                            className={classes.switchStyle}
                        >
                            {<Translate id="ShowLegend" />}
                        </DrcSwitch>
                        {selectedApplications.length ? (
                            <div className={classes.container}>
                                <DrcChips chips={this.makeChips(selectedApplications)} onDelete={this.handleDeleteChips} />
                                {!showFilter ? (
                                    <DrcButton
                                        isSecondary
                                        onClick={this.onAddBtnClick}
                                        style={{
                                            borderRadius: '18px',
                                            height: '28px',
                                            padding: '6px',
                                            margin: '5px 8px 5px 0'
                                        }}
                                    >
                                        <Translate id="AddApp" />
                                        <AddIcon />
                                    </DrcButton>
                                ) : (
                                    <DrcSelect
                                        label={<Translate id="Application" />}
                                        onChange={this.handleApplicationSelect}
                                        value={''}
                                        options={this.props.applicationList.filter(
                                            (item) =>
                                                selectedApplications.find((appItem) => appItem.label.toLowerCase() === item.label.toLowerCase()) ===
                                                undefined
                                        )}
                                        fullWidth={true}
                                        placeholder="Select Application"
                                        className={classes.includeSelect}
                                    ></DrcSelect>
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className={classes.integrationsErrorContainer}>
                        <Bar data={barChartData} options={this.options} />
                    </div>
                </DrcPanel>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess))
});

const mapStateToProps = (state) => {
    return {
        env: state.rootReducer.env
    };
};

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ErrorTrends)));
