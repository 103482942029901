import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import GridStyles from '../../styles/gridStyles';
import { DrcDataGrid } from 'driscolls-react-components';
import GridNoRecords from '../GridNoRecords';
import { connect } from 'react-redux';
import { colorBgTemplate, getRowCount } from '../../utils/helper';
import DisplayLink from '../TableCellFormatters/DisplayLink';
import { Translate } from 'react-localize-redux';

const styles = (theme) => ({
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 380px)')
});

class ApplicationsList extends Component {
    state = {
        columns: []
    };

    uniqueKeyField = 'appName';

    componentDidMount() {
        this.setState({
            columns: this.makeColumns(this.props.records[0] ? this.props.records[0] : [])
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.records && !prevProps.records.length && this.props.records && this.props.records.length > 0) {
            this.setState({
                columns: this.makeColumns(this.props.records[0])
            });
        }
    }

    linkTemplate = (row) => <DisplayLink linkText={row.appName} link={`/ApiListing/${row.appName}`} />;

    getColumnTemplate = (key) => {
        if (key.toLowerCase() === 'appname') {
            return this.linkTemplate;
        } else {
            return colorBgTemplate(key);
        }
    };

    makeColumns = (data) => {
        let excludingFields = ['appVersion'];
        let columns = [];
        columns = Object.keys(data)
            .map((key) => {
                if (!excludingFields.includes(key)) {
                    return {
                        key,
                        name: <Translate id={key} />,
                        columnTemplate: this.getColumnTemplate(key)
                    };
                }
                return null;
            })
            .filter((e) => e);
        return columns;
    };

    render() {
        const { first, onPage, pageSize, classes } = this.props;
        return (
            <div>
                {this.props.records?.length ? (
                    <DrcDataGrid
                        paginator={true}
                        currentPage={first}
                        onPage={onPage}
                        uniqueKeyField={this.uniqueKeyField}
                        columns={this.state.columns}
                        rows={this.props.records}
                        gridStyles={classes.gridStyles}
                        totalRecords={this.props.totalRecords}
                        resultCount={this.props.totalRecords}
                        pageSize={pageSize}
                        rowsToLoadPerScroll={getRowCount(this.props.totalRecords, this.state.pageSize)}
                        lazy={true}
                        virtualRowHeight={48}
                        showReport={true}
                    />
                ) : (
                    <GridNoRecords />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        records: state.applicationListingReducer.applicationsData,
        totalRecords: state.applicationListingReducer.totalRecords
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(ApplicationsList));
