import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DrcButton, DrcSelect, DrcInput } from 'driscolls-react-components';
import { Translate } from 'react-localize-redux';
import Switch from '../Switch';
import ApplicationSelectionDropDown from '../ApplicationSelectionDropDown';
import DateRangePicker from '../DateRangePicker';
import { checkIfDateIsNotInRange } from '../../utils/helper';
import { InputAdornment } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { setViewLogsFilters } from '../../actions/ViewLogsActions';

const styles = () => ({
    filterContainer: {
        marginBottom: '8px'
    },
    filterItems: {
        display: 'flex',
        flexFlow: 'row wrap'
    },
    drcInputCustom: {
        width: '175px',
        marginRight: '8px'
    },
    searchBtn: {
        alignSelf: 'baseline'
    },
    searchIcon: {
        color: 'lightgrey'
    },
    includeSelect: {
        width: '100px',
        marginRight: '2px'
    }
});

class ViewLogsFilter extends Component {
    state = {
        application: this.props.appName ? { label: this.props.appName, value: this.props.appName } : {},
        reProcessable: '',
        startDate: this.props.appName && this.props.processName && this.props.filters.startDate ? this.props.filters.startDate : new Date(),
        endDate: this.props.appName && this.props.processName && this.props.filters.endDate ? this.props.filters.endDate : new Date(),
        transactionId: '',
        endPoint: '',
        source: { label: 'Process Errors', value: 'DB' },
        newRelicSource: { label: 'New Relic Errors', value: 'NR' },
        sourceList: [
            { label: 'Process Errors', value: 'DB' },
            { label: 'New Relic Errors', value: 'NR' }
        ],
        searchSelect: [
            { label: 'Like', value: 'true' },
            { label: 'Not Like', value: 'false' }
        ],
        processName: this.props.processName
            ? { label: this.props.processName, value: this.props.processName }
            : this.props.appName
            ? { label: 'All', value: '' }
            : {},
        searchString: '',
        searchLike: { label: 'Like', value: 'true' }
    };

    transactionIdExpression = new RegExp(/^[a-zA-Z0-9-]+$/);
    endPointExpression = new RegExp(/^[a-zA-Z0-9/?:.=&]+$/);
    /* eslint-disable no-useless-escape */
    searchStringExpression = new RegExp(/^[a-zA-Z0-9\s\/\\._&-]+$/);
    checkSource = '';

    selects = [
        {
            id: 'api',
            name: 'processName',
            onChange: (data) => this.props.handleSelectChange(data.value, 'processName')
        }
    ];
    errorSourceSelects = [
        {
            id: 'Source',
            name: 'source',
            onChange: (data) => this.handleSelectChange(data),
            options: this.state.sourceList
        }
    ];
    searchInclude = [
        {
            id: 'Include',
            name: 'searchLike',
            onChange: (data) => this.handleIncludeChange(data),
            options: this.state.searchSelect
        }
    ];

    getSourceValue = (selectedSourceInput) =>
        selectedSourceInput === 'nrError' || selectedSourceInput === 'newRelicSource' ? 'newRelicSource' : 'source';

    componentDidUpdate(prevProps) {
        this.checkSource = this.checkSource === '' ? this.getSourceValue(this.props.errorSource) : this.getSourceValue(this.checkSource);
        if (
            prevProps.appName !== this.props.appName ||
            prevProps.processName !== this.props.processName ||
            prevProps.errorSource !== this.props.errorSource
        ) {
            this.setState({
                source: this.checkSource === 'newRelicSource' ? { label: 'New Relic Errors', value: 'NR' } : { label: 'Process Errors', value: 'DB' },
                application: this.props.appName ? { label: this.props.appName, value: this.props.appName } : {},
                processName: this.props.processName
                    ? { label: this.props.processName, value: this.props.processName }
                    : this.props.appName
                    ? { label: 'All', value: '' }
                    : {}
            });
        }
    }

    handleSwitchChange = (label) => {
        this.setState({ reProcessable: label });
    };

    handleDateChange = (startDate, endDate) => {
        this.setState({ startDate: startDate, endDate: endDate });
    };

    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    Search = () => {
        var data = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            application: this.state.application.value,
            apiName: this.state.processName.value,
            transactionId: this.state.transactionId,
            reProcessable: this.state.reProcessable,
            source: this.state.source.value,
            endPoint: encodeURIComponent(this.state.endPoint),
            searchString: encodeURIComponent(this.state.searchString),
            searchLike: this.state.searchLike.value
        };
        this.props.setViewLogsFilters(data);
        this.props.handleSearch(data);
    };

    handleSelectChange = (data) => {
        this.checkSource = data.value === 'DB' ? 'source' : 'nrError';
        this.setState({ source: data, endPoint: '', searchString: '', reProcessable: '' });
        this.props.changeSource(this.checkSource);
    };

    handleIncludeChange = (data) => {
        this.setState({ searchLike: data });
    };

    checkIfSearchButtonIsDisabled = () => {
        return (
            this.props.canSearch ||
            checkIfDateIsNotInRange(this.state.startDate, this.state.endDate, 2) ||
            this.checkIfCorelationIdIsInvalid() ||
            this.checkIfEndPointIsInvalid() ||
            this.checkIfSearchStringIsInvalid()
        );
    };

    checkIfCorelationIdIsInvalid = () => {
        return this.state.transactionId ? !this.transactionIdExpression.test(this.state.transactionId) : false;
    };

    checkIfEndPointIsInvalid = () => {
        return this.state.endPoint ? !this.endPointExpression.test(this.state.endPoint) : false;
    };

    checkIfSearchStringIsInvalid = () => {
        return this.state.searchString ? !this.searchStringExpression.test(this.state.searchString) : this.state.searchString;
    };

    render() {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.filterContainer}>
                    <div className={classes.filterItems}>
                        <DrcInput
                            className={classes.drcInputCustom}
                            name="transactionId"
                            placeholder="Transaction Id"
                            label={<Translate id="transactionId" />}
                            onChange={(e) => this.handleInputChange(e)}
                            value={this.state.transactionId}
                            helperText={this.checkIfCorelationIdIsInvalid() ? <Translate id="corelationIdValidationMsg" /> : ''}
                        />
                        {this.state.source.value === 'DB' ? (
                            <DrcInput
                                className={classes.drcInputCustom}
                                name="endPoint"
                                placeholder="End Point"
                                label={<Translate id="endPoint" />}
                                onChange={(e) => this.handleInputChange(e)}
                                value={this.state.endPoint}
                                helperText={this.checkIfEndPointIsInvalid() ? <Translate id="endPointValidationMsg" /> : ''}
                            />
                        ) : (
                            <>
                                {this.searchInclude.map((include, index) => (
                                    <DrcSelect
                                        key={index}
                                        label={<Translate id={include.id} />}
                                        name={include.name}
                                        onChange={include.onChange}
                                        value={this.state[include.name]}
                                        options={include.options ? include.options : ''}
                                        fullWidth={true}
                                        className={classes.includeSelect}
                                    />
                                ))}
                                <DrcInput
                                    className={classes.drcInputCustom}
                                    name="searchString"
                                    placeholder="Search..."
                                    onChange={(e) => this.handleInputChange(e)}
                                    value={this.state.searchString}
                                    helperText={this.checkIfSearchStringIsInvalid() ? <Translate id="searchStringValidationMsg" /> : ''}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchOutlined className={classes.searchIcon} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </>
                        )}
                        <ApplicationSelectionDropDown
                            value={this.state.application}
                            onChange={(data) => this.props.handleSelectChange(data.value, 'application')}
                            required={true}
                        />
                        {this.selects.map((select, index) => (
                            <DrcSelect
                                key={index}
                                label={<Translate id={select.id} />}
                                required
                                name={select.name}
                                onChange={select.onChange}
                                value={this.state[select.name]}
                                options={select.options ? select.options : this.props.processNameList}
                                fullWidth={true}
                                className={classes.drcInputCustom}
                            />
                        ))}
                        {this.errorSourceSelects.map((source, index) => (
                            <DrcSelect
                                key={index}
                                label={<Translate id={source.id} />}
                                required
                                name={this.checkSource}
                                onChange={source.onChange}
                                value={this.state[this.checkSource]}
                                options={source.options ? source.options : ''}
                                fullWidth={true}
                                className={classes.drcInputCustom}
                            />
                        ))}
                        {this.state.source.value === 'DB' && (
                            <Switch label={<Translate id="reprocessable" />} value={this.state.reProcessable} onChange={this.handleSwitchChange} />
                        )}
                        <DateRangePicker
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={(startDate, endDate) => this.handleDateChange(startDate, endDate)}
                        />
                    </div>
                </div>
                <DrcButton isPrimary onClick={this.Search} disabled={this.checkIfSearchButtonIsDisabled()} className={classes.searchBtn}>
                    {<Translate id="search" />}
                </DrcButton>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        applicationList: state.Options.applications,
        filters: state.viewLogsReducer.filters
    };
};

const mapDispatchToProps = (dispatch) => ({
    setViewLogsFilters: (data) => dispatch(setViewLogsFilters(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewLogsFilter));
