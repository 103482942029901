import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { DrcMain } from 'driscolls-react-components';
import { Middleware } from 'one-ring';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setErrorsAction, setLogoutMsg, setPageTitleAction } from '../actions/actions';
import APIEndPoints from '../services/api';
import { setApplicationList } from '../actions/OptionsActions';
import { getAccessToken, getEndIsoDate, getLoadingMessage, getPreviousDate, getStartIsoDate, ReInitializeMasterOptions } from '../utils/helper';
import IntegrationErrors from '../components/Dashboard/IntegrationErrors';
import { setApiListingFilters } from '../actions/ApiListingActions';
import { Translate } from 'react-localize-redux';
import IsMasterDataInitialized from '../components/IsMasterDataInitialized';
import ErrorTrends from '../components/Dashboard/ErrorTrends';

const PAGE_TITLE = 'Dashboard';

const styles = () => ({
    main: {
        maxWidth: '96vw !important',
        padding: '8px 24px',
        borderRadius: '4px',
        marginTop: '65px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent'
    }
});

class Dashboard extends Component {
    state = {
        applicationList: [],
        errorTrends: [],
        defaultTrendingApps: []
    };

    getApplicationData = async () => {
        let token = await getAccessToken(this.props);
        var applicationData = [];
        if (token) {
            let startDate = getStartIsoDate(getPreviousDate(2));
            let endDate = getEndIsoDate(getPreviousDate(0));
            applicationData = await Middleware.Send(
                'ApplicationList',
                token,
                APIEndPoints.APPLICATION_LEVEL_DETAILS(this.props.env, 1, 50, startDate, endDate),
                'GET',
                '',
                getLoadingMessage('InitializingDashboard')
            );
            applicationData = applicationData.applicationListing
                ? applicationData.applicationListing.map((app) => {
                      return {
                          label: app.appName,
                          errorCount: app.dbErrorCount ? app.dbErrorCount : 0,
                          newRelicErrorCount: app.nrErrorCount ? app.nrErrorCount : 0
                      };
                  })
                : [];
        }
        return applicationData;
    };

    getErrorTrends = async (apps = []) => {
        let token = await getAccessToken(this.props);
        let payload = apps.map((item) => {
            return item.label;
        });
        let response = await Middleware.Send(
            'ErrorTrends',
            token,
            APIEndPoints.GET_ERROR_TRENDS(this.props.env),
            'POST',
            { appNames: payload },
            getLoadingMessage('LoadingErrorTrends')
        );
        return response;
    };

    initializeDashboard = async (apps) => {
        var applicationData = [];
        var errorTrends = [];
        let objMonth;
        try {
            applicationData = await this.getApplicationData();
            errorTrends = await this.getErrorTrends(apps);
            let dbErrors = errorTrends['dbErrors'];
            Object.keys(dbErrors || {}).map((dbMonth) => {
                objMonth = dbErrors[dbMonth];
                return null;
            });
        } catch (err) {
            this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileFetchingApplicationData" />, err);
        }
        objMonth = objMonth && objMonth.map(app=>{return {...app,label:app.appName}});
        this.setState({ applicationList: applicationData, errorTrends: errorTrends, defaultTrendingApps: objMonth });
    };

    componentDidMount() {
        if (this.props.pageTitle !== PAGE_TITLE) {
            this.props.setPageTitle(PAGE_TITLE);
        }
        this.initializeDashboard();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.env !== this.props.env) {
            this.initializeDashboard();
            ReInitializeMasterOptions(this.props.setApplicationList);
        }
    }

    setApiListingFiltersData = () => {
        this.props.setApiListingFilters('', getPreviousDate(2), getPreviousDate(0));
    };

    goToAppDetails = (appName) => {
        this.setApiListingFiltersData();
        this.props.history.push(`/ApiListing/${appName}`);
    };

    refreshData = (apps) => {
        this.initializeDashboard(apps);
    };

    render() {
        const { classes } = this.props;
        return (
            <IsMasterDataInitialized>
                <DrcMain className={classes.main}>
                    <IntegrationErrors
                        applicationList={this.state.applicationList}
                        goToAppDetails={this.goToAppDetails}
                        refreshData={this.refreshData}
                    />
                    <ErrorTrends
                        errorTrends={this.state.errorTrends}
                        applicationList={this.state.applicationList}
                        defaultTrendingApps={this.state.defaultTrendingApps}
                        refreshData={this.refreshData}
                    />
                </DrcMain>
            </IsMasterDataInitialized>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        env: state.rootReducer.env
    };
};

const mapDispatchToProps = (dispatch) => ({
    setErrorsAction: (title, error, errorstack) => dispatch(setErrorsAction(title, error, errorstack)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setApplicationList: (list) => dispatch(setApplicationList(list)),
    setLogoutMsg: (logoutMessage) => dispatch(setLogoutMsg(logoutMessage)),
    setApiListingFilters: (apiName, startDate, endDate) => dispatch(setApiListingFilters(apiName, startDate, endDate))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard)));
