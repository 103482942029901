export const SET_ERROR_LIBRARY_RECORDS = 'SET_ERROR_LIBRARY_RECORDS';
export const setErrorLibraryRecords = (data, totalRecords) => ({
    type: SET_ERROR_LIBRARY_RECORDS,
    payload: { data, totalRecords }
});

export const SET_ERROR_LIBRARY_FILTERS = 'SET_ERROR_LIBRARY_FILTERS';
export const setErrorLibraryFilters = (data) => ({
    type: SET_ERROR_LIBRARY_FILTERS,
    payload: { data }
});
