import { SET_ERROR_LIBRARY_RECORDS, SET_ERROR_LIBRARY_FILTERS } from '../actions/ErrorLibraryActions';

const initialState = {
    errorLibraryData: [],
    totalRecords: 0,
    filters: {
        rootCause: '',
        errorCode: '',
        errorMessage: '',
        applicationName: null,
        apiName: null
    }
};

const ErrorLibraryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ERROR_LIBRARY_RECORDS:
            return Object.assign({}, state, {
                errorLibraryData: action.payload.data,
                totalRecords: action.payload.totalRecords
            });
        case SET_ERROR_LIBRARY_FILTERS:
            return Object.assign({}, state, {
                filters: action.payload.data
            });
        default:
            return state;
    }
};

export default ErrorLibraryReducer;
