import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { getCurrentEnvironment } from '../utils/helper';

const styles = (theme) => ({
    wrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: 0,
        borderBottom: '48px solid transparent',
        borderLeft: '48px solid ' + theme.light.error,
        zIndex: 1200,
        pointerEvents: 'none'
    },
    title: {
        position: 'fixed',
        color: theme.light.text.inverted,
        fontSize: '1.2rem',
        transform: 'rotate(-45deg)',
        textAlign: 'center',
        top: 6,
        left: -6,
        width: 48
    }
});

class EnvironmentMarker extends React.Component {
    getEnvDetails = () => {
        switch (this.props.env) {
            case 'dev':
                return 'Dev';
            case 'tst':
                return 'Test';
            case 'qa':
                return 'QA';
            case 'stg':
                return 'Stage';
            case 'uat':
                return 'UAT';
            default:
                return 'Dev';
        }
    };

    currentEnv = getCurrentEnvironment(window.subdomain === 'localhost' ? 'dev' : window.subdomain);

    render() {
        const { classes } = this.props;

        return (
            <>
                {this.currentEnv !== 'prd' ? (
                    <div className={classes.wrapper}>
                        <span className={classes.title}>{this.getEnvDetails()}</span>
                    </div>
                ) : null}
            </>
        );
    }
}

export default withStyles(styles)(EnvironmentMarker);
