import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DrcMain, DrcPanel } from 'driscolls-react-components';

const styles = () => ({
    main: {
        maxHeight: 'calc(100vh - 100px)',
        maxWidth: '96vw !important',
        padding: '8px 24px',
        borderRadius: '4px'
    },
    panel: {
        maxWidth: 'calc(100vw - 40px) !important',
        border: 'none',
        padding: 0,
        margin: 0,
        maxHeight: 'calc(100vh - 145px) !important',
        height: '100vh'
    }
});

class MainPanel extends Component {
    render() {
        const { classes } = this.props;
        return (
            <DrcMain className={classes.main}>
                <DrcPanel className={classes.panel}>{this.props.children}</DrcPanel>
            </DrcMain>
        );
    }
}

export default withStyles(styles)(MainPanel);
