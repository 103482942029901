import React from 'react';
import Dashboard from '@material-ui/icons/Dashboard';
import Apps from '@material-ui/icons/Apps';
import List from '@material-ui/icons/List';
import Details from '@material-ui/icons/Details';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import NewReleases from '@material-ui/icons/NewReleases';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import { Translate } from 'react-localize-redux';

const LINKS = [
    { title: <Translate id="home" />, url: '/Dashboard/', icon: <Dashboard />, showInMenuBar: true },
    { title: <Translate id="applicationListing" />, url: '/ApplicationList/', icon: <Apps />, showInMenuBar: true },
    { title: <Translate id="apiListing" />, url: '/ApiListing/', icon: <List />, showInMenuBar: true },
    { title: <Translate id="viewLogs" />, url: '/ViewLogs/', icon: <Details />, showInMenuBar: true },
    { title: <Translate id="errorLibrary" />, url: '/ErrorLibrary/', icon: <LibraryBooks />, showInMenuBar: true },
    { title: <Translate id="releases" />, url: '/Releases/', icon: <NewReleases />, showInMenuBar: true },
    { title: <Translate id="logout" />, url: '/LogOut/', icon: <DirectionsRun /> }
];

export default LINKS;
