import { Middleware } from 'one-ring';
const getApplicationNamesListMapping = [
    Middleware.CreateMapping('appName', 'appName'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('AppNames', 'GET')) {
        Middleware.RegisterMapping('AppNames', 'GET', getApplicationNamesListMapping);
    }
}

const ApplicationNamesListMapping = {
    ConfigureMiddleware
};

export default ApplicationNamesListMapping;
