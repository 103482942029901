import React, { Component } from 'react';
import { setPageTitleAction, showToast, setErrorsAction, setLogoutMsg } from '../actions/actions';
import { connect } from 'react-redux';
import { Middleware } from 'one-ring';
import { withOktaAuth } from '@okta/okta-react';
import APIEndpoints from '../services/api';
import { Translate } from 'react-localize-redux';
import { DuExcelUtilities } from 'driscolls-react-utilities';
import startCase from 'lodash/startCase';
import AddUpdateSolution from '../components/AddUpdateSolution';
import { setErrorLibraryRecords } from '../actions/ErrorLibraryActions';
import {
    formatRecords,
    getAccessToken,
    getCurrentLoggedInUserName,
    getIsoDate,
    getLoadingMessage,
    getPrettyDateTime,
    makeOptions
} from '../utils/helper';
import HeaderTitle from '../components/HeaderTitle';
import ErrorLibraryList from '../components/ErrorLibrary/List';
import ErrorLibraryFilterAndBackButtonWrapper from '../components/ErrorLibrary/FilterAndBackButtonWrapper';
import ConfirmationDialog from '../components/ConfirmationDialog';
import IsMasterDataInitialized from '../components/IsMasterDataInitialized';
import MainPanel from '../components/MainPanel';

const PAGE_TITLE = 'Error Library';
class ErrorLibrary extends Component {
    state = {
        openConfirmationModal: false,
        title: '',
        showSubmit: true,
        openSolutionModal: false,
        updateMode: false,
        first: 0,
        selectedRow: null,
        isViewOnly: false,
        apiNameList: [],
        selectedIndexes: [],
        pageSize: 50,
        enableExportAll: false
    };

    getErrorLibraryDetails = async (
        currentPage,
        errorCode,
        errorMessage,
        rootCause,
        apiName,
        pageSize,
        appName,
        loadingMessage = 'LoadingErrorLibrary'
    ) => {
        let token = await getAccessToken(this.props);
        var data = [];
        if (token) {
            var filter = {
                appErrorCode: errorCode,
                errorMessage: errorMessage,
                rootCause: rootCause,
                apiName: apiName ? apiName : '',
                appName: appName ? appName : ''
            };
            data = await Middleware.Send(
                'ErrorLibraryList',
                token,
                APIEndpoints.ERROR_LIBRARY_DETAILS(this.props.env, currentPage, pageSize, 0),
                'POST',
                filter,
                getLoadingMessage(loadingMessage)
            );
            data.results = data?.results?.map((val) => {
                return {
                    ...val,
                    updatedDate: val.updatedDate ? getPrettyDateTime(val.updatedDate) : val.updatedDate,
                    createdDate: val.createdDate ? getPrettyDateTime(val.createdDate) : val.createdDate
                };
            });
        }
        return data;
    };

    getApiListData = async (appName) => {
        let token = await getAccessToken(this.props);
        var data = [];
        if (token) {
            data = await Middleware.Send(
                'ApiNames',
                token,
                APIEndpoints.API_NAMES(this.props.env, appName),
                'GET',
                '',
                getLoadingMessage('loadingApiNames')
            );
        }
        this.setState({ apiNameList: [{ label: 'All', value: '' }].concat(makeOptions(data ? data : [], 'apiName', 'apiName')) });
    };

    initializeErrorLibraryScreen = async (currentPage = this.state.first / this.state.pageSize + 1) => {
        try {
            let data = await this.getErrorLibraryDetails(
                currentPage,
                this.props.filters.errorCode,
                this.props.filters.errorMessage,
                this.props.filters.rootCause,
                this.props.filters.apiName,
                this.state.pageSize,
                this.props.filters.applicationName
            );
            this.props.setErrorLibraryData(data.results ? data.results : [], data.totalRecords ? data.totalRecords : 0);
        } catch (err) {
            this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileFetchingErrorLibraryRecords" />, err);
        }
        if (this.props.filters.applicationName) {
            this.getApiListData(this.props.filters.applicationName);
        }
    };

    componentDidMount() {
        if (this.props.pageTitle !== PAGE_TITLE) {
            this.props.setPageTitle(PAGE_TITLE);
        }
        this.initializeErrorLibraryScreen();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.env !== this.props.env) {
            this.initializeErrorLibraryScreen();
        }
    }

    closeErrorDialog = () => {
        this.setState({
            openConfirmationModal: false,
            selectedRow: null,
            openSolutionModal: false,
            title: '',
            updateMode: false,
            isViewOnly: false,
            enableExportAll: false
        });
    };

    startViewSolution = (row) => {
        this.setState({ selectedRow: row, openSolutionModal: true, title: <Translate id="ErrorResolution" />, isViewOnly: true });
    };

    startUpdate = (row) => {
        this.setState({ selectedRow: row, openSolutionModal: true, title: <Translate id="UpdateErrorResolution" />, updateMode: true });
    };

    onPage = async (event) => {
        this.initializeErrorLibraryScreen(event.first / this.state.pageSize + 1);
        this.setState({ first: event.first });
    };

    onRowsSelected = (e) => this.setState({ selectedIndexes: e.value });

    formatAndExport = (fileName, cols, data) => {
        data = data.map((val) => {
            return { ...val, errorDescription: val.errorMessage, errorCode: val.appErrorCode };
        }); //doing this until column name is not corrected from backend
        DuExcelUtilities.Write(fileName, cols, data);
    };

    downloadData = async () => {
        var data = [...this.state.selectedIndexes];
        if (data.length && data.length !== this.state.pageSize && data.length !== this.props.totalRecords) {
            var cols = this.getCols();
            data = formatRecords(data, 'errorMessage');
            this.formatAndExport('selectedLibraries.xlsx', cols, data);
            this.setState({ selectedIndexes: [] });
        } else {
            this.startExportAll();
        }
    };

    startExportAll = () => {
        this.setState({
            openConfirmationModal: true,
            title: <Translate id="ExportAllRecords" />,
            showSubmit: true,
            solutionDescription: `You Are About To Export ${this.props.totalRecords} Records, This Action Might Take Some Time, Do you Want To Continue?`,
            enableExportAll: true
        });
    };

    startDelete = (row) => {
        this.setState({
            openConfirmationModal: true,
            title: <Translate id="deleteErrorResolution" />,
            showSubmit: true,
            solutionDescription: <Translate id="deleteMsg" />,
            selectedRow: row
        });
    };

    handleDelete = async () => {
        let token = await getAccessToken(this.props);
        if (token) {
            try {
                await Middleware.Send(
                    'ErrorLibrary',
                    token,
                    APIEndpoints.DELETE_ERROR_LIBRARY(this.props.env, this.state.selectedRow.Id),
                    'DELETE',
                    '',
                    getLoadingMessage('DeletingErrorLibraryRecord')
                );
                this.props.showToast(<Translate id="DeleteErrorLibSuccessful" />, true);
                this.initializeErrorLibraryScreen();
            } catch (err) {
                this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileDeletingErrorLibraryRecord" />, err);
            }
        }
        this.closeErrorDialog();
    };

    getCols = () => {
        var excludingKeys = ['method', 'createdBy', 'createdDate', 'updatedBy', 'updatedDate'];
        var keys = Object.keys(this.props.records[0]);
        keys[keys.indexOf('appErrorCode')] = 'errorCode'; //doing this until column name is not corrected from backend.
        keys[keys.indexOf('errorMessage')] = 'errorDescription'; //doing this until column name is not corrected from backend.
        keys = keys.filter((val) => !excludingKeys.includes(val)); //doing this until method column is not removed from backend.
        return keys
            .sort()
            .concat(excludingKeys.slice(1, excludingKeys.length + 1))
            .map((key) => {
                return { name: startCase(key), key: key };
            });
    };

    handleExportAll = async () => {
        try {
            var cols = this.getCols();
            var records = await this.getErrorLibraryDetails(
                1,
                this.props.filters.errorCode,
                this.props.filters.errorMessage,
                this.props.filters.rootCause,
                this.props.filters.apiName,
                this.props.totalRecords, //Will change this once backend gives us new api doing this to test the performance,,
                this.props.filters.applicationName,
                'ExportingAllErrorLibraryRecords'
            );
            records = formatRecords(records.results, 'errorMessage');
            this.formatAndExport('AllLibraries.xlsx', cols, records);
            this.setState({ selectedIndexes: [] });
        } catch (err) {
            this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileDeletingErrorLibraryRecord" />, err);
        }
        this.closeErrorDialog();
    };

    handleAgree = () => {
        if (this.state.enableExportAll) {
            this.handleExportAll();
        } else {
            this.handleDelete();
        }
    };

    startAddErrorSolution = () => {
        this.setState({
            openSolutionModal: true,
            title: <Translate id="NewErrorResolution" />
        });
    };

    handleAddUpdateErrorResolution = async (data) => {
        let token = await getAccessToken(this.props);
        if (token) {
            let date = getIsoDate(new Date(), true);
            let userId = getCurrentLoggedInUserName(token);
            if (!this.state.selectedRow) {
                data.createdDate = date;
                data.createdBy = userId;
            } else {
                data.Id = this.state.selectedRow.Id;
            }
            data.updatedDate = date;
            data.updatedBy = userId;
            try {
                await Middleware.Send(
                    'ErrorLibrary',
                    token,
                    APIEndpoints.ADD_UPDATE_ERROR_LIBRARY(this.props.env),
                    !this.state.selectedRow ? 'POST' : 'PATCH',
                    data,
                    getLoadingMessage(!this.state.selectedRow ? 'AddingNewRecord' : 'UpdatingErrorLibraryRecord')
                );
                this.props.showToast(<Translate id={!this.state.selectedRow ? 'AddErrorLibSuccessful' : 'UpdateErrorLibSuccessful'} />, true);
                this.initializeErrorLibraryScreen();
            } catch (err) {
                this.props.setErrorsAction(
                    <Translate id="APIError" />,
                    <Translate
                        id={!this.state.selectedRow ? 'ErrorOccurredWhileAddingErrorLibraryRecord' : 'ErrorOccurredWhileUpdatingErrorLibraryRecords'}
                    />,
                    err
                );
            }
        }
        this.closeErrorDialog();
    };

    onSearchButtonClick = async (filterValues) => {
        let data = await this.getErrorLibraryDetails(
            1,
            filterValues.errorCode,
            filterValues.errorMessage,
            filterValues.rootCause,
            filterValues.apiName,
            this.state.pageSize,
            filterValues.applicationName
        );
        this.props.setErrorLibraryData(data.results ? data.results : [], data.totalRecords ? data.totalRecords : 0);
        this.setState({
            first: 0,
            selectedIndexes: [],
            apiNameList: !filterValues.applicationName && !filterValues.apiName ? [] : this.state.apiNameList
        });
    };

    loadOptions = async (apiName) => {
        let token = await getAccessToken(this.props);
        var data = [];
        if (token) {
            try {
                data = await Middleware.Send('ApiNamesSearch', token, APIEndpoints.API_NAMES_SEARCH(this.props.env, apiName), 'GET', '');
                if (data) {
                    data = makeOptions(data, 'apiName', 'apiName');
                }
            } catch (err) {
                this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileFetchingApiNames" />, err);
            }
        }
        return data;
    };

    render() {
        return (
            <IsMasterDataInitialized>
                <MainPanel>
                    <HeaderTitle id="ErrorLibrary" />
                    <ErrorLibraryFilterAndBackButtonWrapper
                        handleSearch={this.onSearchButtonClick}
                        getProcessListData={this.getApiListData}
                        apiNameList={this.state.apiNameList}
                        downloadData={this.downloadData}
                        startAddErrorSolution={this.startAddErrorSolution}
                        records={this.props.records}
                    />
                    <ErrorLibraryList
                        startViewSolution={this.startViewSolution}
                        startUpdate={this.startUpdate}
                        startDelete={this.startDelete}
                        first={this.state.first}
                        pageSize={this.state.pageSize}
                        selectedIndexes={this.state.selectedIndexes}
                        onRowsSelected={this.onRowsSelected}
                        onPage={this.onPage}
                    />
                </MainPanel>
                <ConfirmationDialog
                    open={this.state.openConfirmationModal}
                    title={this.state.title}
                    confirm={this.handleAgree}
                    cancel={this.closeErrorDialog}
                    content={this.state.solutionDescription}
                />
                <AddUpdateSolution
                    open={this.state.openSolutionModal}
                    title={this.state.title}
                    saveOrUpdateSolution={this.handleAddUpdateErrorResolution}
                    cancel={this.closeErrorDialog}
                    updating={this.state.updateMode}
                    selectedRow={this.state.selectedRow}
                    isViewOnly={this.state.isViewOnly}
                    loadOptions={this.loadOptions}
                />
            </IsMasterDataInitialized>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        env: state.rootReducer.env,
        records: state.errorLibraryReducer.errorLibraryData,
        totalRecords: state.errorLibraryReducer.totalRecords,
        filters: state.errorLibraryReducer.filters,
        hasReadOnlyAccess: state.rootReducer.isReadOnly
    };
};

const mapDispatchToProps = (dispatch) => ({
    setErrorsAction: (title, error, errorstack) => dispatch(setErrorsAction(title, error, errorstack)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setErrorLibraryData: (data, totalRecords) => dispatch(setErrorLibraryRecords(data, totalRecords)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess)),
    setLogoutMsg: (logoutMessage) => dispatch(setLogoutMsg(logoutMessage))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(ErrorLibrary));
