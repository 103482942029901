import { Middleware } from 'one-ring';
const getErrorListMapping = [
    Middleware.CreateMapping('statusCode', 'statusCode'),
    Middleware.CreateMapping('errorMessage', 'errorMessage'),
    Middleware.CreateMapping('protocol', 'protocol'),
    Middleware.CreateMapping('apiName', 'apiName'),
    Middleware.CreateMapping('appErrorCode', 'appErrorCode'),
    Middleware.CreateMapping('rootCause', 'rootCause'),
    Middleware.CreateMapping('endpoint', 'endpoint'),
    Middleware.CreateArrayMapping('results', 'results')
];

const createErrorLibraryMapping = [
    Middleware.CreateMapping('statusCode', 'statusCode'),
    Middleware.CreateMapping('errorMessage', 'errorMessage'),
    Middleware.CreateMapping('protocol', 'protocol'),
    Middleware.CreateMapping('apiName', 'apiName'),
    Middleware.CreateMapping('appErrorCode', 'appErrorCode'),
    Middleware.CreateMapping('rootCause', 'rootCause'),
    Middleware.CreateMapping('createdDate', 'createdDate'),
    Middleware.CreateMapping('updatedDate', 'updatedDate'),
    Middleware.CreateMapping('createdBy', 'createdBy'),
    Middleware.CreateMapping('protocol', 'protocol'),
    Middleware.CreateMapping('updatedBy', 'updatedBy'),
    Middleware.CreateMapping('Id', 'Id')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('ErrorLibraryList', 'POST')) {
        Middleware.RegisterMapping('ErrorLibraryList', 'POST', getErrorListMapping);
    }
    if (!Middleware.CheckMappingExists('ErrorLibrary', 'POST')) {
        Middleware.RegisterMapping('ErrorLibrary', 'POST', createErrorLibraryMapping);
        Middleware.RegisterMapping('ErrorLibrary', 'PATCH', createErrorLibraryMapping);
        Middleware.RegisterMapping('ErrorLibrary', 'DELETE');
    }
}

const ErrorListMapping = {
    ConfigureMiddleware
};

export default ErrorListMapping;
