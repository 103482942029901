import React, { Component } from 'react';
import { DrcSelect } from 'driscolls-react-components';
import { withStyles } from '@material-ui/core/styles';
import { Middleware } from 'one-ring';
import { withOktaAuth } from '@okta/okta-react';
import APIEndPoints from '../services/api';
import { getAccessToken, getLoadingMessage, makeOptions } from '../utils/helper';
import { Translate } from 'react-localize-redux';
import { setErrorsAction, setLogoutMsg } from '../actions/actions';
import { setApplicationList } from '../actions/OptionsActions';
import { connect } from 'react-redux';

const styles = () => ({
    drcSelectCustom: {
        width: '175px !important',
        paddingRight: '8px'
    }
});

class ApplicationSelectionDropDown extends Component {
    state = {
        applicationList: this.props.applicationList
    };

    loadApplicationDropDownData = async () => {
        var token = await getAccessToken(this.props);
        if (token) {
            try {
                let data = await Middleware.Send(
                    'AppNames',
                    token,
                    APIEndPoints.APPLICATION_NAMES(this.props.env),
                    'GET',
                    '',
                    getLoadingMessage('loadingAppNames')
                );
                let options = makeOptions(data, 'appName', 'appName');
                this.setState({ applicationList: options });
                this.props.setApplicationList(options);
            } catch (err) {
                this.props.setErrorsAction('API Error', 'Error occurred while fetch data.', err);
            }
        }
    };

    async componentDidMount() {
        if (!this.props.applicationList.length) {
            this.loadApplicationDropDownData();
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.env !== this.props.env) {
            this.loadApplicationDropDownData();
        }
    }

    render() {
        const { onChange, classes, value, required } = this.props;
        return (
            <>
                <DrcSelect
                    label={<Translate id="Application" />}
                    name="application"
                    onChange={(data) => onChange(data)}
                    value={value}
                    options={this.state.applicationList}
                    fullWidth={true}
                    className={classes.drcSelectCustom}
                    required={required}
                />
            </>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    setErrorsAction: (title, error, errorstack) => dispatch(setErrorsAction(title, error, errorstack)),
    setApplicationList: (list) => dispatch(setApplicationList(list)),
    setLogoutMsg: (logoutMessage) => dispatch(setLogoutMsg(logoutMessage))
});

const mapStateToProps = (state) => {
    return {
        applicationList: state.Options.applications,
        env: state.rootReducer.env
    };
};

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicationSelectionDropDown)));
