import { Middleware } from 'one-ring';
const getViewLogsListMapping = [
    Middleware.CreateMapping('errorDescription', 'errorDescription'),
    Middleware.CreateMapping('createdDate', 'createdDate'),
    Middleware.CreateMapping('correlationId', 'correlationId'),
    Middleware.CreateMapping('reprocessed', 'reprocessed'),
    Middleware.CreateMapping('checked', 'checked'),
    Middleware.CreateMapping('transactionId', 'transactionId'),
    Middleware.CreateMapping('errorCode', 'errorCode'),
    Middleware.CreateArrayMapping('Result', 'Result')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('ViewLogsList', 'GET')) {
        Middleware.RegisterMapping('ViewLogsList', 'GET', getViewLogsListMapping);
    }

    if (!Middleware.CheckMappingExists('DeleteLog', 'GET')) {
        Middleware.RegisterMapping('DeleteLog', 'DELETE');
    }

    if (!Middleware.CheckMappingExists('ViewPayload', 'GET')) {
        Middleware.RegisterMapping('ViewPayload', 'GET');
    }
    if (!Middleware.CheckMappingExists('ViewLog', 'GET')) {
        Middleware.RegisterMapping('ViewLog', 'GET');
    }
    if (!Middleware.CheckMappingExists('ReprocessErrorRecord', 'POST')) {
        Middleware.RegisterMapping('ReprocessErrorRecord', 'POST');
    }
    if (!Middleware.CheckMappingExists('CreateServiceNowTicket', 'POST')) {
        Middleware.RegisterMapping('CreateServiceNowTicket', 'POST');
    }
}

const ViewLogsListMapping = {
    ConfigureMiddleware
};

export default ViewLogsListMapping;
