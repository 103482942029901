import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowDownwardRounded from '@material-ui/icons/ArrowDownwardRounded';
import Add from '@material-ui/icons/Add';
import { DrcButton } from 'driscolls-react-components';
import { Translate } from 'react-localize-redux';
import IsEditAllowed from '../IsEditAllowed';

const styles = () => ({
    btnIcon: {
        width: '24px',
        height: '24px',
        marginRight: '2px',
        marginTop: '-2px'
    },
    exportContainer: {
        marginBottom: '8px'
    }
});

class CreateExport extends Component {
    render() {
        const { downloadData, startAddErrorSolution, classes } = this.props;
        return (
            <div className={classes.exportContainer}>
                <IsEditAllowed>
                    <DrcButton isSecondary onClick={startAddErrorSolution}>
                        <Add className={classes.btnIcon} />
                        <Translate id="NewError" />
                    </DrcButton>
                </IsEditAllowed>
                <DrcButton isSecondary onClick={downloadData} disabled={!this.props.records.length}>
                    <ArrowDownwardRounded className={classes.btnIcon} />
                    <Translate id="Export" />
                </DrcButton>
            </div>
        );
    }
}

export default withStyles(styles)(CreateExport);
