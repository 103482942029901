import { DuThemeUtilities } from 'driscolls-react-utilities';
import { DrcIconButton, DrcTooltip } from 'driscolls-react-components';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Translate } from 'react-localize-redux';
import IsEditAllowed from '../IsEditAllowed';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';

const ViewLogsActions = (lockRecord, classes, errorSourceList, handleActionClick) => (row) => {
    let actionButtons = [
        {
            icon: ControlPointDuplicateIcon,
            iconColor: DuThemeUtilities.DefaultColors.primary.blue,
            label: 'ViewActions',
            handler: (e) => handleActionClick(e, row),
            accessRestricted: false,
            disabled: false
        }
    ];

    actionButtons =
        errorSourceList === 'NR' || errorSourceList === 'nrError'
            ? actionButtons
            : [
                  {
                      icon: row.locked === 0 ? LockOpenIcon : LockIcon,
                      iconColor: DuThemeUtilities.DefaultColors.primary.blue,
                      label: row.locked === 0 ? 'Unlocked' : 'LockedBy',
                      handler: () => lockRecord(row),
                      accessRestricted: true,
                      disabled: false
                  },
                  ...actionButtons
              ];

    const getConstructLabel = (label, name) => {
        const constructedLabel =
            label === 'LockedBy' ? (
                <>
                    <Translate id={label} /> {name}
                </>
            ) : (
                <Translate id={label} />
            );

        return constructedLabel;
    };

    function getButtonComponent(btn, indx) {
        return (
            <DrcIconButton key={indx} isSecondary onClick={btn.handler} disabled={btn.disabled} className={classes.actionButton}>
                <DrcTooltip tipText={getConstructLabel(btn.label, row.updatedBy)} key={indx}>
                    <btn.icon style={{ width: '20px', height: '20px', color: btn.iconColor }} className={classes.btnicon} />
                </DrcTooltip>
            </DrcIconButton>
        );
    }
    return (
        <div>
            {actionButtons.map((btn, indx) =>
                btn.accessRestricted ? <IsEditAllowed key={btn.label}>{getButtonComponent(btn, indx)}</IsEditAllowed> : getButtonComponent(btn, indx)
            )}
        </div>
    );
};

export default ViewLogsActions;
