import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from './data/store';
import App from './App';
import Middleware from './data/middleware';
import { LocalizeProvider } from 'react-localize-redux';
import * as serviceWorker from './serviceWorker';

const renderApp = () =>
    render(
        <Provider store={Store}>
            <LocalizeProvider store={Store}>
                <App />
            </LocalizeProvider>
        </Provider>,
        document.getElementById('root')
    );

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./App', renderApp);
}

serviceWorker.register();
Middleware.ConfigureAllMiddleware();
renderApp();
