import { combineReducers } from 'redux';
import masterReducer from './MasterReducer';
import { localizeReducer, Translate } from 'react-localize-redux';
import optionReducer from './OptionsReducer';
import applicationListingReducer from './ApplicationListing';
import apiListingReducer from './ApiListing';
import viewLogsReducer from './ViewLogs';
import errorLibraryReducer from './ErrorLibrary';

import {
    SHOW_LOADING_SCREEN,
    HIDE_LOADING_SCREEN,
    SET_ERRORS,
    HIDE_ERROR_DIALOG,
    SET_PAGE_TITLE,
    SET_APP_FILTER,
    SET_ENV,
    SET_READONLY_ACCESS,
    SHOW_TOAST,
    SET_LOGOUT_MSG
} from '../actions/actions';
import { getCurrentEnvironment } from '../utils/helper';

const initialState = {
    showLoadingScreen: false,
    pageTitle: '',
    errorDialog: {
        show: false,
        title: <Translate id="APIError" />,
        content: 'Whazzzuuppp'
    },
    toastMessage: '',
    env: getCurrentEnvironment(window.subdomain === 'localhost' ? 'dev' : window.subdomain),
    isSuccess: true,
    logoutMessage: 'logOutMessage'
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SET_APP_FILTER:
            return Object.assign({}, state, {
                ...state,
                filters: action.payload
            });
        case SET_PAGE_TITLE:
            return Object.assign({}, state, {
                ...state,
                pageTitle: 'DIA: ' + action.payload
            });
        case SHOW_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: true,
                loadingScreenMessage: action.payload
            };
        case HIDE_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: false,
                loadingScreenMessage: ''
            };
        case SET_ERRORS:
            return {
                ...state,
                errorDialog: {
                    show: true,
                    title: action.payload.title,
                    content: action.payload.errors,
                    stack: action.payload.errorstack
                }
            };
        case HIDE_ERROR_DIALOG:
            return {
                ...state,
                errorDialog: {
                    show: false,
                    title: '',
                    content: ''
                }
            };
        case SET_ENV:
            return {
                ...state,
                env: (action.payload || 'dev').toLowerCase()
            };
        case SET_READONLY_ACCESS:
            return {
                ...state,
                isReadOnly: action.payload
            };
        case SHOW_TOAST:
            return {
                ...state,
                toastMessage: action.payload.toastMessage,
                isSuccess: action.payload.isSuccess
            };
        case SET_LOGOUT_MSG:
            return {
                ...state,
                logoutMessage: action.payload.logoutMessage
            };
        default:
            break;
    }

    return state;
}

export default combineReducers({
    rootReducer,
    masterReducer,
    applicationListingReducer,
    viewLogsReducer,
    apiListingReducer,
    errorLibraryReducer,
    localize: localizeReducer,
    Options: optionReducer
});
