import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import EnvironmentToggle from './EnvironmentToggle';

var styles = () => ({});

class HeaderButtons extends React.Component {
    state = {
        isLoggedIn: false
    };

    componentDidUpdate() {
        this.getGroup();
    }

    getGroup = async () => {
        let token = await this.props.authService.getAccessToken();

        if (!token || token.length <= 0) {
            if (this.state.isLoggedIn) {
                this.setState({ isLoggedIn: false });
            }

            return;
        }

        if (!this.state.isLoggedIn) {
            this.setState({ isLoggedIn: true });
        }
    };

    setEnv = (env) => {
        // This is intentional
    };

    render() {
        const { env } = this.props;

        return (
            <React.Fragment>
                {this.state.isLoggedIn ? (
                    <React.Fragment>
                        <EnvironmentToggle env={env} setEnv={this.setEnv} />
                    </React.Fragment>
                ) : null}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        env: state.masterReducer.env
    };
}

export default withOktaAuth(connect(mapStateToProps, null)(withRouter(withStyles(styles)(HeaderButtons))));
