import React, { Component } from 'react';
import { DrcDataGrid, DrcDialog, DrcIconButton, DrcTooltip } from 'driscolls-react-components';
import { Translate } from 'react-localize-redux';
import { withStyles } from '@material-ui/styles';
import { Close } from '@material-ui/icons';
import AddUpdateSolution from './AddUpdateSolution';
import Visibility from '@material-ui/icons/Visibility';
import GridStyles from '../styles/gridStyles';
import GridNoRecords from './GridNoRecords';
import isEqual from 'lodash/isEqual';
import { coloredLabelTemplate, labelTemplate } from '../utils/helper';

const styles = (theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '900px',
            width: '100%',
            overflowY: 'none',
            padding: '8px 16px',
            borderRadius: '8px',
            [theme.darkTheme]: {
                padding: '0px 0px !important'
            }
        },
        '& .MuiDialogActions-root': {
            border: 'none !important'
        }
    },
    content: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 200px)'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey
    },
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 380px)')
});

class PossibleSolutions extends Component {
    state = {
        columns: [],
        openSolution: false,
        selectedRow: null
    };

    async componentDidMount() {
        if (this.props.solutions.length) {
            this.makeColumns(this.props.solutions[0]);
        }
    }
    async componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.solutions, this.props.solutions) && this.props.solutions.length && !this.state.columns.length) {
            this.makeColumns(this.props.solutions[0]);
        }
    }

    handleClickOpen = (row) => {
        this.setState({
            openSolution: true,
            selectedRow: row
        });
    };

    actionTemplate = (row) => (
        <div>
            <DrcIconButton isSecondary onClick={() => this.handleClickOpen(row)} className={this.props.classes.actionButton}>
                <DrcTooltip tipText={<Translate id="ViewSolution" />}>
                    <Visibility className={this.props.classes.viewSolutionIcon} />
                </DrcTooltip>
            </DrcIconButton>
        </div>
    );

    getColumnTemplate = (key) => {
        let lowerCaseKey = key.toLowerCase();
        if (lowerCaseKey === 'statuscode' || lowerCaseKey === 'protocol' || lowerCaseKey === 'apperrorcode') {
            return coloredLabelTemplate(key);
        } else {
            return labelTemplate(key);
        }
    };

    makeColumns = (data) => {
        let excludingFields = ['updatedBy', 'solution', 'createdDate', 'createdBy', 'updatedDate', 'Id', 'disablePurge', 'method'];
        let columns = [];
        columns.push({ key: 'Actions', name: <Translate id="Actions" />, columnTemplate: this.actionTemplate });
        var tempColumns = Object.keys(data)
            .filter((val) => !excludingFields.includes(val))
            .map((key) => {
                return {
                    key: key,
                    name: <Translate id={key} />,
                    columnTemplate: this.getColumnTemplate(key)
                };
            });
        this.setState({ columns: columns.concat(tempColumns) });
    };

    handleCancel = () => {
        this.setState({ openSolution: false, selectedRow: null });
    };

    render() {
        const { title, open, cancel, classes, solutions } = this.props;
        return (
            <DrcDialog
                title={
                    <>
                        {title}
                        <DrcIconButton isSecondary className={classes.closeButton} onClick={cancel}>
                            <DrcTooltip title={<Translate id="Close" />}>
                                <Close />
                            </DrcTooltip>
                        </DrcIconButton>
                    </>
                }
                open={open}
                className={classes.dialog}
            >
                <div className={classes.content}>
                    {solutions.length > 1 ? (
                        <DrcDataGrid gridStyles={classes.gridStyles} columns={this.state.columns} rows={solutions} />
                    ) : (
                        <GridNoRecords />
                    )}
                    <AddUpdateSolution
                        selectedRow={this.state.selectedRow}
                        open={this.state.openSolution}
                        cancel={this.handleCancel}
                        isViewOnly={true}
                    />
                </div>
            </DrcDialog>
        );
    }
}

export default withStyles(styles)(PossibleSolutions);
