import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { DrcButton } from 'driscolls-react-components';
import { setEnv } from '../actions/actions';
import { connect } from 'react-redux';
import { envs, getCurrentEnvironment } from '../utils/helper';

var styles = () => ({
    headerButtons: {
        float: 'right',
        marginTop: '-42px',
        padding: '6px',
        minWidth: 0,
        margin: 4,
        border: 'none !important',
        '& svg': {
            fontSize: 24
        }
    },
    popover: {
        '& .MuiPaper-elevation8': {
            maxWidth: 500,
            width: '100px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
        }
    },
    langPopover: {
        '& .MuiPaper-elevation8': {
            padding: '4px 12px',
            maxWidth: 400,
            width: '100%'
        }
    },
    language: {
        width: 120,
        float: 'right',
        height: 22
    },
    languageBtn: {
        width: '100%',
        margin: '8px 0'
    },
    langButtons: {
        width: '8rem !important',
        float: 'none !important',
        margin: '4px auto !important',
        border: 'none !important'
    },
    berryLabel: {
        padding: '0px 0px 4px 0px',
        fontSize: '1.2rem'
    }
});

class EnvironmentToggle extends Component {
    state = {
        anchorEl: null
    };

    handleBerryClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    setEnv = (env) => () => {
        this.handleClose();
        this.props.setEnv(env);
    };

    getEnvsToDisplay = () => {
        let currentEnv = getCurrentEnvironment(window.subdomain === 'localhost' ? 'dev' : window.subdomain);
        if (currentEnv === 'stg' || currentEnv === 'prd') {
            return envs.slice(
                envs.findIndex((val) => val.value === 'stg'),
                envs.findIndex((val) => val.value === currentEnv) + 1
            );
        }
        return envs.slice(0, envs.findIndex((val) => val.value === currentEnv) + 1);
    };

    render() {
        const { classes, env } = this.props;
        const { anchorEl } = this.state;
        return (
            <>
                <DrcButton
                    isSecondary
                    aria-describedby={'Language-popover'}
                    className={classes.headerButtons}
                    noStyle
                    onClick={this.handleBerryClick}
                >
                    <span className={classes.berryLabel}>{envs.find((data) => data.value === env).label || 'Dev'}</span>
                </DrcButton>
                <Popover
                    id={'Language-popover'}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    className={classes.popover}
                >
                    {this.getEnvsToDisplay().map((env) => (
                        <DrcButton key={env.value} isSecondary onClick={this.setEnv(env.value)} className={classes.langButtons}>
                            <span className={classes.berryLabel}>{env.label}</span>
                        </DrcButton>
                    ))}
                </Popover>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        env: state.rootReducer.env
    };
};

const mapDispatchToProps = (dispatch) => ({
    setEnv: (env) => dispatch(setEnv(env))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EnvironmentToggle));
