import React, { Component } from 'react';
import { setPageTitleAction, showToast, setErrorsAction, setLogoutMsg } from '../actions/actions';
import { setApplicationListingRecords } from '../actions/ApplicationListingActions';
import { connect } from 'react-redux';
import { Middleware } from 'one-ring';
import { withOktaAuth } from '@okta/okta-react';
import APIEndpoints from '../services/api';
import { getAccessToken, getEndIsoDate, getLoadingMessage, getStartIsoDate, ReInitializeMasterOptions, getTitle } from '../utils/helper';
import { setApplicationList } from '../actions/OptionsActions';
import HeaderTitle from '../components/HeaderTitle';
import ApplicationListingFilters from '../components/ApplicationListing/Filter';
import ApplicationList from '../components/ApplicationListing/List';
import { setApiListingFilters } from '../actions/ApiListingActions';
import { Translate } from 'react-localize-redux';
import IsMasterDataInitialized from '../components/IsMasterDataInitialized';
import MainPanel from '../components/MainPanel';

const PAGE_TITLE = 'Application Listing';

class ApplicationListing extends Component {
    state = {
        page: 1,
        pageSize: 50,
        first: 0,
        title: ''
    };

    getApplicationData = async (currentPage, startDate, endDate) => {
        let token = await getAccessToken(this.props);
        var data = [];
        if (token) {
            data = await Middleware.Send(
                'ApplicationList',
                token,
                APIEndpoints.APPLICATION_LEVEL_DETAILS(this.props.env, currentPage, this.state.pageSize, startDate, endDate),
                'GET',
                '',
                getLoadingMessage('LoadingApplicationListing')
            );
        }
        return data;
    };

    initializeApplicationListingPage = async (startDate, endDate) => {
        let title = getTitle(startDate, endDate);
        var data = [];
        try {
            startDate = getStartIsoDate(startDate);
            endDate = getEndIsoDate(endDate);
            data = await this.getApplicationData(1, startDate, endDate);
        } catch (error) {
            this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileFetchingApplicationData" />, error);
        }
        this.setState({ first: 0, title });
        this.props.setApplicationListingData(data.applicationListing ? data.applicationListing : [], data.totalRecords ? data.totalRecords : 0);
    };

    initialize = () => {
        let startDate = this.props.filters.startDate ? this.props.filters.startDate : new Date();
        let endDate = this.props.filters.endDate ? this.props.filters.endDate : new Date();
        this.initializeApplicationListingPage(startDate, endDate);
        this.setApiListingFiltersData(startDate, endDate);
    };

    componentDidMount() {
        if (this.props.pageTitle !== PAGE_TITLE) {
            this.props.setPageTitle(PAGE_TITLE);
        }
        this.initialize();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.env !== this.props.env) {
            this.initialize();
            ReInitializeMasterOptions(this.props.setApplicationList);
        }
    }

    onPage = async (event) => {
        let data = await this.getApplicationData(
            event.first / this.state.pageSize + 1,
            getStartIsoDate(this.props.filters.startDate ? this.props.filters.startDate : new Date()),
            getEndIsoDate(this.props.filters.endDate ? this.props.filters.endDate : new Date())
        );
        this.props.setApplicationListingData(data.applicationListing ? data.applicationListing : [], data.totalRecords ? data.totalRecords : 0);
        this.setState({ first: event.first });
    };

    setApiListingFiltersData = (startDate, endDate) => {
        this.props.setApiListingFilters('', startDate, endDate);
    };

    onSearchButtonClick = async (filterValues) => {
        this.initializeApplicationListingPage(filterValues.startDate, filterValues.endDate);
        this.setApiListingFiltersData(filterValues.startDate, filterValues.endDate);
    };

    render() {
        return (
            <IsMasterDataInitialized>
                <MainPanel>
                    <HeaderTitle id="applicationListing" title={this.state.title} />
                    <ApplicationListingFilters handleSearch={this.onSearchButtonClick} />
                    <ApplicationList onPage={this.onPage} first={this.state.first} pageSize={this.state.pageSize} />
                </MainPanel>
            </IsMasterDataInitialized>
        );
    }
}
function mapStateToProps(state) {
    return {
        records: state.applicationListingReducer.applicationsData,
        totalRecords: state.applicationListingReducer.totalRecords,
        filters: state.applicationListingReducer.filters,
        env: state.rootReducer.env
    };
}
const mapDispatchToProps = (dispatch) => ({
    setErrorsAction: (title, error, errorstack) => dispatch(setErrorsAction(title, error, errorstack)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setApplicationListingData: (records, count) => dispatch(setApplicationListingRecords(records, count)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess)),
    setApplicationList: (list) => dispatch(setApplicationList(list)),
    setApiListingFilters: (apiName, startDate, endDate) => dispatch(setApiListingFilters(apiName, startDate, endDate)),
    setLogoutMsg: (logoutMessage) => dispatch(setLogoutMsg(logoutMessage))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(ApplicationListing));
