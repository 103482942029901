import React, { Component } from 'react';
import { DrcInput, DrcDialog, DrcButton } from 'driscolls-react-components';
import { Translate } from 'react-localize-redux';
import { withStyles } from '@material-ui/styles';

const styles = () => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '600px',
            width: '100%',
            padding: '8px 8px',
            borderRadius: '8px'
        },
        '& .MuiDialogActions-root': {
            border: 'none !important'
        }
    },
    descriptionInput: {
        height: '200px !important',
        '& .MuiInputBase-root': {
            height: '180px !important'
        },
        '& .MuiOutlinedInput-inputMultiline': {
            overflow: 'scroll !important',
            height: '150px !important'
        },
        '& MuiOutlinedInput-input': {
            padding: '16px 0px !important'
        },
        '& MuiOutlinedInput-inputMarginDense': {
            paddingTop: '0px !important',
            margin: '16px 0px !important'
        }
    },
    button: {
        width: '120px !important'
    }
});

class CreateTicketDialog extends Component {
    state = {
        title: '',
        description: ''
    };
    // eslint-disable-next-line
    expression = new RegExp(/^[a-zA-Z0-9-_,.& ]+$/);

    handleOnChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    checkIfAllFieldsAreNotFilled = () => {
        return !(this.state.title && this.state.description);
    };

    resetState = () => {
        this.setState({
            title: '',
            description: ''
        });
    };

    createTicket = () => {
        let data = {
            title: this.state.title,
            description: this.state.description
        };
        this.props.create(data);
        this.resetState();
    };

    cancelTicket = () => {
        this.resetState();
        this.props.cancel();
    };

    checkIfTitleIsInvalid = () => {
        return this.state.title ? !this.expression.test(this.state.title) : false;
    };

    render() {
        const { title, open, classes } = this.props;
        return (
            <DrcDialog
                title={title}
                open={open}
                buttons={
                    <div>
                        <DrcButton isSecondary onClick={this.cancelTicket} className={classes.button}>
                            <Translate id="Cancel" />
                        </DrcButton>
                        <DrcButton
                            isPrimary
                            onClick={this.createTicket}
                            disabled={this.checkIfAllFieldsAreNotFilled() || this.checkIfTitleIsInvalid()}
                            className={classes.button}
                        >
                            <Translate id="create" />
                        </DrcButton>
                    </div>
                }
                className={classes.dialog}
            >
                <DrcInput
                    label={<Translate id="Title" />}
                    name="title"
                    value={this.state.title}
                    onChange={this.handleOnChange}
                    helperText={this.checkIfTitleIsInvalid() ? <Translate id="titleValidationMessage" /> : ''}
                    required
                />
                <DrcInput
                    className={classes.descriptionInput}
                    label={<Translate id="Description" />}
                    name="description"
                    value={this.state.description}
                    onChange={this.handleOnChange}
                    required
                    multiline
                    rowsMax={10}
                />
            </DrcDialog>
        );
    }
}

export default withStyles(styles)(CreateTicketDialog);
