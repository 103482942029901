import React, { Component } from 'react';
import { DrcDialog, DrcButton } from 'driscolls-react-components';
import { Translate } from 'react-localize-redux';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '400px',
            borderRadius: '12px',
            width: '100%',
            overflowY: 'none',
            padding: '8px 16px',
            [theme.darkTheme]: {
                padding: '0px 0px !important',
            }
        },
        '& .MuiDialogActions-root': {
            border: 'none !important'
        }
    },
    button: {
        width: '100px !important'
    }
});

class ConfirmationDialog extends Component {
    render() {
        const { title, open, content, confirm, cancel, classes } = this.props;
        return (
            <DrcDialog
                title={title}
                open={open}
                buttons={
                    <div>
                        <DrcButton isSecondary onClick={cancel} className={classes.button}>
                            <Translate id="No" />
                        </DrcButton>
                        <DrcButton isPrimary onClick={confirm} className={classes.button}>
                            <Translate id="Yes" />
                        </DrcButton>
                    </div>
                }
                className={classes.dialog}
            >
                <b>{content}</b>
            </DrcDialog>
        );
    }
}

export default withStyles(styles)(ConfirmationDialog);
