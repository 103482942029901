import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import OverflowTip from '../OverflowTip';

const styles = () => ({
    container: {
        display: 'flex'
    },
    label: {
        color: '#eee',
        alignSelf: 'center',
        padding: '4px',
        textAlign: 'center',
        fontFamily: 'rubik',
        borderRadius: '4px',
        letterSpacing: '0.5px'
    }
});

class ColorLabelsUsingKeys extends Component {
    appliedColor = () => {
        switch (this.props.field.toLowerCase()) {
            case 'reprocessable':
                return '#fca130';
            case 'appversion':
                return '#62affe';
            case 'apiversion':
                return '#62affe';
            case 'appname':
                return '#62affe';
            case 'errorcount':
                return '#e73508';
            default:
                return '#f96977';
        }
    };

    render() {
        const { classes, val } = this.props;
        return (
            <div className={classes.container}>
                <OverflowTip title={val}>
                    <label style={{ background: this.appliedColor() }} className={`${classes.label}`}>
                        {val}
                    </label>
                </OverflowTip>
            </div>
        );
    }
}

export default withStyles(styles)(ColorLabelsUsingKeys);
