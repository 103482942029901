export const SET_API_LISTING_RECORDS = 'SET_API_LISTING_RECORDS';
export const setApiListingRecords = (data, totalRecords) => ({
    type: SET_API_LISTING_RECORDS,
    payload: { data, totalRecords }
});

export const SET_API_LISTING_FILTERS = 'SET_API_LISTING_FILTERS';
export const setApiListingFilters = (apiName, startDate,endDate) => ({
    type: SET_API_LISTING_FILTERS,
    payload: { apiName, startDate,endDate }
});
