// [English, Spanish, Chinese, French]

const APP_TRANSLATIONS = {
    applicationListing: ['Application Listing', 'Listado de aplicaciones', '申请清单', 'Liste des applications'],
    appName: ['Application Name', 'Nombre de la aplicación', '应用名称', "Nom de l'application"],
    appVersion: ['Application Version', 'Versión de la aplicación', '应用版本', "Version de l'application"],
    muleVersion: ['MuleSoft Version', 'Versión de Mulesoft', 'Mulesoft版本', 'Version Mulesoft'],
    errors: ['Errors', 'les erreurs', '失误', 'les erreurs'],
    reprocessable: ['Reprocessable', 'Reprocesables', '可再加工', 'Retraitables'],
    processListing: ['Process Listing', 'Listado de procesos', '流程清单', 'Liste des processus'],
    API: ['API', 'API', 'API', 'API'],
    Type: ['Type', 'Tipo', '类型', 'Type'],
    Version: ['Version', 'Versión', '版', 'Version'],
    Errorcount: ['Error Count', 'Recuento de errores', '错误计数', "Nombre d'erreurs"],
    applicationLogDashboard: [
        'Application Logs Dashboard',
        'Panel de registros de aplicaciones',
        '应用程序日志仪表板',
        "Tableau de bord des journaux d'application"
    ],
    home: ['Home', 'Casa', '家', 'Accueil'],
    apiListing: ['API Listing', 'Listado de API', 'API清单', "Liste d'API"],
    viewLogs: ['View Logs', 'Ver los registros', '查看日志', 'Regardes les connexions'],
    errorLibrary: ['Error Library', 'Biblioteca de errores', '错误库', "Bibliothèque d'erreurs"],
    releases: ['Releases', 'Lanzamientos', '发布', 'Communiqués'],
    processLogs: ['Process Logs', 'Registros de proceso', '流程日志', 'Journaux de processus'],
    EventId: ['Event Id', 'ID de evento', '活动编号', 'ID dévénement'],
    EndPoints: ['End Points', 'Puntos finales', '终点', 'Points de fin'],
    LogDescription: ['Log Description', 'Descripción del registro', '日志说明', 'Description du journal'],
    Details: ['Details', 'Detalles', '细节', 'Détails'],
    Actions: ['Actions', 'Comportamiento', '动作', 'Actions'],
    BackToProcessListing: ['Back To API Listing', 'Volver al listado de API', '返回流程清单', 'Retour à la liste des API'],
    Export: ['Export', 'Exportar', '出口', 'Exportation'],
    ViewLogs: ['View Logs', 'Ver los registros', '查看日志', 'Regardes les connexions'],
    Processes: ['Processes', 'Procesos', '工艺流程', 'Processus'],
    TransactionId: ['Transaction Id', 'ID de transacción', '交易编号', 'Identifiant de transaction'],
    Date: ['Date', 'Fecha', '日期', 'Date'],
    ErrorDescription: ['Error Description', 'Error de descripción', '错误说明', 'Erreur de description'],
    ErrorCode: ['Error Code', 'Código de error', '错误代码', 'Code derreur'],
    Reprocessed: ['Reprocessed', 'Reprocesado', '重新处理', 'Retraité'],
    Application: ['Application', 'Solicitud', '应用', 'Application'],
    TimePeriod: ['Time Period', 'Periodo de tiempo', '时间段', 'Période de temps'],
    SearchTransactionId: ['Search Transaction Id...', 'Buscar ID de transacción ...', '搜索交易ID ...', 'Rechercher lID de transaction ...'],
    DateRange: ['Date Range', 'Rango de fechas', '日期范围', 'Plage de dates'],
    Inventory: ['Inventory', 'Inventario', '库存', 'Inventaire'],
    Reprocess: ['Reprocess', 'Reprocesar', '重新处理', 'Retraiter'],
    getCurrentLoggedInUserNameFailed: [
        'Get current logged-in user name failed',
        'Error al obtener el nombre de usuario que inició sesión actual',
        '获取当前登录的用户名失败',
        "L'obtention du nom d'utilisateur actuellement connecté a échoué"
    ],
    ViewActions: ['View Actions', 'Ver acciones', '查看动作', 'Afficher les actions'],
    Locked: ['Locked', 'Bloqueada', '已锁定', 'Fermer à clé'],
    Unlocked: ['Unlocked', 'Desbloqueada', '已解锁', 'Débloqué'],
    LockRecord: ['Lock Record', 'Bloquear registro', '锁定记录', "Verrouiller l'enregistrement"],
    LockedBy: ['Locked By', 'Bloqueado por', '锁定者', 'Verrouillé par'],
    UnlockRecord: ['Unlock Record', 'Desbloquear registro', '解锁记录', "Déverrouiller l'enregistrement"],
    LockingRecord: ['Locking Record', 'Registro de bloqueo', '锁定记录', 'Enregistrement de verrouillage'],
    UnlockingRecord: ['Unlocking Record', 'Registro de desbloqueo', '解锁记录', "Déverrouiller l'enregistrement"],
    confirmLockRecord: [
        'This operation will lock the record',
        'Esta operación bloqueará el registro',
        '此操作将锁定记录',
        "Cette opération verrouille l'enregistrement"
    ],
    confirmUnlockRecord: [
        'This operation will unlock the record',
        'Esta operación desbloqueará el registro',
        '此操作将解锁记录',
        "Cette opération déverrouillera l'enregistrement"
    ],
    EnableLockSuccessful: ['Lock Record Successful', 'Bloquear registro exitoso', '锁定记录成功', "Verrouiller l'enregistrement réussi"],
    EnableUnlockSuccessful: [
        'Unlock Record Successful',
        'Desbloqueo de registro exitoso',
        '解锁记录成功',
        "Déverrouillage de l'enregistrement réussii"
    ],
    LockFail: ['Record Lock Failed', 'Error de bloqueo de grabación', '记录锁定失败', "Le verrouillage de l'enregistrement a échoué"],
    RecordLockFailedHeading: ['Lock Record Failed', 'Bloquear registro fallido', '锁定记录失败', "Verrouiller l'enregistrement a échoué"],
    RecordUnlockFailedHeading: [
        'Unlock Record Failed',
        'Desbloqueo de registro fallido',
        '解锁记录失败',
        "Le déverrouillage de l'enregistrement a échoué"
    ],
    RecordLockFailedMessage: [
        'The record is locked by another user.',
        'El registro está bloqueado por otro usuario',
        '该记录已被其他用户锁定',
        "L'enregistrement est verrouillé par un autre utilisateur"
    ],
    RecordLockFailedMessage2: [
        'Please wait for sometime or untill the user unlocks it.',
        'Espere algún tiempo o hasta que el usuario lo desbloquee',
        '请等待一段时间或直到用户将其解锁',
        "Veuillez attendre quelque temps ou jusqu'à ce que l'utilisateur le déverrouille"
    ],
    RecordUnlockFailedMessage: [
        'Unlocking the record failed. Please try later.',
        'Error al desbloquear el registro',
        '记录解锁失败',
        "Le déverrouillage de l'enregistrement a échoué"
    ],
    EnablingRecordLock: [
        'Enabling Record Lock',
        'Habilitación del bloqueo de grabación',
        '启用记录锁定',
        "Activation du verrouillage d'enregistrement"
    ],
    ViewLog: ['View Log', 'Ver registro', '查看日志', 'Afficher le journal'],
    CreateIncident: ['Create Incident', 'Crear Incident', '创建载荷', 'Créer un incident'],
    Purge: ['Purge', 'Purga', '清除', 'Purge'],
    ViewPayload: ['View Payload', 'Ver carga útil', '查看有效载荷', 'Voir la charge utile'],
    criticalErrors: ['Critical Errors', 'Errores críticos', '严重错误', 'Erreurs critiques'],
    integrationErrors: ['Integration Errors', 'Errores de integración', '整合错误', 'Erreurs dintégration'],
    processErrors: ['Process Errors', 'Errores de proceso', '工艺错误', 'Erreurs de processus'],
    newRelicErrors: ['New Relic Errors', 'Errores de reliquias nuevas', '新的遗物错误', 'Nouvelles erreurs de relique'],
    trendingErrors: ['Trending Errors', 'Errores de reliquias nuevas', '趋势错误', 'erreurs de tendance'],
    Refresh: ['Refresh', 'Actualizar', '刷新', 'Rafraîchir'],
    TogglePanel: ['Toggle Panel', 'Panel de alternancia', '切换面板', 'Basculer le panneau'],
    pastFourMonths: ['Past 4 Months', 'Últimos 4 meses', '过去四个月', '4 derniers mois'],
    pastFourWeeks: ['Past 4 Weeks', 'Últimas 4 semanas', '过去4周', '4 dernières semaines'],
    LoadingErrorTrends: ['Loading error trends ...', 'Cargando tendencias de error ...', '加载错误趋势...', "Chargement des tendances d'erreur. ..."],
    apiStatus: ['Api Status', 'Estado de la API', 'api状态', 'État de lAPI'],
    apiErrors: ['Application Errors', 'Errores de Application', 'api错误', 'Erreurs dApplication'],
    search: ['Search', 'Buscar', '搜索', 'Chercher'],
    reset: ['Reset', 'Reiniciar', '重启', 'Réinitialiser'],
    SampleError: ['Sample Error', 'error de muestra', '样本误差', 'erreur de échantillon'],
    RootCause: ['Root Cause', 'causa principal', '根本原因', 'cause première'],
    Solution: ['Solution', 'solución', '解', 'solution'],
    ErrorLibrary: ['Error Library', 'Biblioteca de errores', '错误', 'bibliothèque de erreurs'],
    NewError: ['New Error', 'Nueva errores', '新错误', 'nouvelle erreur'],
    Close: ['Close', 'cerrar', '关', 'proche'],
    View: ['View', 'Ver', '视图', 'Vue'],
    logout: ['LogOut', 'Cerrar sesión', '登出', 'Se déconnecter'],
    backToHome: ['Back To Home', 'De vuelta a casa', '回到家', 'De retour à la maison'],
    api: ['Api', 'API', '阿皮', 'API'],
    NoRecordsToDisplay: ['No records to display.', 'No hay registros que mostrar.', '无记录可显示', 'Aucun enregistrement à afficher.'],
    gridErrorMessage: [
        'Oops! There was an error while displaying data.',
        '¡Ups! Hubo un error al mostrar los datos.',
        '糟糕！显示数据时出错。',
        'Oups! Une erreur sest produite lors de laffichage des données.'
    ],
    deleteMsg: [
        'Are you sure you want to delete this record ?',
        '¿Está seguro de que desea eliminar este registro?',
        '您确定要删除此记录吗？',
        'Êtes-vous sûr de vouloir supprimer cet enregistrement?'
    ],
    Yes: ['Yes', 'si', '是', 'Oui'],
    selectAppropriatevalues: [
        'Please select appropriate values for application and process dropdowns',
        'Seleccione los valores adecuados para los menús desplegables de aplicaciones y procesos',
        '请为应用程序和过程下拉菜单选择适当的值',
        'Veuillez sélectionner les valeurs appropriées pour les listes déroulantes d application et de processus'
    ],
    Submit: ['Submit', 'enviar', '提交', 'soumettre'],
    MuleVersion: ['MuleSoft Version', 'Versión de Mulesoft', 'Mulesoft版本', 'Version Mulesoft'],
    confirm: ['Confirm', 'Confirmar', '确认', 'Confirmer'],
    oopsJustKidding: ['Oops Just Kidding', 'Cancelar', '取消', 'Annuler'],
    create: ['Create', 'Crear', '创建', 'Créer'],
    save: ['Save', 'Salvar', '救', 'sauvegarder'],
    update: ['Update', 'Actualizar', '更新资料', 'Mise à jour'],
    Title: ['Title', 'Título', '标题', 'Titre'],
    Description: ['Description', 'Descripción', '描述', 'La description'],
    ErrorMessage: ['Error Message', 'Mensaje de error', '', "Message d'erreur"],
    APIName: ['API Name', 'Nombre de API', 'API名称', "Nom de l'API"],
    Protocol: ['Protocol', 'Protocolo', '协议', 'Protocole'],
    ViewSolution: ['View Solution', 'Ver solución', '查看解决方案', 'Voir la solution'],
    ReprocessErrorRecord: ['Reprocess Error Record', 'Reprocesar registro de errores', '重新处理错误记录', 'Retraiter lenregistrement des erreurs'],
    ReprocessErrorRecordMsg: [
        'This operation will reprocess this error record, do you want to continue ?',
        'Esta operación volverá a procesar este registro de error, ¿desea continuar?',
        '此操作将重新处理此错误记录，您是否要继续？',
        'Cette opération retraitera cet enregistrement derreur, voulez-vous continuer?'
    ],
    PurgeRecordMsg: [
        'This operation will delete this record and you will not be able to recover it again.',
        'Esta operación eliminará este registro y no podrá recuperarlo nuevamente.',
        '此操作将删除该记录，您将无法再次恢复它。',
        'Cette opération supprimera cet enregistrement et vous ne pourrez plus le récupérer.'
    ],
    endpoint: ['End Point', 'Puntos finale', '终点', 'Point de fin'],
    protocol: ['Protocol', 'Protocolo', '协议', 'Protocole'],
    apiName: ['API Name', 'Nombre de API', 'API名称', "Nom de l'API"],
    appErrorCode: ['Error Code', 'Código de error', '错误代码', 'Code derreur'],
    rootCause: ['Root Cause', 'causa principal', '根本原因', 'cause première'],
    statusCode: ['Status Code', 'Código de estado', '状态码', 'Code détat'],
    UpdateSolution: ['Update Solution', 'Actualizar solución', '更新解决方案', 'Mettre à jour la solution'],
    ErrorResolution: ['Error Resolution', 'Resolución de errores', '错误解决', 'Résolution derreur'],
    UpdateErrorResolution: [
        'Update Error Resolution',
        'Actualizar resolución de errores',
        '更新错误解决方案',
        'Mettre à jour la résolution des erreurs'
    ],
    NewErrorResolution: ['New Error Resolution', 'Nueva resolución de errores', '新的错误解决方案', 'Nouvelle résolution derreur'],
    errorMessage: ['Error Description', 'Description de error', '错误信息', "Description d'erreur"],
    No: ['No', 'No', '没有', 'Non'],
    Cancel: ['Cancel', 'Cancelar', '取消', 'Annuler'],
    apiVersion: ['Api Version', 'Versión de API', 'api版本', 'Version de lAPI'],
    applicationType: ['Application Type', 'tipo de aplicacion', '申请类型', 'Type dapplication'],
    errorCount: ['Error Count', 'Recuento de errores', '错误计数', 'Nombre derreurs'],
    dbErrorCount: ['Process Error Count', 'Recuento de errores de proceso', '流程错误计数', "Nombre d'erreurs de processus"],
    nrErrorCount: ['New Relic Error Count', 'Recuento de errores de nueva reliquia', '新的文物错误计数', "Nouveau décompte d'erreurs de relique"],
    reprocessableCount: ['Reprocessable Count', 'Recuento reprocesable', '可重新处理的计数', 'Comptage retraitable'],
    AddErrorLibSuccessfull: [
        'Error Resolution Added Successfully',
        'Resolución de error agregada correctamente',
        '错误解决方法已成功添加',
        'Résolution derreur ajoutée avec succès'
    ],
    AddErrorLibUnSuccessfull: [
        'Failed To Add Error Library',
        'No se pudo agregar la biblioteca de errores',
        '添加错误库失败',
        'Échec de lajout de la bibliothèque derreurs'
    ],
    UpdateErrorLibSuccessfull: [
        'Error Resolution Updated Successfully',
        'Resolución de error actualizada correctamente',
        '错误解决方案已成功更新',
        'Résolution derreur mise à jour avec succès'
    ],
    UpdateErrorUnLibSuccessfull: [
        'Failed To Updated Error Resolution',
        'No se pudo actualizar la resolución del error',
        '无法更新错误解决方案',
        'Échec de la mise à jour de la résolution derreur'
    ],
    DeleteErrorLibSuccessful: [
        'Error Library Deleted Successfully',
        'Biblioteca de errores eliminada correctamente',
        '错误库已成功删除',
        'La bibliothèque derreurs a bien été supprimée'
    ],
    DeleteErrorLibUnSuccessfull: [
        'Failed To Delete Error Library',
        'Error al eliminar la biblioteca de errores',
        '删除错误库失败',
        'Échec de la suppression de la bibliothèque derreurs'
    ],
    PossibleSolution: ['Possible Solution', 'Solución posible', '可能的解决方案', 'Solution possible'],
    AddSolutionToLibrary: [
        'Add Solution To Library',
        'Agregar solución a la biblioteca',
        '将解决方案添加到库',
        'Ajouter une solution à la bibliothèque'
    ],
    mqListing: ['MQ Listing', 'Listado MQ', 'MQ清单', 'Liste MQ'],
    mqName: ['MQ Name', 'Nombre MQ', 'MQ名称', 'Nom MQ'],
    enableAutoReProcessing: [
        'Enable Auto Re Processing',
        'Habilitar reprocesamiento automático',
        '启用自动重新处理',
        'Activer le nouveau traitement automatique'
    ],
    ProcessALL: ['Re Process All', 'Re Procesar Todo', '处理全部', 'Re Traiter Tous'],
    errorDescription: ['Detailed Error Description', 'Detailde error de descripción', '错误说明', 'Detailed Erreur de description'],
    createdDate: ['Created Date', 'Fecha de creación', '创建日期', 'Date de création'],
    correlationId: ['Co RelationId', 'Co RelationId', '合作关系', 'Co RelationId'],
    reprocessed: ['Reprocessed', 'Reprocesado', '重新处理', 'Retraité'],
    transactionId: ['Transaction Id', 'ID de transacción', '交易编号', 'Identifiant de transaction'],
    errorCode: ['Error Code', 'Código de error', '错误代码', 'Code derreur'],
    DeleteErrorRecordSuccessFull: [
        'Error Record Deleted Successfully',
        'Registro de error eliminado correctamente',
        '错误记录已成功删除',
        'Enregistrement derreur supprimé avec succès'
    ],
    updatedDate: ['Updated Date', 'Fecha actualizada', '更新日期', 'Date de mise à jour'],
    updatedBy: ['Updated By', 'Actualizado por', '更新者', 'Mis à jour par'],
    reProcessMsg: [
        'This operation will reprocess all the errors,Do you want to continue?',
        'Cette opération retraitera toutes les erreurs. Voulez-vous continuer?',
        '此操作将重新处理所有错误，是否要继续？',
        'Cette opération retraitera toutes les erreurs. Voulez-vous continuer?'
    ],
    method: ['Method', 'Método', '方法', 'Méthode'],
    endPoint: ['End Point', 'Puntos finale', '终点', 'Point de fin'],
    CreateServiceNowTicketSuccessfull: [
        'Service Now Ticket Created Successfully',
        'El ticket de Service Now se creó correctamente',
        '现在已成功创建服务单',
        'Le ticket Service Now a été créé avec succès'
    ],
    CreateServiceNowTicketFailure: [
        'Failed To Create Service Now Ticket',
        'Error al crear el ticket de servicio ahora',
        '无法创建“立即服务”票证',
        'Échec de la création du ticket Service Now'
    ],
    deleteErrorResolution: ['Delete Error Resolution', 'Eliminar resolución de error', '删除错误解决方案', 'Supprimer la résolution derreur'],
    APIError: ['API Error', 'Error de API', 'API错误', 'Erreur API'],
    ErrorOccurredWhileFetchingApplicationData: [
        'Error Occurred While Fetching Application Data.',
        'Se produjo un error al obtener los datos de la aplicación',
        '提取应用程序数据时发生错误',
        'Une erreur sest produite lors de la récupération des données dapplication'
    ],
    ErrorOccurredWhileFetchingChartsData: [
        'Error Occurred While Fetching Charts Data.',
        'Se produjo un error al obtener datos de gráficos',
        '提取图表数据时发生错误',
        'Une erreur sest produite lors de la récupération des données des graphiques'
    ],
    InitializingDashboard: ['Initializing Dashboard...', 'Initialisation du tableau de bord ...', '初始化仪表板...', 'Inicializando Dashboard ...'],
    pastDays: ['Past 3 Days', 'Últimos 3 días', '过去3天', '3 derniers jours'],
    LoadingApplicationListing: [
        'Loading Application Listing...',
        'Cargando lista de aplicaciones ...',
        '正在载入应用程式清单...',
        'Chargement de la liste des applications ...'
    ],
    ErrorOccurredWhileFetchingApiListingData: [
        'Error Occured While Fetching Api Listing Data.',
        'Se produjo un error al obtener los datos de la lista de API.',
        '提取Api列表数据时发生错误。',
        'Une erreur sest produite lors de la récupération des données de la liste Api.'
    ],
    LoadingAPIListing: ['Loading API Listing...', 'Cargando listado de API ...', '载入API清单...', 'Chargement de la liste des API ...'],
    ApplicationListing: ['Application Listing', 'Listado de aplicaciones', '申请清单', 'Liste des applications'],
    ApiName: ['Api Name', 'Nombre de API', 'api名称', 'Nom de lAPI'],
    LoadingViewLogs: ['Loading View Logs...', 'Cargando detalles del proceso ...', '加载过程详细信息...', 'Chargement des détails du processus ...'],
    ErrorOccurredWhileFetchingLogsData: [
        'Error Occured While Fetching Logs Data',
        'Se produjo un error al obtener los datos de los registros',
        '提取日志数据时发生错误',
        'Une erreur sest produite lors de la récupération des données des journaux'
    ],
    PurgeRecord: ['Purge Record', 'Registro de purga', '清除记录', 'Enregistrement de purge'],
    purgeLogData: [
        'This operation will delete this record and you will not be able to recover it again.',
        'Esta operación eliminará este registro y no podrá recuperarlo nuevamente.',
        '此操作将删除该记录，您将无法再次恢复它。',
        'Cette opération supprimera cet enregistrement et vous ne pourrez plus le récupérer.'
    ],
    ReprocessLogMessage: [
        'This operation will reprocess this error record, do you want to continue ?',
        'Esta operación volverá a procesar este registro de error, ¿desea continuar?',
        '此操作将重新处理此错误记录，您是否要继续？',
        'Cette opération retraitera cet enregistrement derreur, voulez-vous continuer?'
    ],
    ServiceNowIncidentDetails: [
        'Service Now Incident Details',
        'Detalles del incidente de Service Now',
        '立即服务事件详细信息',
        'Détails de lincident Service Now'
    ],
    ErrorLogDetails: ['Error Log Details', 'Detalles del registro de errores', '错误日志详细信息', 'Détails du journal des erreurs'],
    LoadingLog: ['Loading Log...', 'Cargando registro ...', '正在载入日志...', 'Chargement du journal ...'],
    ErrorOccuredWhileLoadingLogDetails: [
        'Error Occured While Loading Log Details',
        'Se produjo un error al cargar los detalles del registro',
        '加载日志详细信息时发生错误',
        'Une erreur sest produite lors du chargement des détails du journal'
    ],
    ErrorDetails: ['Error Details', 'Error de detalles', '错误详情', 'Détails de lerreur'],
    LoadingPayload: ['Loading Payload...', 'Cargando carga útil ...', '正在加载有效载荷...', 'Chargement de la charge utile ...'],
    ErrorOccuredWhileLoadingPayloadDetails: [
        'Error Occured While Loading Payload Details',
        'Se produjo un error al cargar los detalles de la carga útil',
        '加载有效负载详细信息时发生错误',
        'Une erreur sest produite lors du chargement des détails de la charge utile'
    ],
    DeletingRecord: ['Deleting Record...', 'Eliminando registro ...', '正在删除记录...', 'Suppression dun enregistrement ...'],
    ErrorOccuredWhileDeletingLogsData: [
        'Error Occured While Deleting Logs Data',
        'Se produjo un error al eliminar los datos de los registros',
        '删除日志数据时发生错误',
        'Une erreur sest produite lors de la suppression des données des journaux'
    ],
    ReprocessingRecord: ['Reprocessing Record...', 'Reprocesamiento de registro ...', '正在重新处理记录...', 'Retraitement de lenregistrement ...'],
    ErrorOccuredWhileReprocessingErrorRecord: [
        'Error Occured While Reprocessing Error Record',
        'Error ocurrido al reprocesar el registro de errores',
        '重新处理错误记录时发生错误',
        'Une erreur sest produite lors du retraitement de lenregistrement derreur'
    ],
    CreatingServiceNowTicket: [
        'Creating Service Now Ticket...',
        'Creación de un ticket de servicio ahora ...',
        '正在创建“立即服务”票证...',
        'Création dun ticket Service Now ...'
    ],
    ErrorOccuredWhileCreatingServiceNowTicket: [
        'Error Occured While Creating Service Now Ticket',
        'Se produjo un error al crear el ticket de servicio ahora',
        '创建“立即服务”故障单时发生错误',
        'Une erreur sest produite lors de la création du ticket Service Now'
    ],
    FetchingAllPossibleSolutions: [
        'Fetching All Possible Solutions...',
        'Obteniendo todas las soluciones posibles ...',
        '正在获取所有可能的解决方案...',
        'Récupération de toutes les solutions possibles ...'
    ],
    AddingRecordToErrorLibrary: [
        'Adding Record To Error Library...',
        'Agregar registro a la biblioteca de errores ...',
        '正在将记录添加到错误库...',
        'Ajout dun enregistrement à la bibliothèque derreurs ...'
    ],
    ErrorOccuredWhileAddingRecordToErrorLibrary: [
        'Error Occured While Adding Record To Error Library',
        'Se produjo un error al agregar un registro a la biblioteca de errores',
        '将记录添加到错误库时发生错误',
        'Une erreur sest produite lors de lajout dun enregistrement à la bibliothèque derreurs'
    ],
    ReprocessSuccessfull: [
        'Error Log Reprocessed Successfully',
        'Registro de errores reprocesado correctamente',
        '错误日志已成功重新处理',
        'Journal des erreurs retraité avec succès'
    ],
    ReprocessFailure: [
        'Reprocess triggered successfully,but something went wrong,please check logs.',
        'El reprocesamiento se activó correctamente, pero algo salió mal, compruebe los registros.',
        '重新处理已成功触发，但是出了点问题，请检查日志。',
        'Retraitement déclenché avec succès, mais quelque chose sest mal passé, veuillez vérifier les journaux.'
    ],
    LoadingErrorLibrary: [
        'Loading Error Library Records...',
        'Cargando registros de la biblioteca de errores ...',
        '正在加载错误库记录...',
        'Chargement des enregistrements de la bibliothèque derreurs ...'
    ],
    ErrorOccuredWhileFetchingErrorLibraryRecords: [
        'Error Occured While Fetching Error Library Records.',
        'Se produjo un error al obtener registros de la biblioteca de errores',
        '提取错误库记录时发生错误',
        'Une erreur sest produite lors de la récupération des enregistrements de la bibliothèque derreur'
    ],
    PurgeSolution: ['Purge Solution', 'Solución de purga', '清洗液', 'Solution de purge'],
    DeletingErrorLibraryRecord: [
        'Deleting Error Library Record...',
        'Eliminando el registro de la biblioteca de errores ...',
        '删除错误库记录...',
        'Suppression de lenregistrement de la bibliothèque derreurs ...'
    ],
    ErrorOccurredWhileDeletingErrorLibraryRecord: [
        'Error Occured While Deleting Error Library Record',
        'Se produjo un error al eliminar el registro de la biblioteca de errores',
        '删除错误库记录时发生错误',
        'Une erreur sest produite lors de la suppression de lenregistrement de la bibliothèque derreur'
    ],
    AddingNewRecord: ['Adding New Record...', 'Agregar nuevo registro ...', '正在添加新记录...', 'Ajout dun nouvel enregistrement ...'],
    ErrorOccuredWhileAddingErrorLibraryRecord: [
        'Error Occured While Adding Error Library Record.',
        'Se produjo un error al agregar un registro de biblioteca de errores',
        '添加错误库记录时发生错误',
        'Une erreur sest produite lors de lajout dun enregistrement de bibliothèque derreur'
    ],
    UpdatingErrorLibraryRecord: [
        'Updating Error Library Record...',
        'Actualizando el registro de la biblioteca de errores ...',
        '正在更新错误库记录...',
        'Mise à jour de lenregistrement de la bibliothèque derreurs ...'
    ],
    ErrorOccuredWhileUpdatingErrorLibraryRecords: [
        'Error Occured While Updating Error Library Records.',
        'Se produjo un error al actualizar los registros de la biblioteca de errores',
        '更新错误库记录时发生错误',
        'Une erreur sest produite lors de la mise à jour des enregistrements de la bibliothèque derreurs'
    ],
    Payload: ['Payload', 'Carga útil', '有效载荷', 'Charge utile'],
    Source: ['Source', 'Fuente', '资源', 'La source'],
    ShowLegend: ['Show Legend', 'Mostrar leyenda', '显示图例', 'Afficher la légende'],
    SHowLabels: ['Show Labels', 'Mostrar etiquetas', '显示标签', 'Afficher les étiquettes'],
    Cannotremove: [
        'Cannot remove default applications!',
        '¡No se pueden eliminar las aplicaciones predeterminadas!',
        '无法删除默认应用程序',
        'Impossible de supprimer les applications par défaut!'
    ],
    AddApp: ['Add Application', 'Agregar aplicación', '添加申请', 'Ajouter une application'],
    Include: ['Include', 'Incluir', '包括', 'Comprendre'],
    ErrorOccuredWhileFetchingApiNames: [
        'Error Occured While Fetching Api Names',
        'Se produjo un error al obtener los nombres de las API',
        '提取Api名称时发生错误',
        'Une erreur sest produite lors de la récupération des noms dAPI'
    ],
    logOutMessage: [
        'Please wait... Logging you out shortly...',
        'Espere ... Cerrar sesión en breve ...',
        '请稍候...即将退出您的帐户...',
        'Veuillez patienter ... Déconnexion sous peu ...'
    ],
    sessionLoggedOut: [
        'Session expired... please login again...',
        'Session expirée ... veuillez vous reconnecter ...',
        '会话已过期...请重新登录...',
        'Sesión caducada ... por favor inicie sesión de nuevo ...'
    ],
    dateErrorMsg: [
        '* Maximum 3 Days Can Be Selected',
        '* Se puede seleccionar un máximo de 3 días',
        '* 最多可以选择3天',
        '* Un maximum de 3 jours peut être sélectionné'
    ],
    loadingChartUrls: ['Loading Chart Url`s', 'Cargando URL del gráfico', '正在加载图表网址', 'Chargement de l`URL du graphique'],
    loadingAppNames: ['Loading App Names...', 'Cargando nombres de aplicaciones...', '加载应用名称...', 'Chargement des noms dapplications...'],
    ExportAllRecords: ['Export All Records', 'Exportar todos los registros', '导出所有记录', 'Exporter tous les enregistrements'],
    ExportingAllErrorLibraryRecords: [
        'Exporting All Error Library Records',
        'Exportación de todos los registros de la biblioteca de errores',
        '导出所有错误库记录',
        'Exportation de tous les enregistrements de la bibliothèque derreurs'
    ],
    SpecialCharsNotAllowed: [
        '* Special characters are not allowed',
        '* Caracteres especiales no están permitidos',
        '* 不允许有特殊字符',
        '* Les caractères spéciaux ne sont pas autorisés'
    ],
    loadingApiNames: ['Loading Api Names....', 'Cargando nombres de api....', '加载应用名称....', 'Chargement des noms dapi....'],
    titleValidationMessage: [
        'Only comma,hyphen,ampersand,underscore,full stop and alphanumeric characters are allowed.',
        'Seuls les virgules, les tirets, les esperluettes, les traits de soulignement, les points et les caractères alphanumériques sont autorisés.',
        '仅允许使用逗号，连字符，＆符号，下划线，句号和字母数字字符.',
        'Solo se permiten comas, guiones, ampersand, guiones bajos, puntos y caracteres alfanuméricos.'
    ],
    endPointValidationMsg: [
        'Only forward slash,comma,colon,full stop,equal sign and alpha numeric characters are allowed',
        'Solo se permiten barras inclinadas, coma, dos puntos, punto, signo igual y caracteres alfanuméricos.',
        '仅允许使用正斜杠，逗号，冒号，句号，等号和字母数字字符',
        'Seuls la barre oblique, la virgule, les deux points, le point, le signe égal et les caractères alphanumériques sont autorisés'
    ],
    searchStringValidationMsg: [
        'Only hyphen, underscore and alpha numeric characters are allowed',
        'Seuls les tirets, traits de soulignement et caractères alphanumériques sont autorisés',
        '仅允许使用连字符，下划线和字母数字字符',
        'Solo se permiten guiones, guiones bajos y caracteres alfanuméricos.'
    ],
    corelationIdValidationMsg: [
        'Only hyphen and  alpha numeric characters are allowed',
        'Seuls les traits dunion et les caractères alphanumériques sont autorisés',
        '仅允许使用连字符和字母数字字符',
        'Solo se permiten guiones y caracteres alfanuméricos.'
    ],
    createdBy: ['Created By', 'Creado por', '由...制作', 'Créé par'],
    errorType: ['Error Type', 'Tipo de error', '错误类型', 'Type derreur']
};

export default APP_TRANSLATIONS;
