import React from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

const styles = (theme) => ({
    link: {
        margin: '8px 0px !important',
        cursor: 'pointer',
        color: `${theme.palette.primary.main}`,
        width: '175px',
        display: 'inline-flex',
        '&:hover': {
            color: `${theme.palette.secondary.dark}`,
            [theme.darkTheme]: {
                color: '#5cd25a'
            }
        }
    },
    linkText: {
        paddingTop: '2.5px'
    }
});

function BackButton({ linkText, link, classes, ...props }) {
    return (
        <div className={classes.link} onClick={() => props.history.push(link)}>
            <div>
                <ArrowBackIos />
            </div>
            <div className={classes.linkText}>
                <b>{linkText}</b>
            </div>
        </div>
    );
}

export default withRouter(withStyles(styles)(BackButton));
