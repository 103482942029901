import React, { Component } from 'react';
import { DrcDialog, DrcIconButton, DrcTooltip } from 'driscolls-react-components';
import { Translate } from 'react-localize-redux';
import { withStyles } from '@material-ui/styles';
import ReactJson from 'react-json-view';
import { Close } from '@material-ui/icons';

const styles = (theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '900px',
            width: '100%',
            overflowY: 'none',
            padding: '8px 16px',
            borderRadius: '8px',
            [theme.darkTheme]: {
                padding: '0px 0px !important'
            }
        },
        '& .MuiDialogActions-root': {
            border: 'none !important'
        },
        '& .MuiPaper-root': {
            backgroundColor: 'transparent !important'
        },
        '& .MuiPaper-elevation24': {
            boxShadow: '0px 0px 0px 0px !important'
        }
    },
    content: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 200px)',
        maxWidth: '900px',
        display: 'flex',
        flexFlow: 'row wrap'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey,
        border: 'none'
    }
});

class ViewLogOrPayloadDialog extends Component {
    handleCopy = (copy) => {
        navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'));
    };

    render() {
        const { title, open, content, cancel, classes } = this.props;
        return (
            <DrcDialog
                title={
                    <>
                        {title}
                        <DrcIconButton isSecondary className={classes.closeButton} onClick={cancel}>
                            <DrcTooltip title={<Translate id="Close" />}>
                                <Close />
                            </DrcTooltip>
                        </DrcIconButton>
                    </>
                }
                open={open}
                className={classes.dialog}
            >
                <div className={classes.content}>
                    <ReactJson
                        src={typeof content === 'string' ? JSON.parse(content) : content}
                        name={false}
                        collapseStringsAfterLength={100}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        style={{ width: '1000px' }}
                        enableClipboard={this.handleCopy}
                        theme={{
                            base00: '#f9f1f1',
                            base01: '#216565',
                            base02: '#216565',
                            base03: '#212165',
                            base04: 'purple',
                            base05: '#212165',
                            base06: '#212165',
                            base07: '#212165',
                            base08: '#216565',
                            base09: 'black',
                            base0A: 'rgba(70, 70, 230, 1)',
                            base0B: 'rgba(70, 70, 230, 1)',
                            base0C: 'rgba(70, 70, 230, 1)',
                            base0D: 'black',
                            base0E: '#212165',
                            base0F: '#212165'
                        }} //Defining base 16 colors to support proper colors in dark or light mode.
                    />
                </div>
            </DrcDialog>
        );
    }
}

export default withStyles(styles)(ViewLogOrPayloadDialog);
