import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    customTooltip: {
        backgroundColor: 'rgba(40,40,40, 0.8)',
        fontSize: '12px',
        [theme.darkTheme]: {
            backgroundColor: 'rgba(245,245,245, 0.8)',
            color: '#222222'
        }
    }
});

class OverflowTip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            overflowed: false
        };
        this.textElement = React.createRef();
    }

    componentDidMount() {
        this.setState({
            isOverflowed: this.textElement.current.scrollWidth > this.textElement.current.clientWidth
        });
    }

    render() {
        const { isOverflowed } = this.state;
        return (
            <Tooltip
                classes={{
                    tooltip: this.props.classes.customTooltip
                }}
                title={this.props.title}
                disableHoverListener={!isOverflowed}
            >
                <div
                    ref={this.textElement}
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {this.props.children}
                </div>
            </Tooltip>
        );
    }
}

export default withStyles(styles)(OverflowTip);
