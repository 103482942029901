import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logoutAction } from '../actions/actions';
import { withOktaAuth } from '@okta/okta-react';
import { DrcLoading, DrcPanel } from 'driscolls-react-components';
import { withStyles } from '@material-ui/styles';
import { Translate } from 'react-localize-redux';

const styles = (theme) => ({
    logOutDiv: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    logOutPanel: {
        boxShadow: '0px 0px 6px 0px #b5b5b5',
        borderRadius: '4px',
        [theme.darkTheme]: {
            boxShadow: 'none !important'
        },
        width: '400px',
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

class LogOut extends Component {
    constructor(props) {
        super(props);
        this.props.logout();
        this.props.authService.logout('/');
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.logOutDiv}>
                <DrcPanel className={classes.logOutPanel}>
                    <DrcLoading size="Medium" text={<Translate id={this.props.logoutMessage} />} />
                </DrcPanel>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        logoutMessage: state.rootReducer.logoutMessage
    };
};

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logoutAction())
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LogOut)));
