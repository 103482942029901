import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import { LoginCallback, Security } from '@okta/okta-react';
import {
    DrcBackdrop,
    DrcBackgroundImages,
    DrcImage,
    DrcLoading,
    DrcMain,
    DrcPageLogin,
    DrcPageNotFound,
    DrcPanel,
    DrcSecureGroupRoute,
    DrcSecureHeader,
    DrcThemeProvider,
    DrcThemeUtilities,
    DrcTranslations,
    DrcVersionInfo,
    Helmet
} from 'driscolls-react-components';
import { DuCommonTranslations, DuThemeUtilities } from 'driscolls-react-utilities';
import { Middleware } from 'one-ring';
import React, { lazy, Suspense } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { addInfo, hideErrorDialogAction, hideLoadingScreenAction, setErrorsAction, showLoadingScreenAction, showToast } from './actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized } from './actions/MasterActions';
import HeaderButtons from './components/HeaderButtons';
import ErrorDialog from './components/ErrorDialog';
import LINKS from './data/links';
import LoggingUtilities from './data/LoggingUtilities';
import MasterDataUtilities from './data/MasterDataUtilities';
import APP_TRANSLATIONS from './data/translations';
import LogoPng from './Images/Logo_Small_Transparent.png';
import LogoWebP from './Images/Logo_Small_Transparent.webp';
import ApplicationListing from './pages/ApplicationListing';
import Dashboard from './pages/Dashboard';
import ErrorLibrary from './pages/ErrorLibrary';
import InitializeApplication from './pages/InitializeApplication';
import LogOut from './pages/LogOut';
import ViewLogs from './pages/ViewLogs';
import ApiListing from './pages/ApiListing';
import ErrorBoundary from './components/ErrorBoundary';
import EnvironmentMarker from './components/EnvironmentMarker';
import { createHashHistory } from 'history';

const Releases = lazy(() => import('./pages/Releases'));
const HealthCheck = lazy(() => import('./pages/HealthCheck'));

const allGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || [], window.config.OKTA_READ_ONLY_GROUPS || []);

const SITE_NAME = 'DIA';

var styles = (theme) => ({
    '@global': {
        /* width */
        '::-webkit-scrollbar': {
            width: '10px',
            height: '10px',
            cursor: 'pointer'
        },
        /* Track */
        '::-webkit-scrollbar-track': {
            background: 'none',
            borderRadius: '2px',
            cursor: 'pointer'
        },
        /* Handle */
        '::-webkit-scrollbar-thumb': {
            backgroundColor: '#92ad8a',
            borderRadius: '2px',
            cursor: 'pointer'
        },
        '.Mui-disabled': {
            cursor: 'not-allowed !important'
        },
        '.MuiFormHelperText-root': {
            color: 'red !important'
        }
    },
    logoContainer: {
        marginLeft: 40,
        border: 'none !important'
    },
    logo: {
        height: 40,
        padding: '8px 0',
        marginRight: '24px'
    },
    header: {
        '& .toolbar': {
            minHeight: theme.spacing(8),
            height: theme.spacing(8),
            maxHeight: theme.spacing(8),
            backgroundColor: '#FFF',
            textShadow: `none`
        },
        '& .title': {
            textShadow: `none`,
            fontSize: 22
        },
        '& .appBar': {
            boxShadow: `none`,
            fontSize: theme.spacing(2.5)
        },
        '& .logOut': {
            right: 0,
            position: `absolute`,
            top: theme.spacing(1.5),
            paddingRight: theme.spacing(6),
            '& a:hover': {
                backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 80)
            }
        },
        '& .logOutLink': {
            color: DuThemeUtilities.DefaultColors.primary.green,
            border: 'none'
        },
        '& .logOutTitle': {
            paddingLeft: theme.spacing(1),
            fontSize: theme.spacing(2)
        },
        '& .menuButton': {
            border: `none`
        },
        '@media (prefers-color-scheme: dark)': {
            '& .toolbar': {
                backgroundColor: '#000'
            },
            '& .title': {
                color: DuThemeUtilities.DefaultColors.primary.green
            },
            '& .menuButton': {
                border: `none`
            },
            '& .menuButton svg': {
                color: DuThemeUtilities.DefaultColors.primary.green
            }
        }
    },
    headerBtnContainer: {
        float: 'right'
    },
    loader: {
        '& > div': {
            width: 320,
            left: 'calc(50vw - 160px)'
        }
    },
    berryBtn: {
        border: 'none !important',
        margin: '2px',
        float: 'right',
        top: 0,
        '& img': {
            height: 36
        }
    },
    printBtn: {
        border: 'none !important',
        margin: '2px',
        float: 'right',
        top: 0,
        '& svg': {
            fontSize: 36
        }
    },
    successToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.green,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    },
    errorToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.red,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    },
    appMain: {
        padding: '4rem 0rem 2rem 0rem',
        minHeight: '100vh'
    },
    headerLink: {
        color: '#4a773c !important',
        fontSize: '22px',
        textDecoration: 'none'
    }
});

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            theme: DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.green)
        };

        //Initializing the Internationalization
        this.props.initialize({
            languages: DuCommonTranslations.LANGUAGES,
            translation: {
                ...DuCommonTranslations.COMMON_TRANSLATIONS,
                ...DrcTranslations.DRC_PAGE_DATA_MAINTENANCE_TRANSLATIONS,
                ...APP_TRANSLATIONS
            },
            options: { renderToStaticMarkup }
        });

        this.props.setInitializeRedirectUrl(window.location.pathname.length > 1 ? window.location.pathname : '/Dashboard/');

        this.closeErrorDialog = this.closeErrorDialog.bind(this);

        MasterDataUtilities.Register(this.props.setInitializeRedirectUrl, this.props.setMasterDataInitialized);
        LoggingUtilities.Register(this.props.addInfo);
        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setErrorsAction, false);

        this.history = createHashHistory();

        window.chartColors = {
            red: 'rgb(255, 99, 132)',
            orange: 'rgb(255, 159, 64)',
            yellow: 'rgb(255, 205, 86)',
            green: 'rgb(75, 192, 192)',
            blue: 'rgb(54, 162, 235)',
            purple: 'rgb(153, 102, 255)',
            grey: 'rgb(201, 203, 207)'
        };
    }

    closeErrorDialog() {
        this.props.hideErrorDialog();
    }
    handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.showToast('', true);
    };

    render() {
        var { classes } = this.props;

        return (
            <div className={this.props.classes.appMain}>
                <DrcThemeProvider fontFamily="Rubik" theme={this.state.theme}>
                    <DrcBackgroundImages />
                    <EnvironmentMarker env={this.props.env} />
                    <Helmet>
                        <title>{this.props.pageTitle.length > 0 ? this.props.pageTitle : SITE_NAME}</title>
                    </Helmet>
                    <Router history={this.history}>
                        <Security
                            issuer={window.config.OKTA_ISSUER}
                            client_id={window.config.OKTA_CLIENT_ID}
                            redirect_uri={window.location.origin + '/implicit/callback'}
                            onAuthRequired={() => {
                                this.history.push('/');
                            }}
                            auto_renew={true}
                            pkce={false} // Added this as refreshing token api was getting failed,please let us know if we have a better solution for this,Thanks.
                            scopes={['openid', 'email', 'MulesoftAPIAccess']}
                        >
                            <ErrorBoundary path={window.location.pathname}>
                                <DrcSecureHeader
                                    title={
                                        <Link className={classes.headerLink} to={'/Dashboard/'}>
                                            <Translate id="applicationLogDashboard" />
                                        </Link>
                                    }
                                    allLinks={LINKS}
                                    fullWidth={true}
                                    logo={<DrcImage src={LogoPng} webp={LogoWebP} className={classes.logo} alt="Driscoll's Logo" />}
                                    className={classes.header}
                                >
                                    <HeaderButtons />
                                </DrcSecureHeader>
                                <Suspense
                                    fallback={
                                        <DrcMain>
                                            <DrcPanel>
                                                <DrcLoading />
                                            </DrcPanel>
                                        </DrcMain>
                                    }
                                >
                                    <Switch>
                                        <Route
                                            path="/"
                                            exact
                                            render={(props) => <DrcPageLogin {...props} setPageTitle={this.props.setPageTitleAction} />}
                                        />
                                        <DrcSecureGroupRoute
                                            path="/InitializeApplication/"
                                            exact
                                            component={InitializeApplication}
                                            groupsAllowed={allGroups}
                                        />
                                        <DrcSecureGroupRoute path="/Dashboard/" exact component={Dashboard} groupsAllowed={allGroups} />
                                        <DrcSecureGroupRoute
                                            path="/ApplicationList/"
                                            exact
                                            component={ApplicationListing}
                                            groupsAllowed={allGroups}
                                        />
                                        <DrcSecureGroupRoute
                                            path="/ViewLogs/:ApplicationName/:ProcessName"
                                            exact
                                            component={ViewLogs}
                                            groupsAllowed={allGroups}
                                        />
                                        <DrcSecureGroupRoute
                                            path="/ViewLogs/:ApplicationName/"
                                            exact
                                            component={ViewLogs}
                                            groupsAllowed={allGroups}
                                        />
                                        <DrcSecureGroupRoute path="/ViewLogs/" exact component={ViewLogs} groupsAllowed={allGroups} />
                                        <DrcSecureGroupRoute
                                            path="/ApiListing/:ApplicationName"
                                            exact
                                            component={ApiListing}
                                            groupsAllowed={allGroups}
                                        />
                                        <DrcSecureGroupRoute path="/ApiListing/" exact component={ApiListing} groupsAllowed={allGroups} />
                                        <DrcSecureGroupRoute path="/ErrorLibrary/" exact component={ErrorLibrary} groupsAllowed={allGroups} />
                                        <DrcSecureGroupRoute path="/Releases/" exact component={Releases} groupsAllowed={allGroups} />
                                        <Route path="/HealthCheck/" component={HealthCheck} />
                                        <Route path="/implicit/callback" component={LoginCallback} />
                                        <Route path="/LogOut/" exact component={LogOut} />
                                        <Route component={DrcPageNotFound} />
                                    </Switch>
                                </Suspense>
                            </ErrorBoundary>
                        </Security>
                        <DrcVersionInfo allowClick={this.props.isAdmin} />
                        <DrcBackdrop
                            className={classes.loader}
                            isLoading
                            loadingMessage={this.props.loadingScreenMessage}
                            show={this.props.showLoadingScreen}
                        />
                        <ErrorDialog isError errorDialog={this.props.errorDialog} open={this.props.errorDialog.show}></ErrorDialog>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            className={this.props.isSuccess ? classes.successToast : classes.errorToast}
                            open={this.props.toastMessage !== ''}
                            autoHideDuration={3000}
                            onClose={this.handleCloseNotification}
                            message={<span id="message-id">{this.props.toastMessage}</span>}
                        />
                    </Router>
                </DrcThemeProvider>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        errorDialog: state.rootReducer.errorDialog,
        pageTitle: state.rootReducer.pageTitle,
        isAdmin: state.masterReducer.isAdmin,
        toastMessage: state.rootReducer.toastMessage,
        isSuccess: state.rootReducer.isSuccess,
        env: state.rootReducer.env
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideErrorDialog: () => dispatch(hideErrorDialogAction()),
    showLoadingScreenAction: (title) => dispatch(showLoadingScreenAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setErrorsAction: (title, errors) => dispatch(setErrorsAction(title, errors)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess))
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(App)));
