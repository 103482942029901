// break this
class APIEndPoints {
    static BASE = window.config.API_BASE_ADDRESS;

    static ATTACH_ENV = (url, env) => {
        // check if url has params
        if (url === '') {
            return '';
        }

        if (url.indexOf('?') !== -1) {
            return `${url}&env=${env}`;
        } else {
            return `${url}?env=${env}`;
        }
    };

    static APPLICATION_NAMES = (env) => {
        return APIEndPoints.ATTACH_ENV(`${this.BASE}appnames`, env);
    };

    static API_NAMES = (env, appName) => {
        return APIEndPoints.ATTACH_ENV(`${this.BASE}apinames?appname=${appName}`, env);
    };

    static API_NAMES_SEARCH = (env, apiName) => {
        return APIEndPoints.ATTACH_ENV(`${this.BASE}apinames/search?apiname=${apiName}`, env);
    };

    static APPLICATION_LEVEL_DETAILS = (env, pageNumber, pageSize, startDate, endDate) => {
        return APIEndPoints.ATTACH_ENV(
            `${this.BASE}apps?startdate=${startDate}&enddate=${endDate}&pagenumber=${pageNumber}&pagesize=${pageSize}`,
            env
        );
    };

    static API_LEVEL_DETAILS = (env, pageNumber, pageSize, startDate, endDate, appName, apiName) => {
        return APIEndPoints.ATTACH_ENV(
            `${this.BASE}apis?appname=${appName}&startdate=${startDate}&enddate=${endDate}&pagenumber=${pageNumber}&pagesize=${pageSize}&apiname=${apiName}`,
            env
        );
    };

    static VIEW_LOGS_DETAILS = (
        env,
        pageNumber,
        pageSize,
        startDate,
        endDate,
        apiName,
        transactionId,
        reprocessable,
        endPoint,
        source,
        searchString,
        appName,
        searchLike
    ) => {
        return APIEndPoints.ATTACH_ENV(
            `${
                this.BASE
            }errorrecords/search?apiname=${apiName}&appname=${appName}&startdate=${startDate}&enddate=${endDate}&pagenumber=${pageNumber}&pagesize=${pageSize}&transactionid=${transactionId}&endpoint=${endPoint}&source=${source}&searchlike=${searchLike}&query=${searchString}${
                reprocessable !== null ? `&reprocessable=${reprocessable}` : ''
            }`,
            env
        );
    };

    static REPROCESS_ERROR_RECORD = (env) => {
        return APIEndPoints.ATTACH_ENV(`${this.BASE}reprocess`, env);
    };

    static PROCESS_RECORD_LOCK = (env) => {
        return APIEndPoints.ATTACH_ENV(`${this.BASE}lock`, env);
    };

    static VIEW_ERROR_LISTING_LOG = (env, transactionId, apiName, startDate, endDate, source) => {
        return APIEndPoints.ATTACH_ENV(
            `${this.BASE}log?transactionid=${transactionId}&apiname=${apiName}&startdate=${startDate}&enddate=${endDate}&source=${source}`,
            env
        );
    };

    static CREATE_SERVICE_NOW_TICKET = (env, updatedBy, id, source) => {
        return APIEndPoints.ATTACH_ENV(`${this.BASE}ticket?updatedby=${updatedBy}&source=${source}${id ? `&id=${id}` : ''}`, env);
    };

    static DELETE_ERROR_LISTING_RECORD = (env) => {
        return APIEndPoints.ATTACH_ENV(`${this.BASE}errorrecords/errorrecord`, env);
    };

    static VIEW_ERROR_LISTING_PAYLOAD = (env, id) => {
        return APIEndPoints.ATTACH_ENV(`${this.BASE}payload?id=${id}`, env);
    };

    static ERROR_LIBRARY_DETAILS = (env, pageNumber, pageSize, checkForSolution) => {
        return APIEndPoints.ATTACH_ENV(
            `${this.BASE}liberrors/search?pagenumber=${pageNumber}&pagesize=${pageSize}${
                checkForSolution ? `&checkforsolution=${checkForSolution}` : ``
            }`,
            env
        );
    };

    static ADD_UPDATE_ERROR_LIBRARY = (env, id, updatedBy, source) => {
        return APIEndPoints.ATTACH_ENV(
            `${this.BASE}liberrors/liberror${id || updatedBy || source ? `?updatedby=${updatedBy}&source=${source}${id ? `&id=${id}` : ''}` : ''}`,
            env
        );
    };

    static DELETE_ERROR_LIBRARY = (env, id) => {
        return APIEndPoints.ATTACH_ENV(`${this.BASE}liberrors/liberror?id=${id}`, env);
    };

    static GET_ERROR_TRENDS = (env) => {
        return APIEndPoints.ATTACH_ENV(`${this.BASE}errorrecords/trends?`, env);
    };
}

export default APIEndPoints;
