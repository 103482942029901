export const SET_VIEW_LOGS_RECORDS = 'SET_VIEW_LOGS_RECORDS';
export const setViewLogsRecords = (data, totalRecords) => ({
    type: SET_VIEW_LOGS_RECORDS,
    payload: { data, totalRecords }
});

export const SET_VIEW_LOGS_FILTERS = 'SET_VIEW_LOGS_FILTERS';
export const setViewLogsFilters = (data) => ({
    type: SET_VIEW_LOGS_FILTERS,
    payload: { data }
});
