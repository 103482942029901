import { SET_VIEW_LOGS_RECORDS, SET_VIEW_LOGS_FILTERS } from '../actions/ViewLogsActions';

const initialState = {
    viewLogsData: [],
    totalRecords: 0,
    filters: {
        startDate: new Date(),
        endDate: new Date(),
        application: '',
        apiName: '',
        transactionId: '',
        reProcessable: '',
        source: 'DB',
        endPoint: '',
        searchString: '',
        searchLike: true
    }
};

const ViewLogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VIEW_LOGS_RECORDS:
            return Object.assign({}, state, {
                viewLogsData: action.payload.data,
                totalRecords: action.payload.totalRecords
            });
        case SET_VIEW_LOGS_FILTERS:
            return Object.assign({}, state, {
                filters: action.payload.data
            });
        default:
            return state;
    }
};

export default ViewLogsReducer;
