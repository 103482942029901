import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { DrcIconButton, DrcTooltip } from 'driscolls-react-components';
import { Translate } from 'react-localize-redux';
import IsEditAllowed from '../IsEditAllowed';
import Edit from '@material-ui/icons/Edit';
import Visibility from '@material-ui/icons/Visibility';

const ListActions = (startViewSolution, startUpdate, startDelete, classes) => (row) => {
    const ACTION_BUTTONS = [
        {
            icon: Visibility,
            class: classes.viewSolutionIcon,
            label: 'ViewSolution',
            handler: () => startViewSolution(row),
            accessRestricted: false,
            disabled: false
        },
        {
            icon: Edit,
            class: classes.updateSolutionIcon,
            label: 'UpdateSolution',
            handler: () => startUpdate(row),
            accessRestricted: true,
            disabled: false
        },
        {
            icon: DeleteForeverOutlinedIcon,
            class: classes.purgeIcon,
            label: 'PurgeSolution',
            handler: () => startDelete(row),
            accessRestricted: true,
            disabled: false
        }
    ];

    const getButtonComponent = (btn, indx) => (
        <DrcIconButton key={indx} isSecondary onClick={btn.handler} disabled={btn.disabled} className={classes.actionButton}>
            <DrcTooltip tipText={<Translate id={btn.label} />}>
                <btn.icon className={btn.class} />
            </DrcTooltip>
        </DrcIconButton>
    );

    return (
        <div>
            {ACTION_BUTTONS.map((btn, indx) =>
                btn.accessRestricted ? <IsEditAllowed>{getButtonComponent(btn, indx)}</IsEditAllowed> : getButtonComponent(btn, indx)
            )}
        </div>
    );
};

export default ListActions;
