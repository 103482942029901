import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import BackButton from '../BackButton';
import { Translate } from 'react-localize-redux';
import ErrorLibraryFilter from './Filter';
import CreateExport from './CreateExport';

const styles = () => ({
    filterItems: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});

class ErrorLibraryFilterAndBackButtonWrapper extends Component {
    render() {
        const { classes } = this.props;
        return (
            <>
                <div>
                    <BackButton linkText={<Translate id="backToHome" />} link={`/Dashboard/`} />
                </div>
                <div className={classes.filterItems}>
                    <ErrorLibraryFilter
                        handleSearch={this.props.handleSearch}
                        getProcessListData={this.props.getProcessListData}
                        apiNameList={this.props.apiNameList}
                    />
                    <CreateExport
                        downloadData={this.props.downloadData}
                        startAddErrorSolution={this.props.startAddErrorSolution}
                        records={this.props.records}
                    />
                </div>
            </>
        );
    }
}

export default withStyles(styles)(ErrorLibraryFilterAndBackButtonWrapper);
