import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import GridStyles from '../../styles/gridStyles';
import { DrcDataGrid } from 'driscolls-react-components';
import GridNoRecords from '../GridNoRecords';
import { connect } from 'react-redux';
import { colorBgTemplate, coloredLabelTemplate, getRowCount } from '../../utils/helper';
import DisplayLink from '../TableCellFormatters/DisplayLink';
import { Translate } from 'react-localize-redux';

const styles = (theme) => ({
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 380px)')
});

class ApiListingList extends Component {
    state = {
        columns: []
    };
    currentPage = 1;
    uniqueKeyField = 'apiName';

    componentDidMount() {
        this.setState({
            columns: this.makeColumns(this.props.records[0] ? this.props.records[0] : [])
        });
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.records && !prevProps.records.length && this.props.records && this.props.records.length > 0) ||
            prevProps.appName !== this.props.appName
        ) {
            this.setState({
                columns: this.makeColumns(this.props.records[0] ? this.props.records[0] : [])
            });
        }
    }

    apiNameTemplate = (row) => <>{row.apiName}</>;

    dbErrorCountLinkTemplate = (row) => (
        <DisplayLink
            linkText={row.dbErrorCount}
            errorSource={'processError'}
            link={this.props.appName ? `/ViewLogs/${this.props.appName}/${row.apiName}` : `/ViewLogs/ /${row.apiName}`}
        />
    );

    newRelicErrorCountLinkTemplate = (row) => (
        <DisplayLink
            linkText={row.nrErrorCount}
            errorSource={'nrError'}
            link={this.props.appName ? `/ViewLogs/${this.props.appName}/${row.apiName}` : `/ViewLogs/ /${row.apiName}`}
        />
    );

    getColumnTemplate = (key) => {
        let lowerCaseKey = key.toLowerCase();
        if (lowerCaseKey === 'apiname') {
            return this.apiNameTemplate;
        } else if (lowerCaseKey === 'applicationtype') {
            return coloredLabelTemplate(key);
        } else if (lowerCaseKey === 'dberrorcount') {
            return this.dbErrorCountLinkTemplate;
        } else if (lowerCaseKey === 'nrerrorcount') {
            return this.newRelicErrorCountLinkTemplate;
        }
        return colorBgTemplate(key);
    };

    makeColumns = (data) => {
        let excludingFields = ['muleVersion'];
        let columns = [];
        columns = Object.keys(data)
            .map((key) => {
                if (!excludingFields.includes(key)) {
                    return {
                        key,
                        name: <Translate id={key} />,
                        columnTemplate: this.getColumnTemplate(key)
                    };
                }
                return null;
            })
            .filter((e) => e);

        return columns;
    };

    render() {
        const { first, onPage, pageSize, appName, classes } = this.props;
        return (
            <div>
                {this.props.records.length && appName ? (
                    <DrcDataGrid
                        paginator={true}
                        currentPage={first}
                        onPage={onPage}
                        pageSize={pageSize}
                        rowsToLoadPerScroll={getRowCount(this.props.totalRecords, this.state.pageSize)}
                        columns={this.state.columns}
                        rows={this.props.records}
                        gridStyles={classes.gridStyles}
                        uniqueKeyField={this.uniqueKeyField}
                        totalRecords={this.props.totalRecords}
                        resultCount={this.props.totalRecords}
                        virtualRowHeight={48}
                        lazy={true}
                        showReport={true}
                    />
                ) : (
                    <GridNoRecords />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        records: state.apiListingReducer.apiListData,
        totalRecords: state.apiListingReducer.totalRecords
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(ApiListingList));
