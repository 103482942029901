import ApplicationList from './ApplicationListing';
import ApiListing from './ApiListing';
import ViewLogsList from './ViewLogsListing';
import ErrorLibrary from './ErrorLibraryListing';
import ApplicationNamesList from './ApplicationNamesListing';

function ConfigureAllMiddleware() {
    ApplicationList.ConfigureMiddleware();
    ApiListing.ConfigureMiddleware();
    ViewLogsList.ConfigureMiddleware();
    ErrorLibrary.ConfigureMiddleware();
    ApplicationNamesList.ConfigureMiddleware();
}

const Middleware = {
    ConfigureAllMiddleware
};

export default Middleware;
