import { SET_APPLICATION_LIST } from '../actions/OptionsActions';

const initialState = {
    applications: [],
    apiTypes: []
};

const OptionsReducer = (state = initialState, action) => {
    if (action.type === SET_APPLICATION_LIST) {
        return Object.assign({}, state, {
            applications: action.payload
        });
    }
    else {
        return state;
    }
};

export default OptionsReducer;
