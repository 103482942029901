import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import GridStyles from '../../styles/gridStyles';
import { DrcDataGrid } from 'driscolls-react-components';
import GridNoRecords from '../GridNoRecords';
import ViewLogsActions from './ViewLogsActions';
import { coloredLabelTemplate, getRowCount, labelTemplate, reprocessTemplate } from '../../utils/helper';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

const styles = (theme) => ({
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 380px)'),
    actionButton: {
        border: 'none',
        margin: 0,
        padding: '1px',
        minWidth: '36px',
        backgroundColor: 'transparent !important',
        '&:hover': {
            border: 'none !important',
            backgroundColor: 'transparent !important',
            opacity: 0.5,
            transform: 'scale(1.3)'
        },
        '&:disabled': {
            cursor: 'not-allowed !important',
            backgroundColor: 'transparent !important',
            border: 'none !important',
            opacity: 0.2
        }
    },
    selectedClassReadOnly: {
        width: '45px !important',
        padding: '0 10px !important'
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 30px !important'
    }
});

class ViewLogsList extends Component {
    state = {
        columns: [],
        page: 1
    };

    componentDidMount() {
        this.setState({
            columns: this.makeColumns(this.props.records[0] ? this.props.records[0] : [])
        });
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.records &&
            this.props.records &&
            this.props.records.length > 0 &&
            (!prevProps.records.length ||
                (prevProps.records.length > 0 && Object.keys(prevProps.records[0]).length !== Object.keys(this.props.records[0]).length))
        ) {
            this.setState({
                columns: this.makeColumns(this.props.records[0])
            });
        }
    }

    getColumnTemplate = (key) => {
        let lowerCaseKey = key.toLowerCase();
        if (lowerCaseKey === 'reprocessable') {
            return reprocessTemplate(key);
        } else if (lowerCaseKey === 'errorcode' || lowerCaseKey === 'method') {
            return coloredLabelTemplate(key);
        } else {
            return labelTemplate(key);
        }
    };

    makeColumns = (data) => {
        let excludingFields = ['id', 'locked', 'disablePurge', 'rootCause', 'disableTicketCreation', 'disableAddSolution', 'index'];
        let columns = [];
        let actionTemplate = ViewLogsActions(this.props.lockRecord, this.props.classes, this.props.errorSourceList, this.props.handleActionClick);
        columns.push({ key: 'Actions', name: <Translate id="Actions" />, columnTemplate: actionTemplate, frozen: true });
        var tempColumns = Object.keys(data)
            .map((key) => {
                if (!excludingFields.includes(key)) {
                    return {
                        key,
                        name: <Translate id={key} />,
                        columnTemplate: this.getColumnTemplate(key)
                    };
                }
                return null;
            })
            .filter((e) => e);

        return columns.concat(tempColumns);
    };

    render() {
        const { classes, onRowsSelected, selectedIndexes } = this.props;
        return (
            <div>
                {this.props.records?.length ? (
                    <DrcDataGrid
                        paginator={true}
                        currentPage={this.props.first}
                        onPage={this.props.onPage}
                        uniqueKeyField={this.uniqueKeyField}
                        columns={this.state.columns}
                        rows={this.props.records}
                        gridStyles={classes.gridStyles}
                        rowSelect={true}
                        selectionMode="multiple"
                        selectedFrozen={true}
                        selected={selectedIndexes}
                        selectedClass={this.props.hasReadOnlyAccess ? classes.selectedClassReadOnly : classes.selectedClass}
                        selectionChange={onRowsSelected}
                        {...(!this.props.hasReadOnlyAccess ? { frozenWidth: '124px' } : { frozenWidth: '100px' })}
                        totalRecords={this.props.totalRecords}
                        resultCount={this.props.totalRecords}
                        pageSize={this.props.pageSize}
                        rowsToLoadPerScroll={getRowCount(this.props.totalRecords, this.props.pageSize)}
                        lazy={true}
                        virtualRowHeight={48}
                        showReport={true}
                    />
                ) : (
                    <GridNoRecords />
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        hasReadOnlyAccess: state.rootReducer.isReadOnly
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(ViewLogsList));
