import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import GridStyles from '../../styles/gridStyles';
import { DrcDataGrid } from 'driscolls-react-components';
import GridNoRecords from '../GridNoRecords';
import ListActions from './ListActions';
import { connect } from 'react-redux';
import { coloredLabelTemplate, getRowCount, labelTemplate } from '../../utils/helper';
import { Translate } from 'react-localize-redux';
import { DuThemeUtilities } from 'driscolls-react-utilities';

const styles = (theme) => ({
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 380px)'),
    purgeIcon: {
        width: '20px',
        height: '20px',
        color: DuThemeUtilities.DefaultColors.primary.red,
        '& :hover': {
            color: '#f71708'
        }
    },
    viewSolutionIcon: {
        width: '20px',
        height: '20px',
        color: DuThemeUtilities.DefaultColors.primary.blue,
        '& :hover': {
            color: '#53bbc5'
        }
    },
    updateSolutionIcon: {
        width: '20px',
        height: '20px',
        color: 'rgb(0, 179, 0)',
        '& :hover': {
            color: '#59ca35'
        }
    },
    actionButton: {
        border: 'none',
        margin: 0,
        padding: '4px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: 'transparent !important'
        },
        '&:hover': {
            border: 'none !important',
            backgroundColor: 'transparent !important',
            transform: 'scale(1.3)'
        }
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 10px !important'
    }
});

class List extends Component {
    state = {
        columns: []
    };

    componentDidMount() {
        this.setState({
            columns: this.makeColumns(this.props.records[0] ? this.props.records[0] : [])
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.records && !prevProps.records.length && this.props.records && this.props.records.length > 0) {
            this.setState({
                columns: this.makeColumns(this.props.records[0])
            });
        }
    }

    getColumnTemplate = (key) => {
        let lowerCaseKey = key.toLowerCase();
        if (lowerCaseKey === 'statuscode' || lowerCaseKey === 'protocol' || lowerCaseKey === 'apperrorcode') {
            return coloredLabelTemplate(key);
        } else {
            return labelTemplate(key);
        }
    };

    makeColumns = (data) => {
        let excludingFields = ['solution', 'createdDate', 'createdBy', 'Id', 'disablePurge', 'method'];
        var columns = [];
        let actionTemplate = ListActions(this.props.startViewSolution, this.props.startUpdate, this.props.startDelete, this.props.classes);
        columns = [{ key: 'Actions', name: <Translate id="Actions" />, columnTemplate: actionTemplate, frozen: true }];
        columns = [
            ...columns,
            ...Object.keys(data)
                .sort()
                .map((key) => {
                    if (!excludingFields.includes(key)) {
                        return {
                            key: key,
                            name: <Translate id={key} />,
                            columnTemplate: this.getColumnTemplate(key)
                        };
                    }
                    return null;
                })
                .filter((e) => e)
        ];
        return columns;
    };

    render() {
        const { classes, onRowsSelected, selectedIndexes, first, onPage, pageSize } = this.props;
        return (
            <div>
                {this.props.records.length ? (
                    <DrcDataGrid
                        paginator={true}
                        currentPage={first}
                        onPage={onPage}
                        uniqueKeyField={this.uniqueKeyField}
                        columns={this.state.columns}
                        rows={this.props.records}
                        gridStyles={classes.gridStyles}
                        rowSelect={true}
                        selectionMode="multiple"
                        selectedFrozen={true}
                        selected={selectedIndexes}
                        selectedClass={classes.selectedClass}
                        selectionChange={onRowsSelected}
                        totalRecords={this.props.totalRecords}
                        resultCount={this.props.totalRecords}
                        pageSize={pageSize}
                        rowsToLoadPerScroll={getRowCount(this.props.totalRecords, pageSize)}
                        {...(!this.props.hasReadOnlyAccess ? { frozenWidth: '170px' } : { frozenWidth: '100px' })}
                        lazy={true}
                        virtualRowHeight={48}
                        showReport={true}
                    />
                ) : (
                    <GridNoRecords />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        records: state.errorLibraryReducer.errorLibraryData,
        totalRecords: state.errorLibraryReducer.totalRecords,
        hasReadOnlyAccess: state.rootReducer.isReadOnly
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(List));
