export const SET_APPLICATION_LISTING_RECORDS = 'SET_APPLICATION_LISTING_RECORDS';
export const setApplicationListingRecords = (data, totalRecords) => ({
    type: SET_APPLICATION_LISTING_RECORDS,
    payload: { data, totalRecords }
});

export const SET_APPLICATION_LISTING_FILTERS = 'SET_APPLICATION_LISTING_FILTERS';
export const setApplicationListingFilters = (data) => ({
    type: SET_APPLICATION_LISTING_FILTERS,
    payload: { data }
});
