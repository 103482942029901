import React, { Component } from 'react';
import { setPageTitleAction, setErrorsAction, setLogoutMsg } from '../actions/actions';
import { setApiListingRecords } from '../actions/ApiListingActions';
import { connect } from 'react-redux';
import { Middleware } from 'one-ring';
import { withOktaAuth } from '@okta/okta-react';
import APIEndpoints from '../services/api';
import { getAccessToken, getEndIsoDate, getLoadingMessage, getStartIsoDate, getTitle, makeOptions } from '../utils/helper';
import HeaderTitle from '../components/HeaderTitle';
import { setViewLogsFilters } from '../actions/ViewLogsActions';
import ApiListingFilters from '../components/ApiListing/Filter';
import ApiListingGrid from '../components/ApiListing/List';
import { Translate } from 'react-localize-redux';
import { setApplicationListingFilters } from '../actions/ApplicationListingActions';
import IsMasterDataInitialized from '../components/IsMasterDataInitialized';
import MainPanel from '../components/MainPanel';

const PAGE_TITLE = 'Process Listing';

class ApiListing extends Component {
    state = {
        page: 1,
        pageSize: 50,
        first: 0,
        title: '',
        appName: this.props.match.params.ApplicationName,
        apiNamesList: []
    };

    getApiListData = async (currentPage, startDate, endDate, appName, apiName) => {
        let token = await getAccessToken(this.props);
        var data = [];
        if (token) {
            data = await Middleware.Send(
                'ApiList',
                token,
                APIEndpoints.API_LEVEL_DETAILS(this.props.env, currentPage, this.state.pageSize, startDate, endDate, appName, apiName),
                'GET',
                '',
                getLoadingMessage('LoadingAPIListing')
            );
        }
        return data;
    };

    setFilterOptionsForViewLogsScreen = (startDate, endDate) => {
        var data = {
            startDate: startDate,
            endDate: endDate,
            application: '',
            apiName: '',
            transactionId: '',
            reProcessable: '',
            source: 'DB',
            endPoint: '',
            searchString: '',
            searchLike: true
        };
        this.props.setViewLogsFilters(data);
    };

    setFilterOptionsForAppListingScreen = (startDate, endDate) => {
        this.props.setApplicationListingFilters({ startDate: startDate, endDate: endDate });
    };

    initializeApiListing = async (startDate, endDate, apiName, appName) => {
        var data = [];
        var title = getTitle(startDate, endDate);
        try {
            data = await this.getApiListData(1, getStartIsoDate(startDate), getEndIsoDate(endDate), appName, apiName);
        } catch (err) {
            this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileFetchingApiListingData" />, err);
        }
        this.props.setApiListingRecords(data.apiListing ? data.apiListing : [], data.totalRecords ? data.totalRecords : 0);
        this.setState({
            first: 0,
            title: title,
            appName: appName
        });
    };

    getApiNames = async (appName) => {
        let token = await getAccessToken(this.props);
        var data = [];
        if (token) {
            data = await Middleware.Send(
                'ApiNames',
                token,
                APIEndpoints.API_NAMES(this.props.env, appName),
                'GET',
                '',
                getLoadingMessage('loadingApiNames')
            );
        }
        this.setState({ apiNamesList: [{ label: 'All', value: '' }].concat(makeOptions(data, 'apiName', 'apiName')) });
    };

    initialize = () => {
        let appName = this.props.match.params.ApplicationName;
        let startDate = appName && this.props.startDate ? this.props.startDate : new Date();
        let endDate = appName && this.props.endDate ? this.props.endDate : new Date();
        let apiName = appName && this.props.apiName ? this.props.apiName : '';
        this.setFilterOptionsForViewLogsScreen(startDate, endDate);
        this.setFilterOptionsForAppListingScreen(startDate, endDate);
        if (appName) {
            this.initializeApiListing(startDate, endDate, apiName, appName);
            this.getApiNames(appName);
        } else {
            this.setState({ title: getTitle(startDate, endDate) });
        }
    };

    componentDidMount() {
        if (this.props.pageTitle !== PAGE_TITLE) {
            this.props.setPageTitle(PAGE_TITLE);
        }
        this.initialize();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.env !== this.props.env) {
            this.initialize();
        }
    }

    onPage = async (event) => {
        try {
            let data = await this.getApiListData(
                event.first / this.state.pageSize + 1,
                getStartIsoDate(this.props.startDate ? this.props.startDate : new Date()),
                getEndIsoDate(this.props.endDate ? this.props.endDate : new Date()),
                this.state.appName,
                this.props.apiName
            );
            this.props.setApiListingRecords(data.apiListing ? data.apiListing : [], data.totalRecords ? data.totalRecords : 0);
            this.setState({ first: event.first });
        } catch (err) {
            this.props.setErrorsAction(<Translate id="APIError" />, <Translate id="ErrorOccurredWhileFetchingApiListingData" />, err);
        }
    };

    onSearchButtonClick = async (filterValues) => {
        this.initializeApiListing(filterValues.startDate, filterValues.endDate, filterValues.apiName, filterValues.appName);
        this.setFilterOptionsForViewLogsScreen(filterValues.startDate, filterValues.endDate);
        this.setFilterOptionsForAppListingScreen(filterValues.startDate, filterValues.endDate);
        this.props.history.push(`/ApiListing/${filterValues.appName}`);
    };

    render() {
        return (
            <IsMasterDataInitialized>
                <MainPanel>
                    <HeaderTitle id="apiListing" title={`${this.state.appName ? `: ${this.state.appName}` : ''}${this.state.title}`} />
                    <ApiListingFilters
                        appName={this.state.appName}
                        handleSearch={this.onSearchButtonClick}
                        apiNamesList={this.state.apiNamesList}
                        getApiNames={this.getApiNames}
                    />
                    <ApiListingGrid first={this.state.first} onPage={this.onPage} pageSize={this.state.pageSize} appName={this.state.appName} />
                </MainPanel>
            </IsMasterDataInitialized>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setErrorsAction: (title, error, errorstack) => dispatch(setErrorsAction(title, error, errorstack)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setApiListingRecords: (records, totalRecords) => dispatch(setApiListingRecords(records, totalRecords)),
    setViewLogsFilters: (data) => dispatch(setViewLogsFilters(data)),
    setApplicationListingFilters: (data) => dispatch(setApplicationListingFilters(data)),
    setLogoutMsg: (logoutMessage) => dispatch(setLogoutMsg(logoutMessage))
});

const mapStateToProps = (state) => {
    return {
        pageTitle: state.rootReducer.pageTitle,
        env: state.rootReducer.env,
        startDate: state.apiListingReducer.startDate,
        endDate: state.apiListingReducer.endDate,
        apiName: state.apiListingReducer.apiName
    };
};

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(ApiListing));
